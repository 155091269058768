export const FLAG_CREATE_REQUEST = 'FLAG_CREATE_REQUEST'
export const FLAG_CREATE_SUCCESS = 'FLAG_CREATE_SUCCESS'
export const FLAG_CREATE_FAIL = 'FLAG_CREATE_FAIL'
export const FLAG_CREATE_RESET = 'FLAG_CREATE_RESET'

export const FLAG_LIST_ALL_REQUEST = 'FLAG_LIST_ALL_REQUEST'
export const FLAG_LIST_ALL_SUCCESS = 'FLAG_LIST_ALL_SUCCESS'
export const FLAG_LIST_ALL_FAIL = 'FLAG_LIST_ALL_FAIL'

export const FLAG_FILTER_REQUEST = 'FLAG_FILTER_REQUEST'
export const FLAG_FILTER_SUCCESS = 'FLAG_FILTER_SUCCESS'
export const FLAG_FILTER_FAIL = 'FLAG_FILTER_FAIL'
export const FLAG_FILTER_RESET = 'FLAG_FILTER_RESET'
