import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Form, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { filterFlags } from '../actions/flagActions'
import classes from './ListScreen.module.css'
import {
  dropdown_boolean_options,
  dropdown_level_options,
  dropdown_question_type_options,
  dropdown_target_options,
  dropdown_text_type_options,
  dropdown_user_options,
} from '../constants/dropdownConstants'
import PaginateFlags from '../components/PaginateFlags'

const FlagListScreen = () => {
  const params = useParams()
  const pageNumber = params.pageNumber || 1

  const { keyword } = useParams()

  const [level, setLevel] = useState('')
  const [userLanguage, setUserLanguage] = useState('')
  const [targetLanguage, setTargetLanguage] = useState('')
  const [textType, setTextType] = useState('')
  const [questionType, setQuestionType] = useState('')
  const [inappropriate, setInappropriate] = useState('')
  const [offensive, setOffensive] = useState('')
  const [incorrect, setIncorrect] = useState('')
  const [wrongAnswer, setWrongAnswer] = useState('')
  const [weird, setWeird] = useState('')

  const [showFilters, setShowFilters] = useState(false)

  let history = useNavigate()

  const dispatch = useDispatch()

  const filter = {
    level: level,
    userLanguage: userLanguage,
    targetLanguage: targetLanguage,
    textType: textType,
    questionType: questionType,
    inappropriate: inappropriate,
    offensive: offensive,
    wrongAnswer: wrongAnswer,
    incorrect: incorrect,
    weird: weird,
  }

  const flagFilter = useSelector((state) => state.flagFilter)
  const { loading, error, flags, page, pages } = flagFilter

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history('/login')
    } else {
      dispatch(filterFlags(filter, keyword, pageNumber))
    }
  }, [history, dispatch, userInfo, pageNumber, params])

  useEffect(() => {
    if (!userInfo) {
      history('/login')
    } else {
      history(
        keyword
          ? `/admin/flags/search/${keyword}/page/${1}`
          : `/admin/flags/page/${1}`
      )
    }
  }, [
    level,
    targetLanguage,
    userLanguage,
    questionType,
    textType,
    inappropriate,
    incorrect,
    weird,
    wrongAnswer,
    offensive,
  ])

  const deleteHandler = (id) => {}
  const filterHandler = () => {
    setShowFilters(!showFilters)
  }

  return (
    <div className={classes.container}>
      <div>
        <Row className='align-items-center'>
          <Col>
            <h1>Flags</h1>
          </Col>
          <Col>
            <div></div>
          </Col>
          <Col className='text-right'>
            <Button className='my-3'>
              <i className='fas fa-plus'></i> Button
            </Button>
          </Col>
        </Row>
      </div>
      <div className={classes.filterContainer}>
        <Button className={classes.showFiltersButton} onClick={filterHandler}>
          Show Filters
        </Button>

        {showFilters && (
          <div>
            <Row>
              <Col>
                <Card className={classes.filterCard}>
                  <div>
                    <Form.Label>User Language:</Form.Label>
                    <Form.Select
                      onChange={(e) => setUserLanguage(e.target.value)}
                    >
                      {dropdown_user_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>

                  <div>
                    <Form.Label>Target Language:</Form.Label>
                    <Form.Select
                      onChange={(e) => setTargetLanguage(e.target.value)}
                    >
                      {dropdown_target_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>

                  <div>
                    <Form.Label>Level:</Form.Label>
                    <Form.Select onChange={(e) => setLevel(e.target.value)}>
                      {dropdown_level_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div>
                    <Form.Label>Question Type:</Form.Label>
                    <Form.Select
                      onChange={(e) => setQuestionType(e.target.value)}
                    >
                      {dropdown_question_type_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div>
                    <Form.Label>Text Type:</Form.Label>
                    <Form.Select onChange={(e) => setTextType(e.target.value)}>
                      {dropdown_text_type_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div>
                    <Form.Label>Inappropriate:</Form.Label>
                    <Form.Select
                      onChange={(e) => setInappropriate(e.target.value)}
                    >
                      {dropdown_boolean_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div>
                    <Form.Label>Offensive:</Form.Label>
                    <Form.Select onChange={(e) => setOffensive(e.target.value)}>
                      {dropdown_boolean_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div>
                    <Form.Label>Incorrect:</Form.Label>
                    <Form.Select onChange={(e) => setIncorrect(e.target.value)}>
                      {dropdown_boolean_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div>
                    <Form.Label>Wrong Answer:</Form.Label>
                    <Form.Select
                      onChange={(e) => setWrongAnswer(e.target.value)}
                    >
                      {dropdown_boolean_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div>
                    <Form.Label>Weird:</Form.Label>
                    <Form.Select onChange={(e) => setWeird(e.target.value)}>
                      {dropdown_boolean_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        )}

        {/* {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>} */}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Card>
            <Table striped bordered hover responsive className='table-sm'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Comment</th>
                  <th>User</th>
                  <th>User Email</th>
                  <th>Inappropriate</th>
                  <th>Offensive</th>
                  <th>Incorrect</th>
                  <th>Wrong Answer</th>
                  <th>Weird</th>
                  <th>Question Text</th>
                </tr>
              </thead>
              <tbody>
                {flags.map((flag) => (
                  <tr key={flag._id}>
                    <td>{flag._id}</td>
                    <td>{flag.comment}</td>
                    <td>{flag.userData.name}</td>
                    <td>{flag.userData.email}</td>
                    <td>
                      {flag.inappropriate ? (
                        <i
                          className='fas fa-check'
                          style={{ color: 'red' }}
                        ></i>
                      ) : (
                        <i
                          className='fas fa-times'
                          style={{ color: 'green' }}
                        ></i>
                      )}
                    </td>
                    <td>
                      {flag.offensive ? (
                        <i
                          className='fas fa-check'
                          style={{ color: 'red' }}
                        ></i>
                      ) : (
                        <i
                          className='fas fa-times'
                          style={{ color: 'green' }}
                        ></i>
                      )}
                    </td>
                    <td>
                      {flag.incorrect ? (
                        <i
                          className='fas fa-check'
                          style={{ color: 'red' }}
                        ></i>
                      ) : (
                        <i
                          className='fas fa-times'
                          style={{ color: 'green' }}
                        ></i>
                      )}
                    </td>
                    <td>
                      {flag.wrongAnswer ? (
                        <i
                          className='fas fa-check'
                          style={{ color: 'red' }}
                        ></i>
                      ) : (
                        <i
                          className='fas fa-times'
                          style={{ color: 'green' }}
                        ></i>
                      )}
                    </td>
                    <td>
                      {flag.weird ? (
                        <i
                          className='fas fa-check'
                          style={{ color: 'red' }}
                        ></i>
                      ) : (
                        <i
                          className='fas fa-times'
                          style={{ color: 'green' }}
                        ></i>
                      )}
                    </td>
                    <td>
                      {flag.questionData
                        ? flag.questionData.question
                        : flag.question}
                    </td>
                    <td>
                      {flag.questionData.isFlagged ? 'FLAGGED' : 'resolved!'}
                    </td>

                    {flag.questionData ? (
                      <td>
                        <LinkContainer
                          to={`/admin/flags/${flag.questionData._id}/edit`}
                        >
                          <Button variant='light' className='btn-sm'>
                            <i className='fas fa-edit'></i>
                          </Button>
                        </LinkContainer>
                        <Button
                          variant='danger'
                          className='btn-sm'
                          onClick={() => deleteHandler(flag.questionData._id)}
                        >
                          <i className='fas fa-trash'></i>
                        </Button>
                      </td>
                    ) : (
                      <td>resolved</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            <PaginateFlags pages={pages} page={page} isAdmin={true} />
          </Card>
        )}
      </div>
    </div>
  )
}

export default FlagListScreen
