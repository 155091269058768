import axios from 'axios'

import {
  NOTEBOOK_CREATE_FAIL,
  NOTEBOOK_CREATE_REQUEST,
  NOTEBOOK_CREATE_SUCCESS,
  NOTEBOOK_DELETE_BULK_FAIL,
  NOTEBOOK_DELETE_BULK_REQUEST,
  NOTEBOOK_DELETE_BULK_SUCCESS,
  NOTEBOOK_DELETE_FAIL,
  NOTEBOOK_DELETE_REQUEST,
  NOTEBOOK_DELETE_SUCCESS,
  NOTEBOOK_DETAILS_FAIL,
  NOTEBOOK_DETAILS_REQUEST,
  NOTEBOOK_DETAILS_SUCCESS,
  NOTEBOOK_FETCH_ONE_FAIL,
  NOTEBOOK_FETCH_ONE_REQUEST,
  NOTEBOOK_FETCH_ONE_SUCCESS,
  NOTEBOOK_LEVEL_OPTIONS_FAIL,
  NOTEBOOK_LEVEL_OPTIONS_REQUEST,
  NOTEBOOK_LEVEL_OPTIONS_SUCCESS,
  NOTEBOOK_LIST_MY_FAIL,
  NOTEBOOK_LIST_MY_REQUEST,
  NOTEBOOK_LIST_MY_SUCCESS,
  NOTEBOOK_UPDATE_FAIL,
  NOTEBOOK_UPDATE_REQUEST,
  NOTEBOOK_UPDATE_SUCCESS,
} from '../constants/notebookConstants'

import { logout } from './userActions'
import { API } from '../apiConstants'

export const createNotebook = (notebook) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NOTEBOOK_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`${API}/api/notebooks`, notebook, config)

    dispatch({
      type: NOTEBOOK_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: NOTEBOOK_CREATE_FAIL,
      payload: message,
    })
  }
}

export const listMyNotebooks =
  (filter, keyword = '', pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      filter.user = userInfo._id
      dispatch({
        type: NOTEBOOK_LIST_MY_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/notebooks/mynotes/?keyword=${keyword}&pageNumber=${pageNumber}`,
        {
          params: filter,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )

      dispatch({
        type: NOTEBOOK_LIST_MY_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: NOTEBOOK_LIST_MY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const deleteNotebook = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NOTEBOOK_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`${API}/api/notebooks/${id}`, config)

    dispatch({
      type: NOTEBOOK_DELETE_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: NOTEBOOK_DELETE_FAIL,
      payload: message,
    })
  }
}

export const deleteNotebookBulk = (notebooks) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NOTEBOOK_DELETE_BULK_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`${API}/api/notebooks/delete/notebooksbulk`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      data: notebooks,
    })

    dispatch({
      type: NOTEBOOK_DELETE_BULK_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: NOTEBOOK_DELETE_BULK_FAIL,
      payload: message,
    })
  }
}

export const getNotebookRandom = (filter) => async (dispatch, getState) => {
  try {
    dispatch({ type: NOTEBOOK_FETCH_ONE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios.get(`${API}/api/notebooks/module/`, {
      params: filter,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })

    dispatch({ type: NOTEBOOK_FETCH_ONE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: NOTEBOOK_FETCH_ONE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getNotebookLevelsList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: NOTEBOOK_LEVEL_OPTIONS_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios.get(`${API}/api/notebooks/levels/`, {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })

    dispatch({ type: NOTEBOOK_LEVEL_OPTIONS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: NOTEBOOK_LEVEL_OPTIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateNotebook = (notebook) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NOTEBOOK_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${API}/api/notebooks/${notebook._id}`,
      notebook,
      config
    )

    dispatch({
      type: NOTEBOOK_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: NOTEBOOK_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: NOTEBOOK_UPDATE_FAIL,
      payload: message,
    })
  }
}

export const getNotebookDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: NOTEBOOK_DETAILS_REQUEST })

    const { data } = await axios.get(`${API}/api/notebooks/${id}`)

    dispatch({
      type: NOTEBOOK_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: NOTEBOOK_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
