import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import ForthcomingBox from '../components/ForthcomingBox'
import LandingImage from '../components/LandingImage'
import SampleDashboardImage from '../components/SampleDashboardImage'
import classes from './NewStartScreen.module.css'

const SampleDashboard = () => {
  return (
    <>
      <div className={classes.loginContainer}>
        <LinkContainer to='/login'>
          <h3 className={classes.loginText}>
            Login or sign up to start using your learning dashboards!
          </h3>
        </LinkContainer>
      </div>
      <div className={classes.landingContainer}>
        <Row>
          <Col>
            <SampleDashboardImage />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default SampleDashboard
