import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import TeamMember from '../components/TeamMember'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import Meta from '../components/Meta'
import { listTeam } from '../actions/teamActions'
import SearchBox from '../components/SearchBox'
import classes from './TeamScreen.module.css'

const TeamScreen = () => {
  const { keyword } = useParams()

  const { pageNumber } = useParams()

  const dispatch = useDispatch()

  const teamList = useSelector((state) => state.teamList)
  const { loading, error, team, page, pages } = teamList

  useEffect(() => {
    dispatch(listTeam(keyword, pageNumber))
  }, [dispatch, keyword, pageNumber])

  return (
    <div className={classes.container}>
      <Meta />
      <Row className='mb-3 pt-3'>
        <Col>
          <div>
            <SearchBox />
          </div>
        </Col>
      </Row>
      <Row>
        {team.map((teamMember) => (
          <Col key={teamMember._id} sm={12} md={6} lg={4} xl={3}>
            <TeamMember teamMember={teamMember} />
          </Col>
        ))}
      </Row>
    </div>
  )
}
// const HomeScreen = () => {
//   const { keyword } = useParams()

//   const { pageNumber } = useParams()

//   const dispatch = useDispatch()

//   const productList = useSelector((state) => state.productList)
//   const { loading, error, products, page, pages } = productList

//   useEffect(() => {
//     dispatch(listProducts(keyword, pageNumber))
//   }, [dispatch, keyword, pageNumber])

//   return (
//     <>
//       <div>'MEOR'</div>
//     </>
//   )
// }

export default TeamScreen
