import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router'
import { QuestionMultipleChoice } from '../components/QuestionMultipleChoice'
import { QuestionFillBlank } from '../components/QuestionFillBlank'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FlagModal from '../components/FlagModal'
import QuestionModuleDonut from '../components/QuizModuleDonut'
import { sum } from 'd3-array'
import { NOTEBOOK_CREATE_RESET } from '../constants/notebookConstants'
import { FLAG_CREATE_RESET } from '../constants/flagConstants'
import {
  getLevelsList,
  getMyQuestionRandom,
} from '../actions/myQuestionActions'
import NotebookModalClassroom from '../components/NotebookModalClassroom'
import { listClassroomDetails } from '../actions/classroomActions'
import { LinkContainer } from 'react-router-bootstrap'
import classes from './ModuleScreens.module.css'
import NotebookModal from '../components/NotebookModal'
import LevelBarCustom from '../components/LevelBarCustom'
import ScoreBar from '../components/ScoreBar'
import { createNotebook } from '../actions/notebookActions'
import { postMyDashboardData } from '../actions/myDashboardActions'
import LevelModal from '../components/LevelModal'
import { getUserDetails, updateCustomUserLevel } from '../actions/userActions'

const ClassroomModuleScreen = () => {
  const dispatch = useDispatch()
  let history = useNavigate()
  const correctColor = '#2fb986'
  const incorrectColor = '#ff8d7a'

  const { id } = useParams()

  /// Redux States
  const myQuestionLevelsList = useSelector(
    (state) => state.myQuestionLevelsList
  )
  const {
    loading: loadingLevelsList,
    error: errorLevelsList,
    levels: levelsList,
  } = myQuestionLevelsList

  const initializeCustom = useSelector(
    (state) => state.initializeCustom.initializeCustomParameters
  )
  const { level } = initializeCustom

  const myQuestionFetchOne = useSelector((state) => state.myQuestionFetchOne)
  const { loading, error, question: questionData } = myQuestionFetchOne

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const classroomDetails = useSelector((state) => state.classroomDetails)
  const {
    loading: loadingClassroom,
    error: errorClassroom,
    classroom,
  } = classroomDetails

  //Use States

  const [isStarted, setIsStarted] = useState(false)
  const [levelChangePermit, setLevelChangePermit] = useState(false)
  const [scoreList, setScoreList] = useState([])
  const [attemptCount, setAttemptCount] = useState(0)
  const [levelLock, setLevelLock] = useState(false)
  const [shuffledAnswerList, setShuffledAnswerList] = useState([])
  const [totalScore, setTotalScore] = useState(null)
  const [correctAnswer, setCorrectAnswer] = useState('')
  const [levels, setLevels] = useState([1])
  const [currentLevelIndex, setCurrentLevelIndex] = useState(null)
  const [maxUserLevelIndex, setMaxUserLevelIndex] = useState(0)
  const [maxLevelIndex, setMaxLevelIndex] = useState(0)
  const [showFlagModal, setShowFlagModal] = useState(false)
  const [showLevelModal, setShowLevelModal] = useState(false)
  const [showNotebookModal, setShowNotebookModal] = useState(false)
  const [showDonut, setShowDonut] = useState(true)
  const [message, setMessage] = useState('')
  const [showMessage, setShowMessage] = useState('')
  const handleFlagModalClose = () => setShowFlagModal(false)
  const handleFlagModalShow = () => setShowFlagModal(true)
  const handleLevelModalClose = () => setShowLevelModal(false)

  const handleNotebookModalClose = () => {
    setShowNotebookModal(false)
    dispatch({ type: NOTEBOOK_CREATE_RESET })
  }
  const handleNotebookModalShow = () => setShowNotebookModal(true)

  const filter = {
    level: levels[currentLevelIndex],
  }

  const data = [
    { date: 'incorrect', value: 100 - totalScore },
    { date: 'correct', value: totalScore },
  ]

  const shuffle = (arr) => arr.sort(() => Math.random() - 0.5)
  const myFunc = (total, num) => {
    return total + num
  }

  ////////////////////////////////////////////////////
  /////////WONDERFUL WORLD OF USE EFFECTS ////////////
  ////////////////////////////////////////////////////

  // Initialize Module Levels
  useEffect(() => {
    if (isStarted === false) {
      dispatch(getLevelsList(id))
      dispatch(listClassroomDetails(id))
      setIsStarted(true)
    }
  }, [isStarted])

  // Initialize Module
  useEffect(() => {
    if (levelsList) {
      dispatch(getMyQuestionRandom(id, { level: levelsList[level] }))
    }
  }, [myQuestionLevelsList])

  useEffect(() => {
    if (levelsList) {
      setLevels(levelsList)
      setMaxLevelIndex(levelsList.length - 1)
    }
  }, [myQuestionLevelsList])

  useEffect(() => {
    setCurrentLevelIndex(level)
    setMaxUserLevelIndex(level)
  }, [initializeCustom])

  // Gets user's stored level and updates
  useEffect(() => {
    if (!userInfo) {
      console.log('No user info')
    } else {
      if (userInfo.customLevels) {
        if (typeof userInfo.customLevels[id] != 'undefined') {
          if (userInfo.customLevels[id] < currentLevelIndex) {
            dispatch(
              updateCustomUserLevel({
                classId: id,
                level: currentLevelIndex,
              })
            )
            setMaxUserLevelIndex(currentLevelIndex)
          }
        }
      } else {
        dispatch(
          updateCustomUserLevel({
            classId: id,
            level: 0,
          })
        )
      }
    }
  }, [dispatch, history, currentLevelIndex])

  // Set Total Score
  useEffect(() => {
    if (scoreList.length > 0) {
      setTotalScore(100 * (sum(scoreList.slice(-5)) / 5))
    }
  }, [scoreList])

  // Deliver Dashboard Data
  useEffect(() => {
    if (userInfo && scoreList.length > 0) {
      let answerData = {
        answer: correctAnswer,
        question: questionData._id,
        classroom: id,
        score:
          scoreList.slice(-1)[0] === 1
            ? 'correct'
            : scoreList.slice(-1)[0] > 0
            ? 'partial'
            : 'incorrect',
        pointscore: scoreList.slice(-1)[0],
      }
      dispatch(postMyDashboardData(answerData))
    }
  }, [scoreList])

  // Shuffled Answers
  useEffect(() => {
    if (questionData.answers) {
      if (questionData.answers.length > 0) {
        setShuffledAnswerList(shuffle([...questionData.answers]))
      }
    }
    if (document.getElementById('nextButton')) {
      document.getElementById('nextButton').disabled = true
    }
    setShowMessage(false)
  }, [questionData])

  // Correct Answers
  useEffect(() => {
    if (shuffledAnswerList.length > 0) {
      let answerFilter = shuffledAnswerList.filter(
        ({ isCorrect, text }) => isCorrect === true
      )
      if (answerFilter.length > 0) {
        setCorrectAnswer(answerFilter[0]['text'])
      }
    }
  }, [shuffledAnswerList, questionData])

  // Manage Levels
  useEffect(() => {
    if (
      scoreList.length === 5 &&
      currentLevelIndex !== maxLevelIndex &&
      scoreList.slice(-1)[0] === 1 &&
      scoreList.slice(-5).reduce((accumulator, curr) => accumulator + curr) ===
        5 &&
      levelLock !== true
    ) {
      setScoreList([])
      setCurrentLevelIndex(currentLevelIndex + 1)
      setShowLevelModal(true)
      setTotalScore(0)
    }
    if (
      scoreList.length > 0 &&
      currentLevelIndex !== 0 &&
      scoreList.slice(-2).reduce((accumulator, curr) => accumulator + curr) ===
        0 &&
      scoreList.slice(-5).reduce((accumulator, curr) => accumulator + curr) <=
        2 &&
      levelLock !== true
    ) {
      setCurrentLevelIndex(currentLevelIndex)
    }
  }, [scoreList])

  // Parlay Question Data
  useEffect(() => {
    if (questionData.answers) {
      for (let i = 1; i <= questionData.answers.length; i++) {
        if (document.getElementById(i) !== null) {
          document.getElementById(i).disabled = false
          document.getElementById(i).style.backgroundColor = '#4C4A47'
        }
      }
    }
  }, [questionData])

  ////////////////////////////////////////////////////
  /////////WONDERFUL WORLD OF BUTTON HANDLERS ////////
  ////////////////////////////////////////////////////

  // Handle Hot Keys
  const handleKeyPress = (event) => {
    if (
      showLevelModal === false &&
      event.key == 'Enter' &&
      document.getElementById('nextButton').disabled === false
    ) {
      document.getElementById('nextButton').click()
    } else {
      if (
        document.getElementById(event.key) !== null &&
        showLevelModal === false
      ) {
        if (document.getElementById(event.key).value) {
          document.getElementById(event.key).click()
          //console.log('Click info', document.getElementById(event.key).value)
        } else {
          document.getElementById(event.key).click()
          //console.log('Click info', document.getElementById(event.key).value)
        }
      }
    }
  }

  const answerButtonHandler = (event) => {
    if (event.target.value === correctAnswer) {
      setScoreList((scoreList) => [...scoreList, 1].slice(-5))
      document.getElementById(event.target.id).style.backgroundColor =
        correctColor
      document.getElementById('overDiv').focus()
      for (let i = 1; i <= questionData.answers.length; i++) {
        if (document.getElementById(i) !== null) {
          document.getElementById(i).disabled = true
        }
      }
      document.getElementById('nextButton').disabled = false
      document.getElementById('skipButton').disabled = true
      if (document.getElementById('giveUpButton') != null) {
        document.getElementById('giveUpButton').disabled = true
      }
    } else {
      document.getElementById(event.target.id).style.backgroundColor =
        incorrectColor
      document.getElementById(event.target.id).disabled = true
      setScoreList((scoreList) => [...scoreList, 0].slice(-5))
    }
    //return console.log(event, scoreList)
  }

  // Skip Button Handler
  const skipButtonHandler = (event) => {
    dispatch(getMyQuestionRandom(id, filter))
    if (document.getElementById('mainForm')) {
      document.getElementById('mainForm').focus()
    }
    setScoreList((scoreList) => [...scoreList, 0].slice(-5))
  }

  // Next Button Handler
  const nextButtonHandler = (event) => {
    if (document.getElementById('mainForm')) {
      document.getElementById('mainForm').focus()
    }
    dispatch(getMyQuestionRandom(id, filter))
    //console.log(shuffledAnswerList)
    document.getElementById('nextButton').disabled = true
    document.getElementById('skipButton').disabled = false
    document.getElementById('giveUpButton').disabled = false
    setShowMessage(false)
  }

  const exitButtonHandler = (event) => {
    history(`/studentclassrooms/${classroom._id}`)
  }

  const flagButtonHandler = () => {
    dispatch({ type: FLAG_CREATE_RESET })
    setShowFlagModal(true)
  }

  ////////////////////////////////////////////////////
  /////////WONDERFUL WORLD OF NOTEBOOK MANAGEMENT ////////////
  ////////////////////////////////////////////////////

  /// Notebook Create
  const notebookCreate = useSelector((state) => state.notebookCreate)
  const {
    loading: loadingNotebookCreate,
    error: errorNotebookCreate,
    success,
    notebook,
  } = notebookCreate

  useEffect(() => {
    if (success) {
      setMessage('Successfully added to notebook!')
      setShowMessage(true)
    }
  }, [success, notebook])

  const notebookButtonHandler = (e) => {
    dispatch({ type: NOTEBOOK_CREATE_RESET })
    e.preventDefault()
    dispatch(
      createNotebook({
        question: questionData.question,
        answers: questionData.answers,
        targetLanguage: questionData.targetLanguage,
        level: questionData.level,
        questionId: questionData._id,
        questionType: questionData.questionType,
        isFlagged: questionData.isFlagged,
        textType: questionData.textType,
        citation: questionData.citation,
        source: classroom.name,
      })
    )
  }

  ////////////////////////////////////////////////////
  /////////WONDERFUL WORLD OF REACT COMPONENTS ////////////
  ////////////////////////////////////////////////////

  const otherButtons = (
    <div>
      <button className={classes.exitButton} onClick={exitButtonHandler}>
        Exit
      </button>
      <button
        className={classes.skipButton}
        id={'skipButton'}
        onClick={skipButtonHandler}
      >
        Skip
      </button>
      <button
        className={classes.nextButton}
        id={'nextButton'}
        onClick={nextButtonHandler}
      >
        Next
      </button>
    </div>
  )

  const questionBox = (
    <div>
      {questionData.questionType === 'multiple_choice' ? (
        <QuestionMultipleChoice
          questionData={questionData}
          correctAnswer={correctAnswer}
          answerButtonHandler={answerButtonHandler}
          shuffledAnswerList={shuffledAnswerList}
          setScoreList={setScoreList}
          correctColor={correctColor}
        />
      ) : questionData.questionType === 'fill_in_the_blank' ? (
        <QuestionFillBlank
          questionData={questionData}
          setScoreList={setScoreList}
          scoreList={scoreList}
        />
      ) : (
        <Loader />
      )}
    </div>
  )

  const moduleLayout = (
    <>
      <div
        id={'overDiv'}
        className={classes.div}
        onKeyDown={handleKeyPress}
        tabIndex={'0'}
      >
        <LevelBarCustom
          maxLevelIndex={maxLevelIndex}
          levels={levels}
          currentLevelIndex={currentLevelIndex}
          setCurrentLevelIndex={setCurrentLevelIndex}
          maxUserLevelIndex={maxUserLevelIndex}
          setScoreList={setScoreList}
          setTotalScore={setTotalScore}
          id={id}
          filter={filter}
          setLevelLock={setLevelLock}
        />
        <div>
          <ScoreBar scoreList={scoreList} />
        </div>

        <Row>
          <Col>
            <div>
              <h2 className={classes.levelLabel2}>
                Level {questionData.level}
              </h2>
            </div>
            <div>
              {classroom && (
                <h2 className={classes.levelLabel2}>{classroom.name}</h2>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {questionBox}
            <FlagModal
              showFlagModal={showFlagModal}
              questionData={questionData}
              handleFlagModalClose={handleFlagModalClose}
            />
            <NotebookModal
              showNotebookModal={showNotebookModal}
              questionData={questionData}
              handleNotebookModalClose={handleNotebookModalClose}
            />
            <LevelModal
              showLevelModal={showLevelModal}
              questionData={questionData}
              link={`/studentclassrooms/${id}`}
              handleLevelModalClose={handleLevelModalClose}
            />
          </Col>
        </Row>

        <div className={classes.otherButtonsContainer}>
          {otherButtons}
          {userInfo && (
            <div classes={classes.modalButtonsContainer}>
              <button
                id={'flagButton'}
                className={classes.flagButton}
                onClick={flagButtonHandler}
              >
                <i className='fas fa-flag'></i> Flag
              </button>
              <button
                id={'notebookButton'}
                className={classes.flagButton}
                onClick={notebookButtonHandler}
              >
                <i className='fas fa-plus'></i> Add to Notebook
              </button>
            </div>
          )}
        </div>
        <div className={classes.donutdiv}>
          <QuestionModuleDonut
            data={data}
            width={40}
            height={40}
            innerRadius={10}
            outerRadius={20}
          />
        </div>
        {showMessage && <Message variant='success'>{message}</Message>}
      </div>
    </>
  )

  return (
    <div className={classes.container}>
      {loadingClassroom ? (
        <Loader />
      ) : currentLevelIndex != null ? (
        moduleLayout
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default ClassroomModuleScreen
