import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import classes from './AboutNavbar.module.css'
const AboutNavbar = () => {
  return (
    <header className={classes.header}>
      <Navbar className={classes.nav} expand='lg' collapseOnSelect>
        <Container className={classes.container}>
          <LinkContainer to='/about'>
            <Nav.Link className={classes.navlink}>About</Nav.Link>
          </LinkContainer>
          <LinkContainer to='/mission'>
            <Nav.Link className={classes.navlink}>Mission</Nav.Link>
          </LinkContainer>
          <LinkContainer to='/languages'>
            <Nav.Link className={classes.navlink}>Languages</Nav.Link>
          </LinkContainer>
          {/* <LinkContainer to='/research'>
            <Nav.Link>Research</Nav.Link>
          </LinkContainer> */}
          <LinkContainer to='/future'>
            <Nav.Link className={classes.navlink}>Future</Nav.Link>
          </LinkContainer>
          <LinkContainer to='/careers'>
            <Nav.Link className={classes.navlink}>Work with us</Nav.Link>
          </LinkContainer>
        </Container>
      </Navbar>
    </header>
  )
}

export default AboutNavbar
