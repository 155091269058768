import React from 'react'
import ForthcomingBox from '../components/ForthcomingBox'
import classes from './DashboardScreen.module.css'

const TeacherGlobalDashboardScreen = () => {
  return (
    <div className={classes.container}>
      <div>TeacherGlobalDashboardScreen</div>
      <ForthcomingBox message={'...Still in development ;)'} />
    </div>
  )
}

export default TeacherGlobalDashboardScreen
