export const NOTEBOOK_LIST_REQUEST = 'NOTEBOOK_LIST_REQUEST'
export const NOTEBOOK_LIST_SUCCESS = 'NOTEBOOK_LIST_SUCCESS'
export const NOTEBOOK_LIST_FAIL = 'NOTEBOOK_LIST_FAIL'

export const NOTEBOOK_LIST_MY_REQUEST = 'NOTEBOOK_LIST_MY_REQUEST'
export const NOTEBOOK_LIST_MY_SUCCESS = 'NOTEBOOK_LIST_MY_SUCCESS'
export const NOTEBOOK_LIST_MY_FAIL = 'NOTEBOOK_LIST_MY_FAIL'
export const NOTEBOOK_LIST_MY_RESET = 'NOTEBOOK_LIST_MY_RESET'

export const NOTEBOOK_FETCH_ONE_REQUEST = 'NOTEBOOK_FETCH_ONE_REQUEST'
export const NOTEBOOK_FETCH_ONE_SUCCESS = 'NOTEBOOK_FETCH_ONE_SUCCESS'
export const NOTEBOOK_FETCH_ONE_FAIL = 'NOTEBOOK_FETCH_ONE_FAIL'
export const NOTEBOOK_FETCH_ONE_RESET = 'NOTEBOOK_FETCH_ONE_RESET'

export const NOTEBOOK_LEVEL_OPTIONS_REQUEST = 'NOTEBOOK_LEVEL_OPTIONS_REQUEST'
export const NOTEBOOK_LEVEL_OPTIONS_SUCCESS = 'NOTEBOOK_LEVEL_OPTIONS_SUCCESS'
export const NOTEBOOK_LEVEL_OPTIONS_FAIL = 'NOTEBOOK_LEVEL_OPTIONS_FAIL'
export const NOTEBOOK_LEVEL_OPTIONS_RESET = 'NOTEBOOK_LEVEL_OPTIONS_RESET'

export const NOTEBOOK_DETAILS_REQUEST = 'NOTEBOOK_DETAILS_REQUEST'
export const NOTEBOOK_DETAILS_SUCCESS = 'NOTEBOOK_DETAILS_SUCCESS'
export const NOTEBOOK_DETAILS_FAIL = 'NOTEBOOK_DETAILS_FAIL'

export const NOTEBOOK_DELETE_REQUEST = 'NOTEBOOK_DELETE_REQUEST'
export const NOTEBOOK_DELETE_SUCCESS = 'NOTEBOOK_DELETE_SUCCESS'
export const NOTEBOOK_DELETE_FAIL = 'NOTEBOOK_DELETE_FAIL'

export const NOTEBOOK_DELETE_BULK_REQUEST = 'NOTEBOOK_DELETE_BULK_REQUEST'
export const NOTEBOOK_DELETE_BULK_SUCCESS = 'NOTEBOOK_DELETE_BULK_SUCCESS'
export const NOTEBOOK_DELETE_BULK_FAIL = 'NOTEBOOK_DELETE_BULK_FAIL'

export const NOTEBOOK_CREATE_REQUEST = 'NOTEBOOK_CREATE_REQUEST'
export const NOTEBOOK_CREATE_SUCCESS = 'NOTEBOOK_CREATE_SUCCESS'
export const NOTEBOOK_CREATE_FAIL = 'NOTEBOOK_CREATE_FAIL'
export const NOTEBOOK_CREATE_RESET = 'NOTEBOOK_CREATE_RESET'

export const NOTEBOOK_UPDATE_REQUEST = 'NOTEBOOK_UPDATE_REQUEST'
export const NOTEBOOK_UPDATE_SUCCESS = 'NOTEBOOK_UPDATE_SUCCESS'
export const NOTEBOOK_UPDATE_FAIL = 'NOTEBOOK_UPDATE_FAIL'
export const NOTEBOOK_UPDATE_RESET = 'NOTEBOOK_UPDATE_RESET'
