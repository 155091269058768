import {
  INITIALIZE_CUSTOM_QUESTION_MODULE,
  INITIALIZE_QUESTION_MODULE,
} from '../constants/startConstants'

export const initializeParametersReducer = (
  state = { initializeParameters: {} },
  action
) => {
  switch (action.type) {
    case INITIALIZE_QUESTION_MODULE:
      return {
        ...state,
        initializeParameters: action.payload,
      }

    default:
      return state
  }
}

export const initializeCustomParametersReducer = (
  state = { initializeParameters: {} },
  action
) => {
  switch (action.type) {
    case INITIALIZE_CUSTOM_QUESTION_MODULE:
      return {
        ...state,
        initializeParameters: action.payload,
      }

    default:
      return state
  }
}
