import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listMyClassrooms, deleteClassroom } from '../actions/classroomActions'
import {
  CLASSROOM_REGISTER_RESET,
  CREATE_CLASSROOM_RESET,
  FETCH_CLASSROOM_DETAILS_RESET,
} from '../constants/classroomConstants'
import ClassroomBoxTeacher from '../components/ClassroomBoxTeacher'
import classes from './ListScreen.module.css'
import SuggestionBird from '../components/SuggestionBird'
import ClassroomImage from '../components/ClassroomImage'

const ClassroomListScreen = () => {
  const { pageNumber } = useParams()

  let history = useNavigate()

  const dispatch = useDispatch()

  const classroomsListMy = useSelector((state) => state.classroomsListMy)
  const { loading, error, classrooms, page, pages } = classroomsListMy

  const classroomDelete = useSelector((state) => state.classroomDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = classroomDelete

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: CLASSROOM_REGISTER_RESET })
    dispatch({ type: FETCH_CLASSROOM_DETAILS_RESET })
    if (!userInfo) {
      history('/login')
    } else {
      dispatch(listMyClassrooms('', pageNumber))
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    // successCreate,
    // createdClassroom,
    pageNumber,
  ])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteClassroom(id))
    }
  }

  const createClassroomHandler = () => {
    //dispatch(createClassroom())
    history(`/myclassrooms/register`)
  }

  return (
    <div className={classes.container}>
      <Row className='align-items-center'>
        <Col>
          <h1 className={classes.headerText}>Teacher Classrooms</h1>
        </Col>
        <Col className='text-right'>
          <Button
            className={classes.createClassroomButton}
            onClick={createClassroomHandler}
          >
            <i className='fas fa-plus'></i> Create Classroom
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {/* {loadingCreate && <Loader />} */}
      {/* {errorCreate && <Message variant='danger'>{errorCreate}</Message>} */}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : classrooms.length ? (
        <>
          <Row>
            {classrooms.map((classroom) => (
              <Col key={classroom._id}>
                <ClassroomBoxTeacher classroom={classroom} />
                <Button
                  className={classes.deleteButton}
                  variant='danger'
                  onClick={() => deleteHandler(classroom._id)}
                >
                  DELETE CLASSROOM
                </Button>
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <ClassroomImage />
      )}
    </div>
  )
}

export default ClassroomListScreen
