import React from 'react'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const PaginateMyQuestions = ({
  pages,
  page,
  isAdmin = false,
  keyword = '',
}) => {
  return (
    pages > 1 && (
      <Pagination>
        {page > 2 && (
          <LinkContainer
            key='first'
            to={
              !isAdmin
                ? keyword
                  ? `/myquestions/search/${keyword}/page/${1}`
                  : `/myquestions/page/${1}`
                : `/myquestions/page/${1}`
            }
          >
            <Pagination.Item>First</Pagination.Item>
          </LinkContainer>
        )}
        {page > 1 && (
          <LinkContainer
            key='previous'
            to={
              !isAdmin
                ? keyword
                  ? `/myquestions/search/${keyword}/page/${page - 1}`
                  : `/myquestions/page/${page - 1}`
                : `/myquestions/page/${page - 1}`
            }
          >
            <Pagination.Item>Previous</Pagination.Item>
          </LinkContainer>
        )}
        {page > 4 && <Pagination.Item>...</Pagination.Item>}
        {[...Array(pages).keys()]
          .filter((x) => x > page - 5 && x < page + 3)
          .map((x) => (
            <LinkContainer
              key={x + 1}
              to={
                !isAdmin
                  ? keyword
                    ? `/myquestions/search/${keyword}/page/${x + 1}`
                    : `/page/${x + 1}`
                  : `/myquestions/page/${x + 1}`
              }
            >
              <Pagination.Item active={x + 1 === page} activeLabel={''}>
                {x + 1}
              </Pagination.Item>
            </LinkContainer>
          ))}
        {page < pages - 5 && <Pagination.Item>...</Pagination.Item>}
        {page < pages && (
          <LinkContainer
            key='next'
            to={
              !isAdmin
                ? keyword
                  ? `/myquestions/search/${keyword}/page/${page + 1}`
                  : `/myquestions/page/${page + 1}`
                : `/myquestions/page/${page + 1}`
            }
          >
            <Pagination.Item>Next</Pagination.Item>
          </LinkContainer>
        )}
        <LinkContainer
          key='last'
          to={
            !isAdmin
              ? keyword
                ? `/myquestions/search/${keyword}/page/${pages}`
                : `/myquestions/page/${1}`
              : `/myquestions/page/${pages}`
          }
        >
          <Pagination.Item>Last ({pages})</Pagination.Item>
        </LinkContainer>
      </Pagination>
    )
  )
}

export default PaginateMyQuestions
