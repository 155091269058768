export const LANGUAGE_PROFILE_FETCH_BARCHART_REQUEST =
  'LANGUAGE_PROFILE_FETCH_BARCHART_REQUEST'
export const LANGUAGE_PROFILE_FETCH_BARCHART_SUCCESS =
  'LANGUAGE_PROFILE_FETCH_BARCHART_SUCCESS'
export const LANGUAGE_PROFILE_FETCH_BARCHART_FAIL =
  'LANGUAGE_PROFILE_FETCH_BARCHART_FAIL'

export const LANGUAGE_PROFILE_FETCH_QUESTION_COUNT_REQUEST =
  'LANGUAGE_PROFILE_FETCH_QUESTION_COUNT_REQUEST'
export const LANGUAGE_PROFILE_FETCH_QUESTION_COUNT_SUCCESS =
  'LANGUAGE_PROFILE_FETCH_QUESTION_COUNT_SUCCESS'
export const LANGUAGE_PROFILE_FETCH_QUESTION_COUNT_FAIL =
  'LANGUAGE_PROFILE_FETCH_QUESTION_COUNT_FAIL'

export const LANGUAGE_PROFILE_FETCH_TOTAL_COUNT_REQUEST =
  'LANGUAGE_PROFILE_FETCH_TOTAL_COUNT_REQUEST'
export const LANGUAGE_PROFILE_FETCH_TOTAL_COUNT_SUCCESS =
  'LANGUAGE_PROFILE_FETCH_TOTAL_COUNT_SUCCESS'
export const LANGUAGE_PROFILE_FETCH_TOTAL_COUNT_FAIL =
  'LANGUAGE_PROFILE_FETCH_TOTAL_COUNT_FAIL'

export const LANGUAGE_PROFILE_FETCH_VOCAB_COUNT_REQUEST =
  'LANGUAGE_PROFILE_FETCH_VOCAB_COUNT_REQUEST'
export const LANGUAGE_PROFILE_FETCH_VOCAB_COUNT_SUCCESS =
  'LANGUAGE_PROFILE_FETCH_VOCAB_COUNT_SUCCESS'
export const LANGUAGE_PROFILE_FETCH_VOCAB_COUNT_FAIL =
  'LANGUAGE_PROFILE_FETCH_VOCAB_COUNT_FAIL'

export const LANGUAGE_PROFILE_FETCH_DONUT_COUNT_REQUEST =
  'LANGUAGE_PROFILE_FETCH_DONUT_COUNT_REQUEST'
export const LANGUAGE_PROFILE_FETCH_DONUT_COUNT_SUCCESS =
  'LANGUAGE_PROFILE_FETCH_DONUT_COUNT_SUCCESS'
export const LANGUAGE_PROFILE_FETCH_DONUT_COUNT_FAIL =
  'LANGUAGE_PROFILE_FETCH_DONUT_COUNT_FAIL'
