import React from 'react'
import ForthcomingBox from '../components/ForthcomingBox'
import classes from './TeacherTools.module.css'

const QuizGeneratorScreen = () => {
  return (
    <div className={classes.container}>
      <div>QuizGeneratorScreen</div>
      <ForthcomingBox message={'...Still in development ;)'} />
    </div>
  )
}

export default QuizGeneratorScreen
