import {
  FOLLOWER_CREATE_FAIL,
  FOLLOWER_CREATE_REQUEST,
  FOLLOWER_CREATE_RESET,
  FOLLOWER_CREATE_SUCCESS,
  FOLLOWER_DELETE_FAIL,
  FOLLOWER_DELETE_REQUEST,
  FOLLOWER_DELETE_SUCCESS,
  FOLLOWER_GET_CLASSROOMS_FAIL,
  FOLLOWER_GET_CLASSROOMS_REQUEST,
  FOLLOWER_GET_CLASSROOMS_SUCCESS,
  FOLLOWER_GET_STUDENTS_FAIL,
  FOLLOWER_GET_STUDENTS_REQUEST,
  FOLLOWER_GET_STUDENTS_SUCCESS,
} from "../constants/followerConstants"

export const followerCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOWER_CREATE_REQUEST:
      return { loading: true }
    case FOLLOWER_CREATE_SUCCESS:
      return { loading: false, success: true, follower: action.payload }
    case FOLLOWER_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case FOLLOWER_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const followerGetClassroomsReducer = (
  state = { classrooms: [] },
  action
) => {
  switch (action.type) {
    case FOLLOWER_GET_CLASSROOMS_REQUEST:
      return {
        loading: true,
      }
    case FOLLOWER_GET_CLASSROOMS_SUCCESS:
      return {
        loading: false,
        classrooms: action.payload,
      }
    case FOLLOWER_GET_CLASSROOMS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const followerGetStudentsReducer = (
  state = { students: [] },
  action
) => {
  switch (action.type) {
    case FOLLOWER_GET_STUDENTS_REQUEST:
      return {
        loading: true,
      }
    case FOLLOWER_GET_STUDENTS_SUCCESS:
      return {
        loading: false,
        students: action.payload,
      }
    case FOLLOWER_GET_STUDENTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const followerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOWER_DELETE_REQUEST:
      return { loading: true }
    case FOLLOWER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case FOLLOWER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
