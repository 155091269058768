import React from 'react'

import { HelmetProvider, Helmet } from 'react-helmet-async'

const Meta = ({ title, description, keywords }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta name='keyword' content={keywords} />
      </Helmet>
    </HelmetProvider>
  )
}

Meta.defaultProps = {
  title: 'Lëbe',
  description: 'AI-Powered Language Learning',
  keywords: 'language learning',
}

export default Meta
