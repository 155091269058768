import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import AboutNavbar from '../components/AboutNavbar'
import ParagraphBox from '../components/ParagraphBox'
import classes from './AboutScreen.module.css'

const MissionAboutScreen = () => {
  return (
    <div>
      <AboutNavbar />
      <div className={classes.scrollContainer}>
        <ParagraphBox
          title={'Education Access'}
          paragraph={
            "Throughout the world there are many learners of all ages that don't have access to high-quality language education tools. Because knowing another language creates economic opportunities and fosters intercultural exchange, we hope that Lëbe Language will allow learners of different backgrounds to enjoy an ad-free ed-tech experience. Although we plan to introduce a freemium model to generate revenue, subscription access will be marketed toward learners seeking to ace high-stakes standardized tests or excel in a professional capacity. As such, we hope that Lëbe Language will remain free and accessible for students seeking to attain language competencies at or below the 'university' level."
          }
        />
        <ParagraphBox
          title={'Teacher Support'}
          paragraph={
            "The Lëbe Language platform is designed not only to optimize language learning, but also language teaching. That's why we've included the resources for teachers to create custom modules for their students and track their progress. The teacher tools are designed for teachers with mixed-ability classrooms so that students can learn at their own pace. The world expects a lot from teachers, so we hope the Lëbe Language platform can help ease the burden."
          }
        />
        <ParagraphBox
          title={'Language Technology'}
          paragraph={
            "In addition to our commitment to education access and equity, Lëbe Language is also a data science company that specializes in natural language processing. We're constantly seeking out the latest language models and NLP tools to generate high-quality language-learning content. Moreover, by harnessing the power of machine learning in our learning analytics, we continue to further optimize our system to maximize measurable progress among users."
          }
        />
      </div>
    </div>
  )
}
export default MissionAboutScreen
