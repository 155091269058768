import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import classes from './Dashboard.module.css'
import { Col, Row, Table } from 'react-bootstrap'

const TrickyWordsTable = (props) => {
  return (
    <div>
      <div className={classes.tableContainer} id='heatmap'>
        {/* <Table striped bordered hover responsive className='table-sm'>
          <tbody>
            {props.TrickyWords.map((word, index) => (
              <tr key={word.group}>
                <td>{word.group}</td>
              </tr>
            ))}
          </tbody>
        </Table> */}
        <h2 className={classes.trickyWordsHeader}>Your Tricky Words</h2>
        <Row>
          {props.TrickyWords.map((word, index) => (
            <Col className={classes.trickyWordContainer} key={word.group}>
              <h2 className={classes.trickyWordText}>{word.group}</h2>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}

export default TrickyWordsTable
