import React, { useEffect, useState } from 'react'
import classes from './LevelBar.module.css'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { getMyQuestionRandom } from '../actions/myQuestionActions'

const LevelBarCustom = (props) => {
  let dispatch = useDispatch()

  const [levelList, setLevelList] = useState([])

  const changeLevelHandler = (e) => {
    dispatch(
      getMyQuestionRandom(props.id, { level: props.levels[e.target.value] })
    )
    props.setScoreList([])
    props.setCurrentLevelIndex(Number(e.target.value))
    props.setTotalScore(0)
  }

  useEffect(() => {
    setLevelList(
      Array.from('1'.repeat(props.maxUserLevelIndex + 1)).concat(
        Array.from(
          '0'.repeat(props.levels.length - (props.maxUserLevelIndex + 1))
        )
      )
    )
  }, [props.maxUserLevelIndex, props.levels])

  return (
    <div className={classes.levelBarContainer}>
      {levelList.map((level, index) =>
        Number(level) > 0 ? (
          <Button
            className={classes.levelButton}
            key={`levelButton${index + 1}`}
            value={index}
            onClick={changeLevelHandler}
          ></Button>
        ) : (
          <Button
            className={classes.levelButton}
            key={`levelButton${index + 1}`}
            value={index}
            disabled={true}
            onClick={changeLevelHandler}
          ></Button>
        )
      )}
    </div>
  )
}

export default LevelBarCustom
