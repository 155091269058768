export const FETCH_CLASSROOMS_REQUEST = 'FETCH_CLASSROOMS_REQUEST'
export const FETCH_CLASSROOMS_SUCCESS = 'FETCH_CLASSROOMS_SUCCESS'
export const FETCH_CLASSROOMS_FAIL = 'FETCH_CLASSROOMS_FAIL'

export const FETCH_USER_CLASSROOMS_REQUEST = 'FETCH_USER_CLASSROOMS_REQUEST'
export const FETCH_USER_CLASSROOMS_SUCCESS = 'FETCH_USER_CLASSROOMS_SUCCESS'
export const FETCH_USER_CLASSROOMS_FAIL = 'FETCH_CLASSROOMS_FAIL'
export const FETCH_USER_CLASSROOMS_RESET = 'FETCH_CLASSROOMS_RESET'

export const FETCH_CLASSROOM_DETAILS_REQUEST =
  'FETCH_USER_CLASSROOM_DETAILS_REQUEST'
export const FETCH_CLASSROOM_DETAILS_SUCCESS =
  'FETCH_USER_CLASSROOM_DETAILS_SUCCESS'
export const FETCH_CLASSROOM_DETAILS_FAIL = 'FETCH_CLASSROOM_DETAILS_FAIL'
export const FETCH_CLASSROOM_DETAILS_RESET = 'FETCH_CLASSROOM_DETAILS_RESET'

export const CREATE_CLASSROOM_REQUEST = 'CREATE_CLASSROOM_REQUEST'
export const CREATE_CLASSROOM_SUCCESS = 'CREATE_CLASSROOM_SUCCESS'
export const CREATE_CLASSROOM_FAIL = 'CREATE_CLASSROOM_FAIL'
export const CREATE_CLASSROOM_RESET = 'CREATE_CLASSROOM_RESET'

export const CLASSROOM_UPDATE_REQUEST = 'CLASSROOM_UPDATE_REQUEST'
export const CLASSROOM_UPDATE_SUCCESS = 'CLASSROOM_UPDATE_SUCCESS'
export const CLASSROOM_UPDATE_FAIL = 'CLASSROOM_UPDATE_FAIL'
export const CLASSROOM_UPDATE_RESET = 'CLASSROOM_UPDATE_RESET'

export const CLASSROOM_DELETE_REQUEST = 'CLASSROOM_DELETE_REQUEST'
export const CLASSROOM_DELETE_SUCCESS = 'CLASSROOM_DELETE_SUCCESS'
export const CLASSROOM_DELETE_FAIL = 'CLASSROOM_DELETE_FAIL'

export const CLASSROOM_REGISTER_REQUEST = 'CLASSROOM_REGISTER_REQUEST'
export const CLASSROOM_REGISTER_SUCCESS = 'CLASSROOM_REGISTER_SUCCESS'
export const CLASSROOM_REGISTER_FAIL = 'CLASSROOM_REGISTER_FAIL'
export const CLASSROOM_REGISTER_RESET = 'CLASSROOM_REGISTER_RESET'

export const CLASSROOM_LOGIN_REQUEST = 'CLASSROOM_LOGIN_REQUEST'
export const CLASSROOM_LOGIN_SUCCESS = 'CLASSROOM_LOGIN_SUCCESS'
export const CLASSROOM_LOGIN_FAIL = 'CLASSROOM_LOGIN_FAIL'
export const CLASSROOM_LOGIN_RESET = 'CLASSROOM_LOGIN_RESET'
