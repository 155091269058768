export const languageData = {
  dutch: {
    language: 'Dutch',
    levels: 8,
    totalVocab: 9939,
    totalQuestions: 270390,
    barChartData: [
      {
        y: 1142,
        x: 1,
        color: 'translation',
      },
      {
        y: 152,
        x: 1,
        color: 'conjugation',
      },
      {
        y: 64,
        x: 1,
        color: 'vocabulary',
      },
      {
        y: 864,
        x: 2,
        color: 'conjugation',
      },
      {
        y: 947,
        x: 2,
        color: 'translation',
      },
      {
        y: 107,
        x: 2,
        color: 'vocabulary',
      },
      {
        y: 2281,
        x: 3,
        color: 'translation',
      },
      {
        y: 3380,
        x: 3,
        color: 'conjugation',
      },
      {
        y: 68,
        x: 3,
        color: 'vocabulary',
      },
      {
        y: 146791,
        x: 4,
        color: 'vocabulary',
      },
      {
        y: 6928,
        x: 4,
        color: 'conjugation',
      },
      {
        y: 9606,
        x: 4,
        color: 'translation',
      },
      {
        y: 4837,
        x: 5,
        color: 'translation',
      },
      {
        y: 62238,
        x: 5,
        color: 'vocabulary',
      },
      {
        y: 2899,
        x: 5,
        color: 'conjugation',
      },
      {
        y: 704,
        x: 5,
        color: 'synonyms',
      },
      {
        y: 1259,
        x: 6,
        color: 'synonyms',
      },
      {
        y: 1569,
        x: 6,
        color: 'conjugation',
      },
      {
        y: 19560,
        x: 6,
        color: 'vocabulary',
      },
      {
        y: 2179,
        x: 6,
        color: 'translation',
      },
      {
        y: 1202,
        x: 7,
        color: 'synonyms',
      },
      {
        y: 1613,
        x: 8,
        color: 'synonyms',
      },
    ],
  },
  dzongkha: {
    language: 'Dzongkha',
    levels: 10,
    totalVocab: 18146,
    totalQuestions: 36610,
    barChartData: [
      {
        y: 222,
        x: 1,
        color: 'translation',
      },
      {
        y: 380,
        x: 2,
        color: 'translation',
      },
      {
        y: 614,
        x: 3,
        color: 'translation',
      },
      {
        y: 6050,
        x: 4,
        color: 'translation',
      },
      {
        y: 4678,
        x: 5,
        color: 'translation',
      },
      {
        y: 9740,
        x: 6,
        color: 'translation',
      },
      {
        y: 8166,
        x: 7,
        color: 'translation',
      },
      {
        y: 4600,
        x: 8,
        color: 'translation',
      },
      {
        y: 930,
        x: 9,
        color: 'translation',
      },
      {
        y: 1230,
        x: 10,
        color: 'translation',
      },
    ],
  },
  english: {
    language: 'English',
    levels: 10,
    totalVocab: 22366,
    totalQuestions: 212746,
    barChartData: [
      {
        y: 5,
        x: 1,
        color: 'vocabulary',
      },
      {
        y: 9384,
        x: 1,
        color: 'conjugation',
      },
      {
        y: 98,
        x: 2,
        color: 'vocabulary',
      },
      {
        y: 18744,
        x: 2,
        color: 'conjugation',
      },
      {
        y: 218,
        x: 3,
        color: 'vocabulary',
      },
      {
        y: 31585,
        x: 3,
        color: 'conjugation',
      },
      {
        y: 23834,
        x: 4,
        color: 'vocabulary',
      },
      {
        y: 44550,
        x: 4,
        color: 'conjugation',
      },
      {
        y: 741,
        x: 5,
        color: 'synonyms',
      },
      {
        y: 9944,
        x: 5,
        color: 'vocabulary',
      },
      {
        y: 720,
        x: 5,
        color: 'conjugation',
      },
      {
        y: 1473,
        x: 6,
        color: 'synonyms',
      },
      {
        y: 13872,
        x: 6,
        color: 'vocabulary',
      },
      {
        y: 1089,
        x: 7,
        color: 'synonyms',
      },
      {
        y: 17119,
        x: 7,
        color: 'vocabulary',
      },
      {
        y: 11887,
        x: 8,
        color: 'vocabulary',
      },
      {
        y: 5262,
        x: 8,
        color: 'synonyms',
      },
      {
        y: 4810,
        x: 9,
        color: 'synonyms',
      },
      {
        y: 5912,
        x: 9,
        color: 'vocabulary',
      },
      {
        y: 3699,
        x: 10,
        color: 'synonyms',
      },
      {
        y: 5574,
        x: 10,
        color: 'vocabulary',
      },
    ],
  },
  french: {
    language: 'French',
    levels: 10,
    totalVocab: 35167,
    totalQuestions: 718436,
    barChartData: [
      {
        y: 296,
        x: 1,
        color: 'conjugation',
      },
      {
        y: 4514,
        x: 1,
        color: 'translation',
      },
      {
        y: 5144,
        x: 2,
        color: 'translation',
      },
      {
        y: 664,
        x: 2,
        color: 'conjugation',
      },
      {
        y: 13960,
        x: 3,
        color: 'conjugation',
      },
      {
        y: 15286,
        x: 3,
        color: 'translation',
      },
      {
        y: 67748,
        x: 4,
        color: 'translation',
      },
      {
        y: 54962,
        x: 4,
        color: 'vocabulary',
      },
      {
        y: 17120,
        x: 4,
        color: 'conjugation',
      },
      {
        y: 1558,
        x: 5,
        color: 'synonyms',
      },
      {
        y: 37132,
        x: 5,
        color: 'vocabulary',
      },
      {
        y: 42630,
        x: 5,
        color: 'translation',
      },
      {
        y: 12952,
        x: 5,
        color: 'conjugation',
      },
      {
        y: 10898,
        x: 6,
        color: 'conjugation',
      },
      {
        y: 2612,
        x: 6,
        color: 'synonyms',
      },
      {
        y: 71432,
        x: 6,
        color: 'translation',
      },
      {
        y: 49956,
        x: 6,
        color: 'vocabulary',
      },
      {
        y: 2666,
        x: 7,
        color: 'synonyms',
      },
      {
        y: 36450,
        x: 7,
        color: 'conjugation',
      },
      {
        y: 43140,
        x: 7,
        color: 'translation',
      },
      {
        y: 32526,
        x: 7,
        color: 'vocabulary',
      },
      {
        y: 7996,
        x: 8,
        color: 'conjugation',
      },
      {
        y: 11182,
        x: 8,
        color: 'vocabulary',
      },
      {
        y: 7846,
        x: 8,
        color: 'synonyms',
      },
      {
        y: 14064,
        x: 8,
        color: 'translation',
      },
      {
        y: 10094,
        x: 9,
        color: 'synonyms',
      },
      {
        y: 15836,
        x: 9,
        color: 'conjugation',
      },
      {
        y: 12738,
        x: 9,
        color: 'vocabulary',
      },
      {
        y: 26864,
        x: 9,
        color: 'translation',
      },
      {
        y: 34424,
        x: 10,
        color: 'translation',
      },
      {
        y: 35064,
        x: 10,
        color: 'conjugation',
      },
      {
        y: 9824,
        x: 10,
        color: 'synonyms',
      },
      {
        y: 8858,
        x: 10,
        color: 'vocabulary',
      },
    ],
  },
  german: {
    language: 'German',
    levels: 10,
    totalVocab: 45256,
    totalQuestions: 250573,
    barChartData: [
      {
        y: 877,
        x: 1,
        color: 'translation',
      },
      {
        y: 64,
        x: 1,
        color: 'conjugation',
      },
      {
        y: 464,
        x: 1,
        color: 'vocabulary',
      },
      {
        y: 769,
        x: 2,
        color: 'translation',
      },
      {
        y: 116,
        x: 2,
        color: 'conjugation',
      },
      {
        y: 270,
        x: 2,
        color: 'vocabulary',
      },
      {
        y: 988,
        x: 3,
        color: 'conjugation',
      },
      {
        y: 489,
        x: 3,
        color: 'vocabulary',
      },
      {
        y: 1772,
        x: 3,
        color: 'translation',
      },
      {
        y: 6916,
        x: 4,
        color: 'translation',
      },
      {
        y: 3776,
        x: 4,
        color: 'conjugation',
      },
      {
        y: 25126,
        x: 4,
        color: 'vocabulary',
      },
      {
        y: 826,
        x: 5,
        color: 'synonyms',
      },
      {
        y: 6036,
        x: 5,
        color: 'translation',
      },
      {
        y: 20204,
        x: 5,
        color: 'vocabulary',
      },
      {
        y: 2260,
        x: 5,
        color: 'conjugation',
      },
      {
        y: 5870,
        x: 6,
        color: 'conjugation',
      },
      {
        y: 1389,
        x: 6,
        color: 'synonyms',
      },
      {
        y: 2833,
        x: 6,
        color: 'translation',
      },
      {
        y: 24355,
        x: 6,
        color: 'vocabulary',
      },
      {
        y: 1413,
        x: 7,
        color: 'synonyms',
      },
      {
        y: 2562,
        x: 7,
        color: 'conjugation',
      },
      {
        y: 1763,
        x: 7,
        color: 'translation',
      },
      {
        y: 16131,
        x: 7,
        color: 'vocabulary',
      },
      {
        y: 1092,
        x: 8,
        color: 'conjugation',
      },
      {
        y: 6348,
        x: 8,
        color: 'vocabulary',
      },
      {
        y: 4371,
        x: 8,
        color: 'synonyms',
      },
      {
        y: 394,
        x: 8,
        color: 'translation',
      },
      {
        y: 5952,
        x: 9,
        color: 'synonyms',
      },
      {
        y: 10590,
        x: 9,
        color: 'vocabulary',
      },
      {
        y: 2156,
        x: 9,
        color: 'conjugation',
      },
      {
        y: 698,
        x: 9,
        color: 'translation',
      },
      {
        y: 2479,
        x: 10,
        color: 'translation',
      },
      {
        y: 13944,
        x: 10,
        color: 'conjugation',
      },
      {
        y: 37592,
        x: 10,
        color: 'synonyms',
      },
      {
        y: 37688,
        x: 10,
        color: 'vocabulary',
      },
    ],
  },
  portuguese: {
    language: 'Portuguese',
    levels: 10,
    totalVocab: 25997,
    totalQuestions: 289906,
    barChartData: [
      {
        y: 64,
        x: 1,
        color: 'conjugation',
      },
      {
        y: 43,
        x: 1,
        color: 'vocabulary',
      },
      {
        y: 1666,
        x: 1,
        color: 'translation',
      },
      {
        y: 2014,
        x: 2,
        color: 'translation',
      },
      {
        y: 396,
        x: 2,
        color: 'conjugation',
      },
      {
        y: 6,
        x: 2,
        color: 'vocabulary',
      },
      {
        y: 7,
        x: 3,
        color: 'vocabulary',
      },
      {
        y: 2529,
        x: 3,
        color: 'conjugation',
      },
      {
        y: 5435,
        x: 3,
        color: 'translation',
      },
      {
        y: 21463,
        x: 4,
        color: 'translation',
      },
      {
        y: 13044,
        x: 4,
        color: 'conjugation',
      },
      {
        y: 36655,
        x: 4,
        color: 'vocabulary',
      },
      {
        y: 1432,
        x: 5,
        color: 'synonyms',
      },
      {
        y: 12016,
        x: 5,
        color: 'translation',
      },
      {
        y: 22692,
        x: 5,
        color: 'vocabulary',
      },
      {
        y: 4912,
        x: 5,
        color: 'conjugation',
      },
      {
        y: 8838,
        x: 6,
        color: 'conjugation',
      },
      {
        y: 2279,
        x: 6,
        color: 'synonyms',
      },
      {
        y: 27908,
        x: 6,
        color: 'vocabulary',
      },
      {
        y: 19213,
        x: 6,
        color: 'translation',
      },
      {
        y: 1601,
        x: 7,
        color: 'synonyms',
      },
      {
        y: 13292,
        x: 7,
        color: 'translation',
      },
      {
        y: 14556,
        x: 7,
        color: 'conjugation',
      },
      {
        y: 13249,
        x: 7,
        color: 'vocabulary',
      },
      {
        y: 3495,
        x: 8,
        color: 'conjugation',
      },
      {
        y: 3596,
        x: 8,
        color: 'vocabulary',
      },
      {
        y: 5481,
        x: 8,
        color: 'synonyms',
      },
      {
        y: 3815,
        x: 8,
        color: 'translation',
      },
      {
        y: 8400,
        x: 9,
        color: 'synonyms',
      },
      {
        y: 2837,
        x: 9,
        color: 'vocabulary',
      },
      {
        y: 9621,
        x: 9,
        color: 'conjugation',
      },
      {
        y: 6740,
        x: 9,
        color: 'translation',
      },
      {
        y: 2483,
        x: 10,
        color: 'vocabulary',
      },
      {
        y: 4548,
        x: 10,
        color: 'translation',
      },
      {
        y: 9071,
        x: 10,
        color: 'conjugation',
      },
      {
        y: 4509,
        x: 10,
        color: 'synonyms',
      },
    ],
  },
  sango: {
    language: 'Sango',
    levels: 10,
    totalVocab: 1869,
    totalQuestions: 3746,
    barChartData: [
      {
        y: 142,
        x: 1,
        color: 'translation',
      },
      {
        y: 328,
        x: 2,
        color: 'translation',
      },
      {
        y: 582,
        x: 3,
        color: 'translation',
      },
      {
        y: 730,
        x: 4,
        color: 'translation',
      },
      {
        y: 330,
        x: 5,
        color: 'translation',
      },
      {
        y: 606,
        x: 6,
        color: 'translation',
      },
      {
        y: 542,
        x: 7,
        color: 'translation',
      },
      {
        y: 310,
        x: 8,
        color: 'translation',
      },
      {
        y: 82,
        x: 9,
        color: 'translation',
      },
      {
        y: 94,
        x: 10,
        color: 'translation',
      },
    ],
  },
  spanish: {
    language: 'Spanish',
    levels: 10,
    totalVocab: 33775,
    totalQuestions: 580495,
    barChartData: [
      {
        y: 4632,
        x: 1,
        color: 'translation',
      },
      {
        y: 208,
        x: 1,
        color: 'conjugation',
      },
      {
        y: 392,
        x: 2,
        color: 'conjugation',
      },
      {
        y: 4616,
        x: 2,
        color: 'translation',
      },
      {
        y: 11956,
        x: 3,
        color: 'translation',
      },
      {
        y: 6000,
        x: 3,
        color: 'conjugation',
      },
      {
        y: 35758,
        x: 4,
        color: 'vocabulary',
      },
      {
        y: 21720,
        x: 4,
        color: 'conjugation',
      },
      {
        y: 51234,
        x: 4,
        color: 'translation',
      },
      {
        y: 14006,
        x: 5,
        color: 'conjugation',
      },
      {
        y: 24163,
        x: 5,
        color: 'vocabulary',
      },
      {
        y: 30540,
        x: 5,
        color: 'translation',
      },
      {
        y: 1396,
        x: 5,
        color: 'synonyms',
      },
      {
        y: 12024,
        x: 6,
        color: 'conjugation',
      },
      {
        y: 30405,
        x: 6,
        color: 'vocabulary',
      },
      {
        y: 42744,
        x: 6,
        color: 'translation',
      },
      {
        y: 2380,
        x: 6,
        color: 'synonyms',
      },
      {
        y: 2362,
        x: 7,
        color: 'synonyms',
      },
      {
        y: 30486,
        x: 7,
        color: 'translation',
      },
      {
        y: 17074,
        x: 7,
        color: 'vocabulary',
      },
      {
        y: 25430,
        x: 7,
        color: 'conjugation',
      },
      {
        y: 11920,
        x: 8,
        color: 'translation',
      },
      {
        y: 6808,
        x: 8,
        color: 'synonyms',
      },
      {
        y: 5854,
        x: 8,
        color: 'vocabulary',
      },
      {
        y: 6208,
        x: 8,
        color: 'conjugation',
      },
      {
        y: 27554,
        x: 9,
        color: 'translation',
      },
      {
        y: 14352,
        x: 9,
        color: 'conjugation',
      },
      {
        y: 8087,
        x: 9,
        color: 'vocabulary',
      },
      {
        y: 8624,
        x: 9,
        color: 'synonyms',
      },
      {
        y: 8702,
        x: 10,
        color: 'vocabulary',
      },
      {
        y: 14988,
        x: 10,
        color: 'synonyms',
      },
      {
        y: 45068,
        x: 10,
        color: 'conjugation',
      },
      {
        y: 52804,
        x: 10,
        color: 'translation',
      },
    ],
  },
}
