import React from 'react'
import { Card } from 'react-bootstrap'
import classes from './Dashboard.module.css'

const fluencyDictionary = {
  1: 'Beginner',
  2: 'Advanced Beginner',
  3: 'Lower Intermediate',
  4: 'Intermediate',
  5: 'Upper Intermediate',
  6: 'Advanced',
  7: 'Highly Advanced',
  8: 'Emerging Expertise',
  9: 'Advanced Expertise',
  10: 'God of Language',
}

export const FluencyScoreBox = (props) => {
  return (
    <div>
      <Card className={classes.countContainer}>
        <Card.Body>
          <Card.Title>{props.message}</Card.Title>
          <h1>{props.data}</h1>
          <h1 className={classes.fluencyText}>
            {fluencyDictionary[Math.round(props.data)]}
          </h1>
        </Card.Body>
      </Card>
    </div>
  )
}
