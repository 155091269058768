import React, { useEffect } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap'
import Loader from './Loader'

import classes from './QuestionMultipleChoiceDzongkha.module.css'

export const QuestionMultipleChoiceDzongkha = (props) => {
  useEffect(() => {
    if (props.buttonTextSize) {
      document.getElementById('questionCard').style.fontSize =
        props.buttonTextSize
    }
  }, [props.buttonTextSize])

  useEffect(() => {
    if (
      (document.getElementById('skipButton') !== null) &
      (document.getElementById('giveUpButton') !== null)
    ) {
      document.getElementById('giveUpButton').disabled = false
      document.getElementById('skipButton').disabled = false
    }
  }, [props.questionData])

  const giveUpHandler = (event) => {
    props.setScoreList((scoreList) => [...scoreList, 0].slice(-5))

    for (let i = 1; i <= props.questionData.answers.length; i++) {
      if (document.getElementById(i).value == props.correctAnswer) {
        document.getElementById(i).style.backgroundColor = props.correctColor
      }
      if (document.getElementById(i) !== null) {
        document.getElementById(i).disabled = true
      }
    }
    document.getElementById('giveUpButton').disabled = true
    document.getElementById('nextButton').disabled = false
    document.getElementById('skipButton').disabled = true
  }

  // const answerButtonHandler = (event) => {
  //   if (event.target.value === correctAnswer) {
  //     setScoreList((scoreList) => [...scoreList, 1].slice(-10))
  //     document.getElementById(event.target.id).style.backgroundColor =
  //       correctColor
  //     document.getElementById('overDiv').focus()
  //     for (let i = 1; i <= questionData.answers.length; i++) {
  //       if (document.getElementById(i) !== null) {
  //         document.getElementById(i).disabled = true
  //       }
  //     }
  //     document.getElementById('nextButton').disabled = false
  //     document.getElementById('skipButton').disabled = true
  //   } else {
  //     document.getElementById(event.target.id).style.backgroundColor =
  //       incorrectColor
  //     document.getElementById(event.target.id).disabled = true
  //     setScoreList((scoreList) => [...scoreList, 0].slice(-10))
  //   }
  // }

  const multipleChoice = (
    <div className={classes.questionContainer}>
      <Button
        id={'giveUpButton'}
        className={classes.giveUpButton}
        onClick={giveUpHandler}
      >
        {'show answer'}
      </Button>
      <Card id={'questionCard'} className={classes.textCard}>
        <Card.Body>
          <Card.Text>{props.questionData.question}</Card.Text>
        </Card.Body>
      </Card>
      <Row>
        <Col className='my-5'>
          {!props.shuffledAnswerList ? (
            <p>Loading...</p>
          ) : (
            props.shuffledAnswerList.map((item, index) => (
              <React.Fragment key={`${index + 10}uniqueFragmentkey`}>
                <a key={`${index}uniqueAkey`}> {index + 1} </a>
                <button
                  id={index + 1}
                  key={`${index}uniquekey`}
                  value={item.text}
                  onClick={props.answerButtonHandler}
                  className={
                    item.isCorrect === props.correctAnswer
                      ? classes.buttonsCorrect
                      : classes.buttonsIncorrect
                  }
                >
                  {item.text}
                </button>
              </React.Fragment>
            ))
          )}
        </Col>
      </Row>
    </div>
  )
  return (
    <>
      {props.loadingQuestion ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        multipleChoice
      )}
    </>
  )
}
