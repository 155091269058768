import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import classes from './ParagraphBox.module.css'
import { LinkContainer } from 'react-router-bootstrap'

const ParagraphPictureBox = (props) => {
  return (
    <div>
      {/* <LinkContainer to={`/studentclassrooms/${classroom.classroom._id}`}> */}
      <Card className={classes.paragraphCard}>
        <Row>
          <Col>
            <Card.Body>
              <Card.Title as='h3'>
                <strong>
                  <h3 className={classes.paragraphHeader}>{props.title}</h3>
                </strong>
              </Card.Title>
              <Card.Text as='div'>
                {<h3 className={classes.h3}>{props.paragraph}</h3>}
              </Card.Text>
            </Card.Body>
          </Col>
          <Col>
            <img className={classes.img} src={'/images/seanifer.jpeg'} />
          </Col>
        </Row>
      </Card>
      {/* // </LinkContainer> */}
    </div>
  )
}

export default ParagraphPictureBox
