import axios from 'axios'
import {
  FETCH_QUESTION_REQUEST,
  FETCH_QUESTION_SUCCESS,
  FETCH_QUESTION_FAIL,
  FILTER_QUESTIONS_REQUEST,
  FILTER_QUESTIONS_SUCCESS,
  FILTER_QUESTIONS_FAIL,
  GET_QUESTION_DETAILS_REQUEST,
  GET_QUESTION_DETAILS_SUCCESS,
  GET_QUESTION_DETAILS_FAIL,
  QUESTION_DELETE_REQUEST,
  QUESTION_DELETE_SUCCESS,
  QUESTION_DELETE_FAIL,
  QUESTION_CREATE_REQUEST,
  QUESTION_CREATE_SUCCESS,
  QUESTION_CREATE_FAIL,
  UPDATE_QUESTION_DETAILS_REQUEST,
  UPDATE_QUESTION_DETAILS_SUCCESS,
  UPDATE_QUESTION_DETAILS_FAIL,
  QUESTION_FLAG_REQUEST,
  QUESTION_FLAG_SUCCESS,
  QUESTION_FLAG_FAIL,
  QUESTION_UNFLAG_REQUEST,
  QUESTION_UNFLAG_SUCCESS,
  QUESTION_UNFLAG_FAIL,
} from '../constants/questionConstants'
import { logout } from './userActions'
import { API } from '../apiConstants'

export const getQuestions = (api_filter_info) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_QUESTION_REQUEST })

    const { data } = await axios.get(`${API}/api/questions`, {
      params: api_filter_info,
    })

    dispatch({ type: FETCH_QUESTION_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: FETCH_QUESTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const filterQuestions =
  (filter, keyword = '', pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      dispatch({ type: FILTER_QUESTIONS_REQUEST })

      const { data } = await axios.get(
        `${API}/api/questions/filter/?keyword=${keyword}&pageNumber=${pageNumber}`,
        {
          params: filter,
        },

        config
      )

      dispatch({
        type: FILTER_QUESTIONS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: FILTER_QUESTIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getQuestionDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_QUESTION_DETAILS_REQUEST })

    const { data } = await axios.get(`${API}/api/questions/${id}`)

    dispatch({
      type: GET_QUESTION_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_QUESTION_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteQuestion = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUESTION_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`${API}/api/questions/${id}`, config)

    dispatch({
      type: QUESTION_DELETE_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: QUESTION_DELETE_FAIL,
      payload: message,
    })
  }
}

export const createQuestion = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUESTION_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`${API}/api/questions`, {}, config)

    dispatch({
      type: QUESTION_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: QUESTION_CREATE_FAIL,
      payload: message,
    })
  }
}

export const updateQuestion = (question) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_QUESTION_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${API}/api/questions/${question._id}`,
      question,
      config
    )

    dispatch({
      type: UPDATE_QUESTION_DETAILS_SUCCESS,
      payload: data,
    })
    dispatch({ type: GET_QUESTION_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: UPDATE_QUESTION_DETAILS_FAIL,
      payload: message,
    })
  }
}

export const flagQuestion = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUESTION_FLAG_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${API}/api/questions/flag/${id}`,
      {},
      config
    )

    dispatch({
      type: QUESTION_FLAG_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: QUESTION_FLAG_FAIL,
      payload: message,
    })
  }
}

export const unflagQuestion = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUESTION_UNFLAG_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${API}/api/questions/flag/${id}`,
      {},
      config
    )

    dispatch({
      type: QUESTION_UNFLAG_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: QUESTION_UNFLAG_FAIL,
      payload: message,
    })
  }
}
