import {
  MY_DASHBOARD_ANSWER_COUNT_FAIL,
  MY_DASHBOARD_ANSWER_COUNT_REQUEST,
  MY_DASHBOARD_ANSWER_COUNT_SUCCESS,
  MY_DASHBOARD_DONUT_DATA_FAIL,
  MY_DASHBOARD_DONUT_DATA_REQUEST,
  MY_DASHBOARD_DONUT_DATA_SUCCESS,
  MY_DASHBOARD_POST_DATA_FAIL,
  MY_DASHBOARD_POST_DATA_REQUEST,
  MY_DASHBOARD_POST_DATA_SUCCESS,
  MY_DASHBOARD_QUESTION_COUNT_FAIL,
  MY_DASHBOARD_QUESTION_COUNT_REQUEST,
  MY_DASHBOARD_QUESTION_COUNT_SUCCESS,
} from '../constants/myDashboardConstants'

export const myDashboardPostDataReducer = (state = {}, action) => {
  switch (action.type) {
    case MY_DASHBOARD_POST_DATA_REQUEST:
      return { loading: true }
    case MY_DASHBOARD_POST_DATA_SUCCESS:
      return { loading: false, success: true, answer: action.payload }
    case MY_DASHBOARD_POST_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const myDashboardQuestionCountReducer = (state = {}, action) => {
  switch (action.type) {
    case MY_DASHBOARD_QUESTION_COUNT_REQUEST:
      return { loading: true }
    case MY_DASHBOARD_QUESTION_COUNT_SUCCESS:
      return {
        loading: false,
        questionCount: action.payload,
      }
    case MY_DASHBOARD_QUESTION_COUNT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const myDashboardAnswerCountReducer = (state = {}, action) => {
  switch (action.type) {
    case MY_DASHBOARD_ANSWER_COUNT_REQUEST:
      return { loading: true }
    case MY_DASHBOARD_ANSWER_COUNT_SUCCESS:
      return {
        loading: false,
        answerCount: action.payload,
      }
    case MY_DASHBOARD_ANSWER_COUNT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const myDashboardDonutDataReducer = (
  state = { donutData: [] },
  action
) => {
  switch (action.type) {
    case MY_DASHBOARD_DONUT_DATA_REQUEST:
      return { loading: true }
    case MY_DASHBOARD_DONUT_DATA_SUCCESS:
      return {
        loading: false,
        donutData: action.payload,
      }
    case MY_DASHBOARD_DONUT_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
