export const DASHBOARD_POST_DATA_REQUEST = 'DASHBOARD_POST_DATA_REQUEST'
export const DASHBOARD_POST_DATA_SUCCESS = 'DASHBOARD_POST_DATA_SUCCESS'
export const DASHBOARD_POST_DATA_FAIL = 'DASHBOARD_POST_DATA_FAIL'

export const DASHBOARD_STUDENT_GLOBAL_HEATMAP_REQUEST =
  'DASHBOARD_STUDENT_GLOBAL_HEATMAP_REQUEST'
export const DASHBOARD_STUDENT_GLOBAL_HEATMAP_SUCCESS =
  'DASHBOARD_STUDENT_GLOBAL_HEATMAP_SUCCESS'
export const DASHBOARD_STUDENT_GLOBAL_HEATMAP_FAIL =
  'DASHBOARD_STUDENT_GLOBAL_HEATMAP_FAIL'

export const DASHBOARD_STUDENT_TOTAL_ANSWERS_REQUEST =
  'DASHBOARD_STUDENT_TOTAL_ANSWERS_REQUEST'
export const DASHBOARD_STUDENT_TOTAL_ANSWERS_SUCCESS =
  'DASHBOARD_STUDENT_TOTAL_ANSWERS_SUCCESS'
export const DASHBOARD_STUDENT_TOTAL_ANSWERS_FAIL =
  'DASHBOARD_STUDENT_TOTAL_ANSWERS_FAIL'

export const DASHBOARD_STUDENT_TOTAL_QUESTIONS_REQUEST =
  'DASHBOARD_STUDENT_TOTAL_QUESTIONS_REQUEST'
export const DASHBOARD_STUDENT_TOTAL_QUESTIONS_SUCCESS =
  'DASHBOARD_STUDENT_TOTAL_QUESTIONS_SUCCESS'
export const DASHBOARD_STUDENT_TOTAL_QUESTIONS_FAIL =
  'DASHBOARD_STUDENT_TOTAL_QUESTIONS_FAIL'

export const DASHBOARD_GLOBAL_ACCURACY_DONUT_REQUEST =
  'DASHBOARD_GLOBAL_ACCURACY_DONUT_REQUEST'
export const DASHBOARD_GLOBAL_ACCURACY_DONUT_SUCCESS =
  'DASHBOARD_GLOBAL_ACCURACY_DONUT_SUCCESS'
export const DASHBOARD_GLOBAL_ACCURACY_DONUT_FAIL =
  'DASHBOARD_GLOBAL_ACCURACY_DONUT_FAIL'

export const DASHBOARD_GLOBAL_TIME_SERIES_REQUEST =
  'DASHBOARD_GLOBAL_TIME_SERIES_REQUEST'
export const DASHBOARD_GLOBAL_TIME_SERIES_SUCCESS =
  'DASHBOARD_GLOBAL_TIME_SERIES_SUCCESS'
export const DASHBOARD_GLOBAL_TIME_SERIES_FAIL =
  'DASHBOARD_GLOBAL_TIME_SERIES_FAIL'

export const DASHBOARD_GLOBAL_BAR_CHART_REQUEST =
  'DASHBOARD_GLOBAL_BAR_CHART_REQUEST'
export const DASHBOARD_GLOBAL_BAR_CHART_SUCCESS =
  'DASHBOARD_GLOBAL_BAR_CHART_SUCCESS'
export const DASHBOARD_GLOBAL_BAR_CHART_FAIL = 'DASHBOARD_GLOBAL_BAR_CHART_FAIL'

export const DASHBOARD_LANGUAGE_TOTAL_ANSWERS_REQUEST =
  'DASHBOARD_LANGUAGE_TOTAL_ANSWERS_REQUEST'
export const DASHBOARD_LANGUAGE_TOTAL_ANSWERS_SUCCESS =
  'DASHBOARD_LANGUAGE_TOTAL_ANSWERS_SUCCESS'
export const DASHBOARD_LANGUAGE_TOTAL_ANSWERS_FAIL =
  'DASHBOARD_LANGUAGE_TOTAL_ANSWERS_FAIL'

export const DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_REQUEST =
  'DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_REQUEST'
export const DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_SUCCESS =
  'DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_SUCCESS'
export const DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_FAIL =
  'DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_FAIL'

export const DASHBOARD_LANGUAGE_ACCURACY_DONUT_REQUEST =
  'DASHBOARD_LANGUAGE_ACCURACY_DONUT_REQUEST'
export const DASHBOARD_LANGUAGE_ACCURACY_DONUT_SUCCESS =
  'DASHBOARD_LANGUAGE_ACCURACY_DONUT_SUCCESS'
export const DASHBOARD_LANGUAGE_ACCURACY_DONUT_FAIL =
  'DASHBOARD_LANGUAGE_ACCURACY_DONUT_FAIL'

export const DASHBOARD_LANGUAGE_BAR_CHART_REQUEST =
  'DASHBOARD_LANGUAGE_BAR_CHART_REQUEST'
export const DASHBOARD_LANGUAGE_BAR_CHART_SUCCESS =
  'DASHBOARD_LANGUAGE_BAR_CHART_SUCCESS'
export const DASHBOARD_LANGUAGE_BAR_CHART_FAIL =
  'DASHBOARD_LANGUAGE_BAR_CHART_FAIL'

export const DASHBOARD_LANGUAGE_LEBE_SCORE_REQUEST =
  'DASHBOARD_LANGUAGE_LEBE_SCORE_REQUEST'
export const DASHBOARD_LANGUAGE_LEBE_SCORE_SUCCESS =
  'DASHBOARD_LANGUAGE_LEBE_SCORE_SUCCESS'
export const DASHBOARD_LANGUAGE_LEBE_SCORE_FAIL =
  'DASHBOARD_LANGUAGE_LEBE_SCORE_FAIL'

export const DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_REQUEST =
  'DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_REQUEST'
export const DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_SUCCESS =
  'DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_SUCCESS'
export const DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_FAIL =
  'DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_FAIL'

export const DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_REQUEST =
  'DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_REQUEST'
export const DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_SUCCESS =
  'DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_SUCCESS'
export const DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_FAIL =
  'DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_FAIL'

export const DASHBOARD_LANGUAGE_TRICKY_WORDS_REQUEST =
  'DASHBOARD_LANGUAGE_TRICKY_WORDS_REQUEST'
export const DASHBOARD_LANGUAGE_TRICKY_WORDS_SUCCESS =
  'DASHBOARD_LANGUAGE_TRICKY_WORDS_SUCCESS'
export const DASHBOARD_LANGUAGE_TRICKY_WORDS_FAIL =
  'DASHBOARD_LANGUAGE_TRICKY_WORDS_FAIL'

export const DASHBOARD_DELETE_ALL_DATA_REQUEST =
  'DASHBOARD_DELETE_ALL_DATA_REQUEST'
export const DASHBOARD_DELETE_ALL_DATA_SUCCESS =
  'DASHBOARD_DELETE_ALL_DATA_SUCCESS'
export const DASHBOARD_DELETE_ALL_DATA_FAIL = 'DASHBOARD_DELETE_ALL_DATA_FAIL'
