export const TEAM_LIST_REQUEST = "TEAM_LIST_REQUEST"
export const TEAM_LIST_SUCCESS = "TEAM_LIST_SUCCESS"
export const TEAM_LIST_FAIL = "TEAM_LIST_FAIL"

export const TEAM_DETAILS_REQUEST = "TEAM_DETAILS_REQUEST"
export const TEAM_DETAILS_SUCCESS = "TEAM_DETAILS_SUCCESS"
export const TEAM_DETAILS_FAIL = "TEAM_DETAILS_FAIL"

export const TEAM_DELETE_REQUEST = "TEAM_DELETE_REQUEST"
export const TEAM_DELETE_SUCCESS = "TEAM_DELETE_SUCCESS"
export const TEAM_DELETE_FAIL = "TEAM_DELETE_FAIL"

export const TEAM_CREATE_REQUEST = "TEAM_CREATE_REQUEST"
export const TEAM_CREATE_SUCCESS = "TEAM_CREATE_SUCCESS"
export const TEAM_CREATE_FAIL = "TEAM_CREATE_FAIL"
export const TEAM_CREATE_RESET = "TEAM_CREATE_RESET"

export const TEAM_UPDATE_REQUEST = "TEAM_UPDATE_REQUEST"
export const TEAM_UPDATE_SUCCESS = "TEAM_UPDATE_SUCCESS"
export const TEAM_UPDATE_FAIL = "TEAM_UPDATE_FAIL"
export const TEAM_UPDATE_RESET = "TEAM_UPDATE_RESET"
