import React from 'react'
import { Card } from 'react-bootstrap'
import classes from './ClassroomBoxTeacher.module.css'
import { LinkContainer } from 'react-router-bootstrap'

const ClassroomBoxTeacher = ({ classroom, deleteHandler }) => {
  return (
    <LinkContainer to={`/myclassrooms/${classroom._id}`}>
      <Card className={classes.classroomCard}>
        <Card.Body>
          <Card.Title as='div'>
            <strong>{classroom.name}</strong>
          </Card.Title>
          <Card.Title as='div'>
            <strong>{classroom.classroomCode}</strong>
          </Card.Title>
          <Card.Text as='div'>{classroom.teacher}</Card.Text>
          <Card.Text as='div'>{classroom.description}</Card.Text>
        </Card.Body>
      </Card>
    </LinkContainer>
  )
}

export default ClassroomBoxTeacher
