import {
  INITIALIZE_CUSTOM_QUESTION_MODULE,
  INITIALIZE_QUESTION_MODULE,
} from '../constants/startConstants'

export const initializeModule =
  (base, target, level) => async (dispatch, getState) => {
    dispatch({
      type: INITIALIZE_QUESTION_MODULE,
      payload: {
        userLanguage: base,
        targetLanguage: target,
        level: level,
      },
    })

    localStorage.setItem(
      'initializeParameters',
      JSON.stringify(getState().initialize.initializeParameters)
    )
  }

export const initializeCustomModule =
  (id, level) => async (dispatch, getState) => {
    dispatch({
      type: INITIALIZE_CUSTOM_QUESTION_MODULE,
      payload: {
        id: id,
        level: level,
      },
    })

    localStorage.setItem(
      'initializeCustomParameters',
      JSON.stringify(getState().initializeCustom.initializeParameters)
    )
  }
