import React from 'react'
import { Card } from 'react-bootstrap'
import classes from './LanguageProfile.module.css'

export const LanguageTitleBox = (props) => {
  return (
    <div>
      <Card className={classes.titleBox}>
        <Card.Body>
          {/* <h3 className={classes.languageLabel}>{props.title}</h3> */}
          <h1 className={classes.languageTitle}>{props.subtitle}</h1>
        </Card.Body>
      </Card>
    </div>
  )
}
