import React from 'react'
import classes from './SuggestionBird.module.css'

const SuggestionBird = (props) => {
  return (
    <div className={classes.container}>
      <h3>{props.message}</h3>
      <div>
        <img className={classes.img} src={'/images/endlessknot.png'} />
      </div>
    </div>
  )
}

export default SuggestionBird
