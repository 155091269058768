import { Container, Nav, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import TeamMember from '../components/TeamMember'
import Meta from '../components/Meta'
import { listTeam } from '../actions/teamActions'
import SearchBox from '../components/SearchBox'

import classes from './AboutScreen.module.css'
import AboutNavbar from '../components/AboutNavbar'

const TeamAboutScreen = () => {
  const { keyword } = useParams()

  const { pageNumber } = useParams()

  const dispatch = useDispatch()

  const teamList = useSelector((state) => state.teamList)
  const { loading, error, team, page, pages } = teamList

  useEffect(() => {
    dispatch(listTeam(keyword, pageNumber))
  }, [dispatch, keyword, pageNumber])

  return (
    <>
      <AboutNavbar />
      <div className={classes.scrollContainer}>
        <Meta />
        <Row className='mb-3 pt-3'>
          <Col>
            <div></div>
          </Col>
        </Row>
        <Row>
          {team.map((teamMember) => (
            <Col key={teamMember._id} sm={12} md={6} lg={4} xl={3}>
              <TeamMember teamMember={teamMember} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  )
}

export default TeamAboutScreen
