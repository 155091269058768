import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { classroomLoginHandler } from '../actions/classroomActions'
import { LinkContainer } from 'react-router-bootstrap'
import { CLASSROOM_LOGIN_RESET } from '../constants/classroomConstants'
import { FOLLOWER_CREATE_RESET } from '../constants/followerConstants'
import ModalClassroomLogin from '../components/ModalClassroomLogin'

const ClassroomLoginScreen = () => {
  const [classroomCode, setClassroomCode] = useState('')
  const [password, setPassword] = useState('')
  const [showModal, setShowModal] = useState(false)
  const handleModalClose = () => {
    setShowModal(false)
    dispatch({ type: CLASSROOM_LOGIN_RESET })
    dispatch({ type: FOLLOWER_CREATE_RESET })
  }

  let location = useLocation()
  let history = useNavigate()

  const dispatch = useDispatch()

  const classroomLogin = useSelector((state) => state.classroomLogin)
  const {
    loading: loadingClassroom,
    error: classroomError,
    classroomInfo,
  } = classroomLogin

  const followerCreate = useSelector((state) => state.followerCreate)
  const {
    loading: loadingFollower,
    error: followerError,
    follower,
  } = followerCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (!userInfo) {
      history('/login')
    }
  }, [dispatch, history, userInfo, classroomInfo, follower])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(classroomLoginHandler(classroomCode, password))
  }

  useEffect(() => {
    if (classroomInfo) {
      setShowModal(true)
    }
  }, [classroomInfo])

  const backHandler = () => {
    dispatch({ type: CLASSROOM_LOGIN_RESET })
    dispatch({ type: FOLLOWER_CREATE_RESET })
  }

  return (
    <>
      <Row>
        <Col>
          <LinkContainer to='/studentclassrooms'>
            <Button type='btn' variant='secondary' onClick={backHandler}>
              Back to Classrooms
            </Button>
          </LinkContainer>
          <FormContainer autoComplete='off'>
            <h1>Join a Classroom</h1>
            {error && <Message variant='danger'>{error}</Message>}
            {classroomError && (
              <Message variant='danger'>{classroomError}</Message>
            )}
            {loadingClassroom && <Loader />}
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='name'>
                <Form.Label>Classroom Code</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter classroom code'
                  value={classroomCode}
                  autoComplete='new-password'
                  onChange={(e) => setClassroomCode(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='password'>
                <Form.Label>Enter secret keycode</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Enter secret keycode'
                  value={password}
                  autoComplete='new-password'
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Button type='submit' variant='primary'>
                Sign In
              </Button>
            </Form>
          </FormContainer>
        </Col>
      </Row>

      <ModalClassroomLogin
        classroomInfo={classroomInfo}
        showModal={showModal}
        handleModalClose={handleModalClose}
      />
    </>
  )
}

export default ClassroomLoginScreen
