import {
  FETCH_CLASSROOMS_REQUEST,
  FETCH_CLASSROOMS_SUCCESS,
  FETCH_CLASSROOMS_FAIL,
  FETCH_USER_CLASSROOMS_REQUEST,
  FETCH_USER_CLASSROOMS_SUCCESS,
  FETCH_USER_CLASSROOMS_FAIL,
  FETCH_USER_CLASSROOMS_RESET,
  CREATE_CLASSROOM_REQUEST,
  CREATE_CLASSROOM_SUCCESS,
  CREATE_CLASSROOM_FAIL,
  CREATE_CLASSROOM_RESET,
  FETCH_CLASSROOM_DETAILS_REQUEST,
  FETCH_CLASSROOM_DETAILS_SUCCESS,
  FETCH_CLASSROOM_DETAILS_FAIL,
  CLASSROOM_UPDATE_REQUEST,
  CLASSROOM_UPDATE_SUCCESS,
  CLASSROOM_UPDATE_FAIL,
  CLASSROOM_UPDATE_RESET,
  CLASSROOM_DELETE_REQUEST,
  CLASSROOM_DELETE_SUCCESS,
  CLASSROOM_DELETE_FAIL,
  CLASSROOM_REGISTER_REQUEST,
  CLASSROOM_REGISTER_SUCCESS,
  CLASSROOM_REGISTER_FAIL,
  CLASSROOM_LOGIN_REQUEST,
  CLASSROOM_LOGIN_SUCCESS,
  CLASSROOM_LOGIN_FAIL,
  CLASSROOM_LOGIN_RESET,
  FETCH_CLASSROOM_DETAILS_RESET,
  CLASSROOM_REGISTER_RESET,
} from '../constants/classroomConstants'

export const classroomListReducer = (state = { classrooms: [] }, action) => {
  switch (action.type) {
    case FETCH_CLASSROOMS_REQUEST:
      return { loading: true, classrooms: [] }
    case FETCH_CLASSROOMS_SUCCESS:
      return {
        loading: false,
        classrooms: action.payload.classrooms,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case FETCH_CLASSROOMS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const classroomListMyReducer = (state = { classrooms: [] }, action) => {
  switch (action.type) {
    case FETCH_USER_CLASSROOMS_REQUEST:
      return {
        loading: true,
      }
    case FETCH_USER_CLASSROOMS_SUCCESS:
      return {
        loading: false,
        classrooms: action.payload,
      }
    case FETCH_USER_CLASSROOMS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case FETCH_USER_CLASSROOMS_RESET:
      return { classrooms: [] }
    default:
      return state
  }
}

export const classroomCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CLASSROOM_REQUEST:
      return { loading: true }
    case CREATE_CLASSROOM_SUCCESS:
      return { loading: false, success: true, classroom: action.payload }
    case CREATE_CLASSROOM_FAIL:
      return { loading: false, error: action.payload }
    case CREATE_CLASSROOM_RESET:
      return {}
    default:
      return state
  }
}

export const classroomDetailsReducer = (
  state = { loading: true, students: [] },
  action
) => {
  switch (action.type) {
    case FETCH_CLASSROOM_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_CLASSROOM_DETAILS_SUCCESS:
      return {
        loading: false,
        classroom: action.payload,
      }
    case FETCH_CLASSROOM_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case FETCH_CLASSROOM_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const classroomUpdateReducer = (state = { classroom: {} }, action) => {
  switch (action.type) {
    case CLASSROOM_UPDATE_REQUEST:
      return { loading: true }
    case CLASSROOM_UPDATE_SUCCESS:
      return { loading: false, success: true, classroom: action.payload }
    case CLASSROOM_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case CLASSROOM_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const classroomDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CLASSROOM_DELETE_REQUEST:
      return { loading: true }
    case CLASSROOM_DELETE_SUCCESS:
      return { loading: false, success: true }
    case CLASSROOM_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const classroomLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case CLASSROOM_LOGIN_REQUEST:
      return { loading: true }
    case CLASSROOM_LOGIN_SUCCESS:
      return { loading: false, classroomInfo: action.payload }
    case CLASSROOM_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case CLASSROOM_LOGIN_RESET:
      return {}
    default:
      return state
  }
}

export const classroomRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case CLASSROOM_REGISTER_REQUEST:
      return { loading: true }
    case CLASSROOM_REGISTER_SUCCESS:
      return { loading: false, classroom: action.payload }
    case CLASSROOM_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    case CLASSROOM_REGISTER_RESET:
      return {}
    default:
      return state
  }
}
