import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import classes from './LandingImage.module.css'

const LandingImage = (props) => {
  return (
    <div className={classes.container}>
      <div>
        <img
          className={classes.img}
          src={'/images/undraw_around_the_world_re_rb1p.svg'}
        />
      </div>
    </div>
  )
}

export default LandingImage
