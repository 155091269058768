import {
  FETCH_QUESTION_FAIL,
  FETCH_QUESTION_SUCCESS,
  FETCH_QUESTION_REQUEST,
  FILTER_QUESTIONS_REQUEST,
  FILTER_QUESTIONS_SUCCESS,
  FILTER_QUESTIONS_FAIL,
  GET_QUESTION_DETAILS_REQUEST,
  GET_QUESTION_DETAILS_SUCCESS,
  GET_QUESTION_DETAILS_FAIL,
  UPDATE_QUESTION_DETAILS_REQUEST,
  UPDATE_QUESTION_DETAILS_SUCCESS,
  UPDATE_QUESTION_DETAILS_FAIL,
  UPDATE_QUESTION_DETAILS_RESET,
  QUESTION_DELETE_REQUEST,
  QUESTION_DELETE_SUCCESS,
  QUESTION_DELETE_FAIL,
  QUESTION_CREATE_REQUEST,
  QUESTION_CREATE_SUCCESS,
  QUESTION_CREATE_FAIL,
  QUESTION_CREATE_RESET,
  FETCH_QUESTION_RESET,
  QUESTION_FLAG_REQUEST,
  QUESTION_FLAG_SUCCESS,
  QUESTION_FLAG_FAIL,
  QUESTION_UNFLAG_REQUEST,
  QUESTION_UNFLAG_SUCCESS,
  QUESTION_UNFLAG_FAIL,
} from "../constants/questionConstants"

export const fetchQuestionReducer = (state = { question: {} }, action) => {
  switch (action.type) {
    case FETCH_QUESTION_REQUEST:
      return { ...state, loading: true }
    case FETCH_QUESTION_SUCCESS:
      return {
        loading: false,
        question: action.payload,
      }
    case FETCH_QUESTION_FAIL:
      return { loading: false, error: action.payload }
    case FETCH_QUESTION_RESET:
      return {}
    default:
      return state
  }
}

export const filterQuestionsReducer = (state = { questions: [] }, action) => {
  switch (action.type) {
    case FILTER_QUESTIONS_REQUEST:
      return { loading: true }
    case FILTER_QUESTIONS_SUCCESS:
      return {
        loading: false,
        questions: action.payload.questions,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case FILTER_QUESTIONS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const questionDetailsReducer = (state = { question: {} }, action) => {
  switch (action.type) {
    case GET_QUESTION_DETAILS_REQUEST:
      return { ...state, loading: true }
    case GET_QUESTION_DETAILS_SUCCESS:
      return { loading: false, question: action.payload }
    case GET_QUESTION_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const questionUpdateReducer = (state = { question: {} }, action) => {
  switch (action.type) {
    case UPDATE_QUESTION_DETAILS_REQUEST:
      return { loading: true }
    case UPDATE_QUESTION_DETAILS_SUCCESS:
      return { loading: false, success: true, question: action.payload }
    case UPDATE_QUESTION_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_QUESTION_DETAILS_RESET:
      return { question: {} }
    default:
      return state
  }
}

export const questionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_DELETE_REQUEST:
      return { loading: true }
    case QUESTION_DELETE_SUCCESS:
      return { loading: false, success: true }
    case QUESTION_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const questionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_CREATE_REQUEST:
      return { loading: true }
    case QUESTION_CREATE_SUCCESS:
      return { loading: false, success: true, question: action.payload }
    case QUESTION_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case QUESTION_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const questionFlagReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_FLAG_REQUEST:
      return {
        loading: true,
      }
    case QUESTION_FLAG_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case QUESTION_FLAG_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const questionUnflagReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_UNFLAG_REQUEST:
      return {
        loading: true,
      }
    case QUESTION_UNFLAG_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case QUESTION_UNFLAG_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
