import React from 'react'
import { Card } from 'react-bootstrap'
import classes from './LanguageProfile.module.css'

export const QuestionCountBox = (props) => {
  return (
    <div>
      <Card className={classes.questionCountContainer}>
        <Card.Body>
          <h3 className={classes.levelLabel}>{props.message}</h3>
          <h1 className={classes.numLabel}>{props.data}</h1>
        </Card.Body>
      </Card>
    </div>
  )
}
