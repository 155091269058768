export const FETCH_QUESTION_REQUEST = "FETCH_QUESTION_REQUEST"
export const FETCH_QUESTION_SUCCESS = "FETCH_QUESTION_SUCCESS"
export const FETCH_QUESTION_FAIL = "FETCH_QUESTION_FAIL"
export const FETCH_QUESTION_RESET = "FETCH_QUESTION_RESET"

export const FILTER_QUESTIONS_REQUEST = "FILTER_QUESTIONS_REQUEST"
export const FILTER_QUESTIONS_SUCCESS = "FILTER_QUESTIONS_SUCCESS"
export const FILTER_QUESTIONS_FAIL = "FILTER_QUESTIONS_FAIL"

export const GET_QUESTION_DETAILS_REQUEST = "GET_QUESTION_DETAILS_REQUEST"
export const GET_QUESTION_DETAILS_SUCCESS = "GET_QUESTION_DETAILS_SUCCESS"
export const GET_QUESTION_DETAILS_FAIL = "GET_QUESTION_DETAILS_FAIL"

export const UPDATE_QUESTION_DETAILS_REQUEST = "UPDATE_QUESTION_DETAILS_REQUEST"
export const UPDATE_QUESTION_DETAILS_SUCCESS = "UPDATE_QUESTION_DETAILS_SUCCESS"
export const UPDATE_QUESTION_DETAILS_FAIL = "UPDATE_QUESTION_DETAILS_FAIL"
export const UPDATE_QUESTION_DETAILS_RESET = "UPDATE_QUESTION_DETAILS_RESET"

export const QUESTION_DELETE_REQUEST = "QUESTION_DELETE_REQUEST"
export const QUESTION_DELETE_SUCCESS = "QUESTION_DELETE_SUCCESS"
export const QUESTION_DELETE_FAIL = "QUESTION_DELETE_FAIL"

export const QUESTION_CREATE_REQUEST = "QUESTION_CREATE_REQUEST"
export const QUESTION_CREATE_SUCCESS = "QUESTION_CREATE_SUCCESS"
export const QUESTION_CREATE_FAIL = "QUESTION_CREATE_FAIL"
export const QUESTION_CREATE_RESET = "QUESTION_CREATE_RESET"

export const QUESTION_FLAG_REQUEST = "QUESTION_FLAG_REQUEST"
export const QUESTION_FLAG_SUCCESS = "QUESTION_FLAG_SUCCESS"
export const QUESTION_FLAG_FAIL = "QUESTION_FLAG_FAIL"

export const QUESTION_UNFLAG_REQUEST = "QUESTION_UNFLAG_REQUEST"
export const QUESTION_UNFLAG_SUCCESS = "QUESTION_UNFLAG_SUCCESS"
export const QUESTION_UNFLAG_FAIL = "QUESTION_UNFLAG_FAIL"
