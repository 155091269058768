import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { getQuestions } from '../actions/questionActions'
import { initializeModule } from '../actions/startActions'
import { QuestionMultipleChoice } from '../components/QuestionMultipleChoice'
import { QuestionFillBlank } from '../components/QuestionFillBlank'
import FlagModal from '../components/FlagModal'
import NotebookModal from '../components/NotebookModal'
import QuestionModuleDonut from '../components/QuizModuleDonut'
import { sum } from 'd3-array'
import { NOTEBOOK_CREATE_RESET } from '../constants/notebookConstants'
import { FLAG_CREATE_RESET } from '../constants/flagConstants'
import classes from './ModuleScreens.module.css'
import ScoreBar from '../components/ScoreBar'
import Loader from '../components/Loader'
import { QuestionConjugation } from '../components/QuestionConjugation'
import { updateUserLevel } from '../actions/userActions'
import LevelBar from '../components/LevelBar'
import { postDashboardData } from '../actions/dashboardActions'
import { createNotebook } from '../actions/notebookActions'
import Message from '../components/Message'
import LevelModal from '../components/LevelModal'
import { LinkContainer } from 'react-router-bootstrap'
import ScoreBarMobile from '../components/ScoreBarMobile'
import { QuestionMultipleChoiceMobile } from '../components/QuestionMultipleChoiceMobile'
import { QuestionMultipleChoiceDzongkha } from '../components/QuestionMultipleChoiceDzongkha'

const languageLevelDictionary = {
  portuguese: 10,
  french: 10,
  dutch: 6,
  dzongkha: 10,
  german: 10,
  spanish: 10,
  english: 10,
  sango: 10,
}

const knowledgeDictionary = {
  translation: 'translation',
  vocabulary: 'vocabulary',
  synonyms: 'synonyms and antonyms',
  conjugation: 'conjugation',
  particles: 'particles',
}

const QuestionModuleScreen = () => {
  const dispatch = useDispatch()
  let history = useNavigate()
  const correctColor = '#2fb986'
  const incorrectColor = '#ff8d7a'
  // Redux States //
  const questionFetch = useSelector((state) => state.questionFetch)
  const {
    loading: loadingQuestion,
    error,
    question: questionData,
  } = questionFetch

  const initialize = useSelector(
    (state) => state.initialize.initializeParameters
  )
  const { userLanguage, targetLanguage, level } = initialize

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateLevel = useSelector((state) => state.userUpdateLevel)
  const { success } = userUpdateLevel

  // Const useStates //
  const [levelChangePermit, setLevelChangePermit] = useState(false)
  const [firstQuestionLoad, setFirstQuestionLoad] = useState(true)
  const [scoreList, setScoreList] = useState([])
  const [skip, setSkip] = useState(true)
  const [attemptCount, setAttemptCount] = useState(0)
  const [levelLock, setLevelLock] = useState(false)
  const [shuffledAnswerList, setShuffledAnswerList] = useState([])
  const [totalScore, setTotalScore] = useState(null)
  const [correctAnswer, setCorrectAnswer] = useState('')
  const [maxLevel, setMaxLevel] = useState(1)
  const [showFlagModal, setShowFlagModal] = useState(false)
  const [showLevelModal, setShowLevelModal] = useState(false)
  const [showNotebookModal, setShowNotebookModal] = useState(false)
  const [message, setMessage] = useState('')
  // const [buttonTextSize, setButtonTextSize] = useState('1rem')
  const [showMessage, setShowMessage] = useState(false)
  const [knowledgeType, setKnowledgeType] = useState('')
  const [showDonut, setShowDonut] = useState(true)
  const [currentLevel, setCurrentLevel] = useState(1)
  const [numLanguageLevel, setNumLanguageLevel] = useState(1)
  const [isMobile, setIsMobile] = useState(false)
  // Modal States //
  const handleFlagModalClose = () => setShowFlagModal(false)
  const handleLevelModalClose = () => setShowLevelModal(false)

  const handleFlagModalShow = () => setShowFlagModal(true)
  const handleNotebookModalClose = () => {
    setShowNotebookModal(false)
    dispatch({ type: NOTEBOOK_CREATE_RESET })
  }
  const handleNotebookModalShow = () => setShowNotebookModal(true)

  // API Filter //
  const api_filter_info = {
    level: currentLevel,
    targetLanguage: targetLanguage,
  }

  // Donut Data
  const data = [
    { date: 'incorrect', value: 100 - totalScore },
    { date: 'correct', value: totalScore },
  ]

  // Function Shuffle Answers
  const shuffle = (arr) => arr.sort(() => Math.random() - 0.5)

  // useEffects //
  useEffect(() => {
    setCurrentLevel(level)
  }, [level])

  useEffect(() => {
    window.screen.width <= 760 ? setIsMobile(true) : setIsMobile(false)
  }, [window.screen.width])

  function detectWindowSize() {
    window.innerWidth <= 760 ? setIsMobile(true) : setIsMobile(false)
  }

  window.onresize = detectWindowSize

  // useEffect(() => {
  //   console.log(questionData._id)
  // }, [questionData])

  //Initialize first question
  useEffect(() => {
    // if (!userInfo) {
    //   document.getElementById('flagButton').disabled = true
    //   document.getElementById('notebookButton').disabled = true
    // }
    if (firstQuestionLoad) {
      document.getElementById('overDiv').focus()
      dispatch(
        getQuestions({
          level: level,
          targetLanguage: targetLanguage,
        })
      )
      setFirstQuestionLoad(false)
      setNumLanguageLevel(languageLevelDictionary[targetLanguage])
    }
    if (maxLevel < level) {
      setMaxLevel(level)
    }
    setShowMessage(false)
  }, [initialize])

  // Gets user's stored level and updates
  useEffect(() => {
    if (!userInfo) {
      console.log('No user info')
    } else {
      if (userInfo.userLevels[targetLanguage]) {
        // console.log(currentLevel)
        // console.log(userInfo.userLevels[targetLanguage])
        // console.log(
        //   Number(userInfo.userLevels[targetLanguage]) < Number(currentLevel)
        // )
        if (userInfo.userLevels[targetLanguage] < currentLevel) {
          dispatch(
            updateUserLevel({
              targetLanguage: targetLanguage,
              level: currentLevel,
            })
          )
        }
      } else {
        dispatch(
          updateUserLevel({
            targetLanguage: targetLanguage,
            level: currentLevel,
          })
        )
      }
    }
  }, [dispatch, history, currentLevel])

  useEffect(() => {
    if (scoreList.length > 0) {
      setTotalScore(100 * (sum(scoreList.slice(-5)) / 5))
    }
  }, [scoreList])

  // Deliver Dashboard Data
  useEffect(() => {
    if (userInfo && scoreList.length > 0) {
      let answerData = {
        answer: correctAnswer,
        question: questionData._id,
        score:
          scoreList.slice(-1)[0] === 1
            ? 'correct'
            : scoreList.slice(-1)[0] > 0
            ? 'partial'
            : 'incorrect',
        pointscore: scoreList.slice(-1)[0],
      }
      dispatch(postDashboardData(answerData))
    }
  }, [scoreList])

  // Shuffled Answers
  useEffect(() => {
    setMessage('')
    if (questionData.answers) {
      setShuffledAnswerList(shuffle([...questionData.answers]))
      setKnowledgeType(questionData.knowledgeType)
    }
    document.getElementById('nextButton').disabled = true
    setShowMessage(false)
  }, [questionData])

  // Correct Answers
  useEffect(() => {
    if (shuffledAnswerList.length > 0) {
      setCorrectAnswer(
        shuffledAnswerList.filter(
          ({ isCorrect, text }) => isCorrect === true
        )[0]['text']
      )
    }
  }, [shuffledAnswerList])

  // Level Management
  useEffect(() => {
    if (
      scoreList.length > 0 &&
      currentLevel < 10 &&
      scoreList.slice(-1)[0] === 1 &&
      scoreList.slice(-5).reduce((accumulator, curr) => accumulator + curr) ===
        5 &&
      levelLock !== true
    ) {
      dispatch(
        initializeModule(userLanguage, targetLanguage, Number(currentLevel) + 1)
      )
      setShowLevelModal(true)
      setScoreList([])
    }

    if (
      scoreList.length > 0 &&
      currentLevel !== 1 &&
      scoreList.slice(-2).reduce((accumulator, curr) => accumulator + curr) ===
        0 &&
      scoreList.slice(-5).reduce((accumulator, curr) => accumulator + curr) <=
        1 &&
      levelLock !== true
    ) {
      dispatch(
        initializeModule(userLanguage, targetLanguage, Number(currentLevel))
      )
    }
  }, [scoreList])

  useEffect(() => {
    if (questionData.answers) {
      for (let i = 1; i <= questionData.answers.length; i++) {
        if (document.getElementById(i) !== null) {
          document.getElementById(i).disabled = false
          document.getElementById(i).style.backgroundColor = '#4C4A47'
          // document.getElementById(i).style.fontSize = buttonTextSize
        }
      }
    }
  }, [questionData.answers])

  // Handle Hot Keys
  const handleKeyPress = (event) => {
    if (
      showLevelModal === false &&
      event.key === 'Enter' &&
      document.getElementById('nextButton').disabled === false
    ) {
      document.getElementById('nextButton').click()
    } else {
      if (
        document.getElementById(event.key) !== null &&
        showLevelModal === false
      ) {
        if (document.getElementById(event.key).value) {
          document.getElementById(event.key).click()
          // console.log('Click info', document.getElementById(event.key).value)
        } else {
          document.getElementById(event.key).click()
          // console.log('Click info', document.getElementById(event.key).value)
        }
      }
    }
  }

  // Answer Button Handlers
  const answerButtonHandler = (event) => {
    if (event.target.value === correctAnswer) {
      setScoreList((scoreList) => [...scoreList, 1].slice(-10))
      document.getElementById(event.target.id).style.backgroundColor =
        correctColor
      document.getElementById('overDiv').focus()
      for (let i = 1; i <= questionData.answers.length; i++) {
        if (document.getElementById(i) !== null) {
          document.getElementById(i).disabled = true
        }
      }
      document.getElementById('nextButton').disabled = false
      document.getElementById('skipButton').disabled = true
      if (document.getElementById('giveUpButton') != null) {
        document.getElementById('giveUpButton').disabled = true
      }
    } else {
      document.getElementById(event.target.id).style.backgroundColor =
        incorrectColor
      document.getElementById(event.target.id).disabled = true
      setScoreList((scoreList) => [...scoreList, 0].slice(-10))
    }
  }

  // Skip Button Handler
  const skipButtonHandler = (event) => {
    setScoreList((scoreList) => [...scoreList, 0].slice(-10))

    if (document.getElementById('mainForm')) {
      document.getElementById('mainForm').focus()
    }
    dispatch(getQuestions(api_filter_info))
  }

  // Next Button Handler
  const nextButtonHandler = (event) => {
    if (document.getElementById('mainForm')) {
      document.getElementById('mainForm').focus()
    }
    dispatch(getQuestions(api_filter_info))
    document.getElementById('nextButton').disabled = true
    document.getElementById('skipButton').disabled = false
    document.getElementById('giveUpButton').disabled = false
    setShowMessage(false)
    setShowNotebookModal(false)
    setMessage('')
  }

  const exitButtonHandler = (event) => {
    history('/')
    setShowMessage(false)
    setShowNotebookModal(false)
    setMessage('')
  }

  const flagButtonHandler = () => {
    dispatch({ type: FLAG_CREATE_RESET })
    setShowFlagModal(true)
  }

  /// Notebook Create
  const notebookCreate = useSelector((state) => state.notebookCreate)
  const {
    loading: loadingNotebookCreate,
    error: errorNotebookCreate,
    success: successNotebookCreate,
    notebook,
  } = notebookCreate

  useEffect(() => {
    if (successNotebookCreate & (firstQuestionLoad != true)) {
      setMessage('Successfully added to notebook!')
      setShowMessage(true)
    }
    setTimeout(() => {
      setShowMessage(false)
    }, 2000)
  }, [successNotebookCreate, notebook])

  const notebookButtonHandler = (e) => {
    dispatch({ type: NOTEBOOK_CREATE_RESET })
    e.preventDefault()
    dispatch(
      createNotebook({
        question: questionData.question,
        answers: questionData.answers,
        targetLanguage: questionData.targetLanguage,
        level: questionData.level,
        questionId: questionData._id,
        questionType: questionData.questionType,
        isFlagged: questionData.isFlagged,
        textType: questionData.textType,
        citation: questionData.citation,
        source: 'Lebe Language',
      })
    )
  }

  const otherButtons = (
    <Row>
      <Col>
        <button className={classes.exitButton} onClick={exitButtonHandler}>
          Exit
        </button>
        <button
          id={'skipButton'}
          className={classes.skipButton}
          onClick={skipButtonHandler}
        >
          Skip
        </button>
        <button
          className={classes.nextButton}
          id={'nextButton'}
          onClick={nextButtonHandler}
        >
          Next
        </button>
      </Col>
    </Row>
  )

  const questionBox = (
    <div>
      <div>
        <Row>
          <Col>
            {questionData.questionType === 'multiple_choice' ? (
              isMobile ? (
                <QuestionMultipleChoiceMobile
                  questionData={questionData}
                  correctAnswer={correctAnswer}
                  loadingQuestion={loadingQuestion}
                  // buttonTextSize={buttonTextSize}
                  answerButtonHandler={answerButtonHandler}
                  shuffledAnswerList={shuffledAnswerList}
                  setScoreList={setScoreList}
                  correctColor={correctColor}
                />
              ) : targetLanguage == 'dzongkha' ? (
                <QuestionMultipleChoiceDzongkha
                  questionData={questionData}
                  correctAnswer={correctAnswer}
                  loadingQuestion={loadingQuestion}
                  // buttonTextSize={buttonTextSize}
                  answerButtonHandler={answerButtonHandler}
                  shuffledAnswerList={shuffledAnswerList}
                  setScoreList={setScoreList}
                  correctColor={correctColor}
                />
              ) : (
                <QuestionMultipleChoice
                  questionData={questionData}
                  correctAnswer={correctAnswer}
                  loadingQuestion={loadingQuestion}
                  // buttonTextSize={buttonTextSize}
                  answerButtonHandler={answerButtonHandler}
                  shuffledAnswerList={shuffledAnswerList}
                  setScoreList={setScoreList}
                  correctColor={correctColor}
                />
              )
            ) : questionData.questionType === 'fill_in_the_blank' ? (
              <QuestionFillBlank
                questionData={questionData}
                loadingQuestion={loadingQuestion}
                targetLanguage={targetLanguage}
                setScoreList={setScoreList}
                isMobile={isMobile}
              />
            ) : questionData.questionType === 'conjugation' ? (
              <QuestionConjugation
                questionData={questionData}
                loadingQuestion={loadingQuestion}
                targetLanguage={targetLanguage}
                setScoreList={setScoreList}
                isMobile={isMobile}
              />
            ) : (
              <Loader />
            )}
          </Col>
        </Row>
      </div>
    </div>
  )

  return (
    <div className={classes.container}>
      <div className={classes.loginContainer}>
        {!userInfo ? (
          <>
            <LinkContainer to='/login'>
              <h3 className={classes.loginText}>
                Login or sign up to save your progress!
              </h3>
            </LinkContainer>
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        id={'overDiv'}
        className={isMobile ? classes.divMobile : classes.div}
        onKeyDown={handleKeyPress}
        tabIndex={'0'}
      >
        <LevelBar
          userLanguage={userLanguage}
          targetLanguage={targetLanguage}
          maxLevel={maxLevel}
          api_filter_info={api_filter_info}
          scoreList={scoreList}
          numLanguageLevels={numLanguageLevel}
          setScoreList={setScoreList}
          setCurrentLevel={setCurrentLevel}
          setLevelLock={setLevelLock}
          setAttemptCount={setAttemptCount}
          currentLevel={currentLevel}
        />
        <div>
          <Row>
            <Col>
              {isMobile ? (
                <ScoreBarMobile scoreList={scoreList} />
              ) : (
                <ScoreBar scoreList={scoreList} />
              )}
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <div>
              <h3 className={classes.languageLabel}>
                {targetLanguage.toUpperCase()}
              </h3>
              <h2 className={classes.levelLabel2}>
                Level {questionData.level}
              </h2>
            </div>
            <h2 className={classes.instructions}>
              {knowledgeDictionary[knowledgeType]}
            </h2>
            <div className={classes.divider}></div>
          </Col>
        </Row>

        <Row>
          <Col>{questionBox}</Col>
        </Row>
        <Row>
          <Col>
            <FlagModal
              showFlagModal={showFlagModal}
              questionData={questionData}
              handleFlagModalClose={handleFlagModalClose}
            />
            <NotebookModal
              showNotebookModal={showNotebookModal}
              questionData={questionData}
              handleNotebookModalClose={handleNotebookModalClose}
            />
            <LevelModal
              showLevelModal={showLevelModal}
              questionData={questionData}
              link={'/'}
              handleLevelModalClose={handleLevelModalClose}
            />
          </Col>
        </Row>

        <div className={classes.otherButtonsContainer}>
          {otherButtons}

          {userInfo && (
            <div classes={classes.modalButtonsContainer}>
              <button
                id={'flagButton'}
                className={classes.flagButton}
                onClick={flagButtonHandler}
              >
                <i className='fas fa-flag'></i> Flag
              </button>
              <button
                id={'notebookButton'}
                className={classes.flagButton}
                onClick={notebookButtonHandler}
              >
                <i className='fas fa-plus'></i> Add to Notebook
              </button>
            </div>
          )}
        </div>

        {/* <div className={classes.otherButtonsContainer}>{otherButtons}</div>
        {userInfo && (
          <div classes={classes.modalButtonsContainer}>
            <button
              id={'flagButton'}
              className={classes.flagButton}
              onClick={flagButtonHandler}
            >
              <i className='fas fa-flag'></i> Flag
            </button>
            <button
              id={'notebookButton'}
              className={classes.flagButton}
              onClick={notebookButtonHandler}
            >
              <i className='fas fa-plus'></i> Add to Notebook
            </button>
          </div>
        )} */}
        <div className={classes.donutdiv}>
          {isMobile == false && (
            <QuestionModuleDonut
              data={data}
              width={40}
              height={40}
              innerRadius={10}
              outerRadius={20}
            />
          )}
        </div>
        {successNotebookCreate ? (
          showMessage && <Message variant='success'>{message}</Message>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default QuestionModuleScreen
