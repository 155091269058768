import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import SearchBox from './SearchBox'
import { logout } from '../actions/userActions'
import classes from './Header.module.css'

const Header = () => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const logoutHandler = () => {
    dispatch(logout())
  }

  useEffect(() => {
    if (document.getElementById('nav-dropdown-user')) {
      document.getElementById('nav-dropdown-user').style.color = '#f8eee4'
      document.getElementById('nav-dropdown-user').style.fontFamily = 'Georgia'
      document.getElementById('nav-dropdown-teacher').style.color = '#f8eee4'
      document.getElementById('nav-dropdown-teacher').style.fontFamily =
        'Georgia'
    }
  }, [userInfo])

  return (
    <header className={classes.header}>
      <Navbar className={classes.nav} expand='lg' collapseOnSelect>
        <Container>
          <LinkContainer to='/'>
            <div>
              <img className={classes.img} src={'/images/endlessknot.png'} />
            </div>
          </LinkContainer>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <div className={classes.titleDiv}>
                <h2 className={classes.h2}>LËBE</h2>
                <h2 className={classes.h2}>LANGUAGE</h2>
                <a className={classes.beta}>beta</a>
              </div>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ml-auto'>
              <LinkContainer to='/about'>
                <Nav.Link className={classes.h3}>About</Nav.Link>
              </LinkContainer>
              {!userInfo && (
                <LinkContainer to='/dashboardsample'>
                  <Nav.Link className={classes.h3}>Dashboard</Nav.Link>
                </LinkContainer>
              )}
              {userInfo && (
                <>
                  <LinkContainer to='/notebook'>
                    <Nav.Link className={classes.h3}>Notebook</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to='/dashboard'>
                    <Nav.Link className={classes.h3}>Dashboard</Nav.Link>
                  </LinkContainer>
                </>
              )}
              {userInfo && (
                <NavDropdown title='Teacher Tools' id='nav-dropdown-teacher'>
                  <LinkContainer to='/myclassrooms'>
                    <NavDropdown.Item>Teacher Classrooms</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/myquestions'>
                    <NavDropdown.Item>My Questions</NavDropdown.Item>
                  </LinkContainer>
                  {/* <LinkContainer to='/teacherdashboard'>
                    <NavDropdown.Item>Teacher Dashboards</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/quizgenerator'>
                    <NavDropdown.Item>Quiz Generator</NavDropdown.Item>
                  </LinkContainer> */}
                </NavDropdown>
              )}
              {userInfo ? (
                <NavDropdown title={userInfo.name} id='nav-dropdown-user'>
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/studentclassrooms'>
                    <NavDropdown.Item>Classrooms</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    <strong>Logout</strong>
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link>
                    <i className='fas fa-user'></i> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown
                  className={classes.h3}
                  title='Admin'
                  id='adminmenu'
                >
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/questionfilter'>
                    <NavDropdown.Item>Questions</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/teamlist'>
                    <NavDropdown.Item>Team</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/flags'>
                    <NavDropdown.Item>Flags</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
