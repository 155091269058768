import {
  FLAG_CREATE_FAIL,
  FLAG_CREATE_REQUEST,
  FLAG_CREATE_RESET,
  FLAG_CREATE_SUCCESS,
  FLAG_FILTER_FAIL,
  FLAG_FILTER_REQUEST,
  FLAG_FILTER_SUCCESS,
  FLAG_LIST_ALL_FAIL,
  FLAG_LIST_ALL_REQUEST,
  FLAG_LIST_ALL_SUCCESS,
} from '../constants/flagConstants'

export const flagCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case FLAG_CREATE_REQUEST:
      return {
        loading: true,
      }
    case FLAG_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        flag: action.payload,
      }
    case FLAG_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case FLAG_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const flagFilterReducer = (state = { flags: [] }, action) => {
  switch (action.type) {
    case FLAG_FILTER_REQUEST:
      return { loading: true }
    case FLAG_FILTER_SUCCESS:
      return {
        loading: false,
        flags: action.payload.flags,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case FLAG_FILTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const flagListReducer = (state = { flags: [] }, action) => {
  switch (action.type) {
    case FLAG_LIST_ALL_REQUEST:
      return {
        loading: true,
      }
    case FLAG_LIST_ALL_SUCCESS:
      return {
        loading: false,
        flags: action.payload,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case FLAG_LIST_ALL_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
