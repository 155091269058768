export const FOLLOWER_CREATE_REQUEST = "FOLLOWER_CREATE_REQUEST"
export const FOLLOWER_CREATE_SUCCESS = "FOLLOWER_CREATE_SUCCESS"
export const FOLLOWER_CREATE_FAIL = "FOLLOWER_CREATE_FAIL"
export const FOLLOWER_CREATE_RESET = "FOLLOWER_CREATE_RESET"

export const FOLLOWER_GET_CLASSROOMS_REQUEST = "FOLLOWER_GET_CLASSROOMS_REQUEST"
export const FOLLOWER_GET_CLASSROOMS_SUCCESS = "FOLLOWER_GET_CLASSROOMS_SUCCESS"
export const FOLLOWER_GET_CLASSROOMS_FAIL = "FOLLOWER_GET_CLASSROOMS_FAIL"

export const FOLLOWER_GET_STUDENTS_REQUEST = "FOLLOWER_GET_STUDENTS_REQUEST"
export const FOLLOWER_GET_STUDENTS_SUCCESS = "FOLLOWER_GET_STUDENTS_SUCCESS"
export const FOLLOWER_GET_STUDENTS_FAIL = "FOLLOWER_GET_STUDENTS_FAIL"

export const FOLLOWER_DELETE_REQUEST = "FOLLOWER_DELETE_REQUEST"
export const FOLLOWER_DELETE_SUCCESS = "FOLLOWER_DELETE_SUCCESS"
export const FOLLOWER_DELETE_FAIL = "FOLLOWER_DELETE_FAIL"
