import React, { useEffect, useState } from 'react'
import classes from './ScoreBarMobile.module.css'

const ScoreBarMobile = (props) => {
  const [scoreBoxes, setScoreBoxes] = useState([])
  const [randomKey, setRandomKey] = useState([])

  useEffect(() => {
    if (props.scoreList.length < 1) {
      setScoreBoxes(Array.from('x'.repeat(5)))
    } else if (props.scoreList.length < 5) {
      setScoreBoxes(
        Array.from('x'.repeat(5 - props.scoreList.length)).concat(
          props.scoreList
        )
      )
    } else {
      setScoreBoxes(props.scoreList.slice(-5))
    }
    setRandomKey(String(Math.random()))
  }, [props.scoreList])

  return (
    <div className={classes.scoreContainer}>
      {scoreBoxes.map((score, index) =>
        score == 1 && index === 4 ? (
          <div
            className={classes.scoreBoxCorrectBounce}
            href='#'
            key={randomKey + `${index}`}
          ></div>
        ) : score == 1 ? (
          <div
            className={classes.scoreBoxCorrect}
            href='#'
            key={randomKey + `${index}`}
          ></div>
        ) : score == 0 && index === 4 ? (
          <div
            className={classes.scoreBoxIncorrectBounce}
            href='#'
            key={randomKey + `${index}`}
          ></div>
        ) : score == 0 ? (
          <div
            className={classes.scoreBoxIncorrect}
            href='#'
            key={randomKey + `${index}`}
          ></div>
        ) : score < 1 && score > 0 ? (
          <div
            className={classes.scoreBoxPartial}
            href='#'
            key={randomKey + `${index}`}
          ></div>
        ) : (
          <div
            className={classes.scoreBoxNeutral}
            href='#'
            key={randomKey + `${index}`}
          ></div>
        )
      )}
    </div>
  )
}

export default ScoreBarMobile
