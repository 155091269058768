import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import Loader from './Loader'
import classes from './QuestionFillBlank.module.css'

const accentDictionary = {
  french: [
    'é',
    'à',
    'è',
    'ì',
    'ò',
    'ù',
    'ë',
    'ï',
    'ü',
    'â',
    'ê',
    'î',
    'ô',
    'û',
    'ç',
  ],
  spanish: ['á', 'é', 'í', 'ó', 'ú', 'ü', 'ñ'],
  portuguese: ['á', 'é', 'í', 'ó', 'ú', 'â', 'ê', 'ô', 'ã', 'õ', 'à', 'ò', 'ç'],
  german: ['ä', 'ö', 'ü', 'Ä', 'Ö', 'Ü', 'ẞ', 'ß'],
}
export const QuestionConjugation = (props) => {
  const typeInput = useRef(null)
  const [userInput, setUserInput] = useState('')
  const [typingAccuracy, setTypingAccuracy] = useState(1)

  const [randomIndex, setRandomIndex] = useState(0)
  const [answer, setAnswer] = useState('')
  const [answerList, setAnswerList] = useState([])
  const [inputText, setInputText] = useState('')
  const [answerChanger, setAnswerChanger] = useState(true)

  const [showAccentButtons, setShowAccentButtons] = useState(false)

  useEffect(() => {
    if (
      props.targetLanguage == 'portuguese' ||
      props.targetLanguage == 'french' ||
      props.targetLanguage == 'spanish' ||
      props.targetLanguage == 'german'
    ) {
      setShowAccentButtons(true)
    }
  }, [props.targetLanguage])
  useEffect(() => {
    setUserInput('')
    setInputText('')
    setRandomIndex(
      Math.floor(Math.random() * props.questionData.answers.length)
    )
    setAnswerList(props.questionData.answers)
    setTypingAccuracy(1)
    setAnswerChanger(!answerChanger)
  }, [props.questionData.answers])

  useEffect(() => {
    setAnswer(props.questionData.answers[randomIndex].text)
  }, [answerChanger])

  useEffect(() => {
    if (document.getElementById('mainInput')) {
      document.getElementById('mainInput').value = ''
      document.getElementById('mainInput').style.backgroundColor = '#ccffcc'
      document.getElementById('mainInput').disabled = false
      document.getElementById('giveUpButton').disabled = false
      document.getElementById('mainInput').focus()
    }
  }, [answerList])

  useEffect(() => {
    setInputText(userInput)
    if (userInput.length) {
      const matchingSoFar = new RegExp('\b' + answer)
      const perfectMatch = new RegExp('^' + answer + '$')
      const userRegex =
        userInput.includes('*') ||
        userInput.includes('+') ||
        userInput.includes('[') ||
        userInput.includes('\\')
          ? new RegExp('\br983h4f934nfu9')
          : new RegExp('\b' + userInput)
      if (answer.includes('+') || answer.includes('*')) {
        document.getElementById('mainInput').style.backgroundColor = '#ff9999'
        setTypingAccuracy(typingAccuracy - 0.1)
      }
      if (perfectMatch.test(userInput)) {
        props.setScoreList((scoreList) => [...scoreList, 1].slice(-5))
        document.getElementById('mainInput').style.backgroundColor = '#00cc00'
        document.getElementById('mainInput').disabled = true
        document.getElementById('nextButton').disabled = false
        document.getElementById('overDiv').focus()
      } else if (userRegex.test(matchingSoFar)) {
        document.getElementById('mainInput').style.backgroundColor = '#ccffcc'
      } else {
        document.getElementById('mainInput').style.backgroundColor = '#ff9999'
        if (typingAccuracy > 0) {
          setTypingAccuracy(typingAccuracy - 0.1)
        } else {
          setTypingAccuracy(0)
        }
      }
    }
  }, [userInput])

  const handleInput = (event) => {
    if (
      event.key == 'Enter' &&
      document.getElementById('mainInput').disabled === true
    ) {
      document.getElementById('nextButton').click()
    } else {
      setUserInput(typeInput.current.value)
    }
  }

  const giveUpHandler = (event) => {
    props.setScoreList((scoreList) => [...scoreList, 0].slice(-5))
    document.getElementById('mainInput').value = answer
    document.getElementById('giveUpButton').disabled = true
    document.getElementById('nextButton').disabled = false
    setInputText(answer)
  }
  const accentButtonHandler = (event) => {
    typeInput.current.value = typeInput.current.value + event.target.value
    setUserInput(typeInput.current.value)
    document.getElementById('mainInput').focus()
  }

  const CONJ = (
    <div
      className={
        props.isMobile
          ? classes.questionContainerMobile
          : classes.questionContainer
      }
    >
      <Card className={classes.tableCard}>
        <Table bordered hover responsive variant='dark' className='table-sm'>
          <tbody>
            {answerList.map((item, index) =>
              index !== randomIndex ? (
                <tr key={index}>
                  <td>{item.fragment}</td>
                  <td>{item.text}</td>
                </tr>
              ) : (
                <tr key={index}>
                  <td>{item.fragment}</td>
                  <td>{inputText}</td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </Card>
      <form
        id={'mainForm'}
        autoFocus={true}
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <label>
          <input
            ref={typeInput}
            id={'mainInput'}
            className={classes.input}
            type='text'
            onKeyUp={handleInput}
          />
        </label>
      </form>
      <div>
        <Row>
          <Col>
            {showAccentButtons &&
              accentDictionary[props.targetLanguage].map((item, index) => (
                <button
                  className={classes.accentButton}
                  value={item}
                  onClick={accentButtonHandler}
                >
                  {item}
                </button>
              ))}
          </Col>
        </Row>
      </div>
      <Button
        id={'giveUpButton'}
        className={classes.giveUpButton}
        onClick={giveUpHandler}
      >
        {'show answer'}
      </Button>
    </div>
  )
  return (
    <>
      {props.loadingQuestion ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        CONJ
      )}
    </>
  )
}
