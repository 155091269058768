export const MY_QUESTIONS_FETCH_ONE_REQUEST = 'MY_QUESTIONS_FETCH_ONE_REQUEST'
export const MY_QUESTIONS_FETCH_ONE_SUCCESS = 'MY_QUESTIONS_FETCH_ONE_SUCCESS'
export const MY_QUESTIONS_FETCH_ONE_FAIL = 'MY_QUESTIONS_FETCH_ONE_FAIL'
export const MY_QUESTIONS_FETCH_ONE_RESET = 'MY_QUESTIONS_FETCH_ONE_RESET'

export const MY_QUESTIONS_FILTER_REQUEST = 'MY_QUESTIONS_FILTER_REQUEST'
export const MY_QUESTIONS_FILTER_SUCCESS = 'MY_QUESTIONS_FILTER_SUCCESS'
export const MY_QUESTIONS_FILTER_FAIL = 'MY_QUESTIONS_FILTER_FAIL'

export const MY_QUESTIONS_DETAILS_REQUEST = 'MY_QUESTIONS_DETAILS_REQUEST'
export const MY_QUESTIONS_DETAILS_SUCCESS = 'MY_QUESTIONS_DETAILS_SUCCESS'
export const MY_QUESTIONS_DETAILS_FAIL = 'MY_QUESTIONS_DETAILS_FAIL'
export const MY_QUESTIONS_DETAILS_RESET = 'MY_QUESTIONS_DETAILS_RESET'

export const MY_QUESTION_DELETE_REQUEST = 'MY_QUESTION_DELETE_REQUEST'
export const MY_QUESTION_DELETE_SUCCESS = 'MY_QUESTION_DELETE_SUCCESS'
export const MY_QUESTION_DELETE_FAIL = 'MY_QUESTION_DELETE_FAIL'

export const MY_QUESTION_DELETE_BULK_REQUEST = 'MY_QUESTION_DELETE_BULK_REQUEST'
export const MY_QUESTION_DELETE_BULK_SUCCESS = 'MY_QUESTION_DELETE_BULK_SUCCESS'
export const MY_QUESTION_DELETE_BULK_FAIL = 'MY_QUESTION_DELETE_BULK_FAIL'

export const MY_QUESTION_LOWEST_LEVEL_REQUEST =
  'MY_QUESTION_LOWEST_LEVEL_REQUEST'
export const MY_QUESTION_LOWEST_LEVEL_SUCCESS =
  'MY_QUESTION_LOWEST_LEVEL_SUCCESS'
export const MY_QUESTION_LOWEST_LEVEL_FAIL = 'MY_QUESTION_LOWEST_LEVEL_FAIL'
export const MY_QUESTION_LOWEST_LEVEL_RESET = 'MY_QUESTION_LOWEST_LEVEL_RESET'

export const MY_QUESTION_LEVELS_LIST_REQUEST = 'MY_QUESTION_LEVELS_LIST_REQUEST'
export const MY_QUESTION_LEVELS_LIST_SUCCESS = 'MY_QUESTION_LEVELS_LIST_SUCCESS'
export const MY_QUESTION_LEVELS_LIST_FAIL = 'MY_QUESTION_LEVELS_LIST_FAIL'
export const MY_QUESTION_LEVELS_LIST_RESET = 'MY_QUESTION_LEVELS_LIST_RESET'

export const MY_QUESTION_CREATE_REQUEST = 'MY_QUESTION_CREATE_REQUEST'
export const MY_QUESTION_CREATE_SUCCESS = 'MY_QUESTION_CREATE_SUCCESS'
export const MY_QUESTION_CREATE_FAIL = 'MY_QUESTION_CREATE_FAIL'
export const MY_QUESTION_CREATE_RESET = 'MY_QUESTION_CREATE_RESET'

export const MY_QUESTION_UPDATE_REQUEST = 'MY_QUESTION_UPDATE_REQUEST'
export const MY_QUESTION_UPDATE_SUCCESS = 'MY_QUESTION_UPDATE_SUCCESS'
export const MY_QUESTION_UPDATE_FAIL = 'MY_QUESTION_UPDATE_FAIL'
export const MY_QUESTION_UPDATE_RESET = 'MY_QUESTION_UPDATE_RESET'

export const MY_QUESTION_ADD_CLASSROOM_REQUEST =
  'MY_QUESTION_ADD_CLASSROOM_REQUEST'
export const MY_QUESTION_ADD_CLASSROOM_SUCCESS =
  'MY_QUESTION_ADD_CLASSROOM_SUCCESS'
export const MY_QUESTION_ADD_CLASSROOM_FAIL = 'MY_QUESTION_ADD_CLASSROOM_FAIL'
export const MY_QUESTION_ADD_CLASSROOM_RESET = 'MY_QUESTION_ADD_CLASSROOM_RESET'

export const MY_QUESTION_REMOVE_CLASSROOM_REQUEST =
  'MY_QUESTION_REMOVE_CLASSROOM_REQUEST'
export const MY_QUESTION_REMOVE_CLASSROOM_SUCCESS =
  'MY_QUESTION_REMOVE_CLASSROOM_SUCCESS'
export const MY_QUESTION_REMOVE_CLASSROOM_FAIL =
  'MY_QUESTION_REMOVE_CLASSROOM_FAIL'
export const MY_QUESTION_REMOVE_CLASSROOM_RESET =
  'MY_QUESTION_REMOVE_CLASSROOM_RESET'

export const MY_QUESTION_GET_BY_CLASSROOM_REQUEST =
  'MY_QUESTION_GET_BY_CLASSROOM_REQUEST'
export const MY_QUESTION_GET_BY_CLASSROOM_SUCCESS =
  'MY_QUESTION_GET_BY_CLASSROOM_SUCCESS'
export const MY_QUESTION_GET_BY_CLASSROOM_FAIL =
  'MY_QUESTION_GET_BY_CLASSROOM_FAIL'
export const MY_QUESTION_GET_BY_CLASSROOM_RESET =
  'MY_QUESTION_GET_BY_CLASSROOM_RESET'

export const MY_QUESTION_FLAG_REQUEST = 'MY_QUESTION_FLAG_REQUEST'
export const MY_QUESTION_FLAG_SUCCESS = 'MY_QUESTION_FLAG_SUCCESS'
export const MY_QUESTION_FLAG_FAIL = 'MY_QUESTION_FLAG_FAIL'

export const MY_QUESTION_UNFLAG_REQUEST = 'MY_QUESTION_UNFLAG_REQUEST'
export const MY_QUESTION_UNFLAG_SUCCESS = 'MY_QUESTION_UNFLAG_SUCCESS'
export const MY_QUESTION_UNFLAG_FAIL = 'MY_QUESTION_UNFLAG_FAIL'
