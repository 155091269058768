import axios from 'axios'
import {
  FETCH_CLASSROOMS_REQUEST,
  FETCH_CLASSROOMS_SUCCESS,
  FETCH_CLASSROOMS_FAIL,
  FETCH_USER_CLASSROOMS_FAIL,
  FETCH_USER_CLASSROOMS_SUCCESS,
  //FETCH_USER_CLASSROOMS_RESET,
  FETCH_USER_CLASSROOMS_REQUEST,
  CREATE_CLASSROOM_REQUEST,
  CREATE_CLASSROOM_SUCCESS,
  CREATE_CLASSROOM_FAIL,
  CLASSROOM_UPDATE_REQUEST,
  CLASSROOM_UPDATE_SUCCESS,
  CLASSROOM_UPDATE_FAIL,
  CLASSROOM_UPDATE_RESET,
  FETCH_CLASSROOM_DETAILS_REQUEST,
  FETCH_CLASSROOM_DETAILS_SUCCESS,
  FETCH_CLASSROOM_DETAILS_FAIL,
  CLASSROOM_DELETE_REQUEST,
  CLASSROOM_DELETE_SUCCESS,
  CLASSROOM_DELETE_FAIL,
  CLASSROOM_REGISTER_REQUEST,
  CLASSROOM_REGISTER_SUCCESS,
  CLASSROOM_REGISTER_FAIL,
  CLASSROOM_LOGIN_REQUEST,
  CLASSROOM_LOGIN_SUCCESS,
  CLASSROOM_LOGIN_FAIL,
  CLASSROOM_LOGIN_RESET,
} from '../constants/classroomConstants'
import { USER_LOGIN_SUCCESS } from '../constants/userConstants'
import { logout } from './userActions'

/// API for production
import { API } from '../apiConstants'

export const listClassrooms =
  (keyword = '', pageNumber = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_CLASSROOMS_REQUEST })

      const { data } = await axios.get(
        `${API}/api/classroom?keyword=${keyword}&pageNumber=${pageNumber}`
      )

      dispatch({
        type: FETCH_CLASSROOMS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: FETCH_CLASSROOMS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listMyClassrooms = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_USER_CLASSROOMS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${API}/api/classrooms/myclassrooms`,
      config
    )

    dispatch({
      type: FETCH_USER_CLASSROOMS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: FETCH_USER_CLASSROOMS_FAIL,
      payload: message,
    })
  }
}

export const createClassroom = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATE_CLASSROOM_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`${API}/api/classrooms`, {}, config)

    dispatch({
      type: CREATE_CLASSROOM_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: CREATE_CLASSROOM_FAIL,
      payload: message,
    })
  }
}

export const listClassroomDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_CLASSROOM_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${API}/api/classrooms/${id}`, config)

    dispatch({
      type: FETCH_CLASSROOM_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: FETCH_CLASSROOM_DETAILS_FAIL,
      payload: message,
    })
  }
}

export const deleteClassroom = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLASSROOM_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`${API}/api/classrooms/${id}`, config)

    dispatch({
      type: CLASSROOM_DELETE_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: CLASSROOM_DELETE_FAIL,
      payload: message,
    })
  }
}

export const updateClassroom = (classroom) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLASSROOM_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${API}/api/classrooms/${classroom._id}`,
      classroom,
      config
    )

    dispatch({
      type: CLASSROOM_UPDATE_SUCCESS,
      payload: data,
    })

    dispatch({ type: CLASSROOM_UPDATE_SUCCESS, payload: data })

    dispatch({ type: FETCH_CLASSROOM_DETAILS_SUCCESS, payload: data })

    dispatch({ type: CLASSROOM_UPDATE_RESET })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: CLASSROOM_UPDATE_FAIL,
      payload: message,
    })
  }
}

export const classroomLoginHandler =
  (classroomCode, password) => async (dispatch) => {
    try {
      dispatch({
        type: CLASSROOM_LOGIN_REQUEST,
      })

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${API}/api/classrooms/classlogin`,
        { classroomCode, password },
        config
      )

      dispatch({
        type: CLASSROOM_LOGIN_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: CLASSROOM_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const registerClassroom =
  (
    classroomCode,
    name,
    teacher,
    description,
    base_language,
    target_language,
    password
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CLASSROOM_REGISTER_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${API}/api/classrooms`,
        {
          classroomCode,
          name,
          teacher,
          description,
          base_language,
          target_language,
          password,
        },
        config
      )

      dispatch({
        type: CLASSROOM_REGISTER_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: CLASSROOM_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const clearClassroomLogin = () => (dispatch) => {
  dispatch({ type: CLASSROOM_LOGIN_RESET })
}
