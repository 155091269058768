import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { registerClassroom } from '../actions/classroomActions'

const ClassroomRegisterScreen = () => {
  let history = useNavigate()
  let location = useLocation()

  const [classroomCode, setClassroomCode] = useState('')
  const [name, setName] = useState('')
  const [teacher, setTeacher] = useState('')
  const [description, setDescription] = useState('')
  const [base_language, setBaseLanguage] = useState('english')
  const [target_language, setTargetLanguage] = useState('english')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)
  const [success, setSuccess] = useState(null)

  const dispatch = useDispatch()

  const classroomRegister = useSelector((state) => state.classroomRegister)
  const { loading, error, classroom } = classroomRegister

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (!userInfo) {
      history(redirect)
    }
    if (classroom) {
      setSuccess('Classroom Successfully Created!')
      setClassroomCode('')
      setName('')
      setTeacher('')
      setDescription('')
      setBaseLanguage('')
      setTargetLanguage('')
      setPassword('')
      setConfirmPassword('')
      setMessage('')
      history('/myclassrooms')
    }
  }, [history, userInfo, redirect, classroom])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      setSuccess(null)
      dispatch(
        registerClassroom(
          classroomCode,
          name,
          teacher,
          description,
          base_language,
          target_language,
          password
        )
      )
    }
  }

  return (
    <FormContainer>
      <Link to='/myclassrooms' className='btn btn-dark my-3'>
        Back to Your Classrooms
      </Link>
      <h1>Create Your Classroom</h1>
      {message && <Message variant='danger'>{message}</Message>}
      {success && <Message variant='success'>{success}</Message>}
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='classroomcode'>
          <Form.Label>Classroom Code</Form.Label>
          <Form.Control
            type='text'
            placeholder='Give a unique class code! (e.g. "FR23NYC")'
            value={classroomCode}
            onChange={(e) => setClassroomCode(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId='name'>
          <Form.Label>Classroom Title</Form.Label>
          <Form.Control
            type='text'
            placeholder='Give a name to your classroom!'
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId='teacher'>
          <Form.Label>Teacher</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter your teacher name'
            value={teacher}
            onChange={(e) => setTeacher(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='description'>
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter your description name'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></Form.Control>
        </Form.Group>

        {/* <Form.Group controlId='base_language'>
          <Form.Label>Base Language</Form.Label>
          <Form.Control
            type='text'
            placeholder='What language do your students know?'
            value={base_language}
            onChange={(e) => setBaseLanguage(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='target_language'>
          <Form.Label>Target Language</Form.Label>
          <Form.Control
            type='text'
            placeholder='What language are your students learning'
            value={target_language}
            onChange={(e) => setTargetLanguage(e.target.value)}
          ></Form.Control>
        </Form.Group> */}

        <Form.Group controlId='keycode'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter a keycode for your students'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='confirmKeycode'>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type='text'
            placeholder='Confirm the keycode above.'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button
          disabled={
            !name ||
            !classroomCode ||
            !teacher ||
            !description ||
            !password ||
            !confirmPassword ||
            password !== confirmPassword
          }
          type='submit'
          variant='primary'
        >
          Create Classroom
        </Button>
      </Form>
    </FormContainer>
  )
}

export default ClassroomRegisterScreen
