import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  teamListReducer,
  teamDetailsReducer,
  teamDeleteReducer,
  teamCreateReducer,
  teamUpdateReducer,
} from './reducers/teamReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userUpdateLevelReducer,
  userClearLevelReducer,
  userUpdateCustomLevelReducer,
} from './reducers/userReducers'
import {
  dashboardDeleteAllDataReducer,
  dashboardGlobalAccuracyDonutReducer,
  dashboardGlobalBarChartReducer,
  dashboardGlobalStudentHeatmapReducer,
  dashboardGlobalTimeSeriesReducer,
  dashboardLanguageAccuracyDonutReducer,
  dashboardLanguageBarChartReducer,
  dashboardLanguageLebeScoreReducer,
  dashboardLanguageMeanScoreBarChartReducer,
  dashboardLanguageStudentHeatmapReducer,
  dashboardLanguageTotalAnswerReducer,
  dashboardLanguageTotalQuestionReducer,
  dashboardLanguageTrickyWordsReducer,
  dashboardStudentTotalAnswerReducer,
  dashboardStudentTotalQuestionReducer,
  postDashboardDataReducer,
} from './reducers/dashboardReducers'
import {
  classroomListMyReducer,
  classroomListReducer,
  classroomCreateReducer,
  classroomDetailsReducer,
  classroomDeleteReducer,
  classroomUpdateReducer,
  classroomRegisterReducer,
  classroomLoginReducer,
} from './reducers/classroomReducers'
import {
  followerCreateReducer,
  followerDeleteReducer,
  followerGetClassroomsReducer,
  followerGetStudentsReducer,
} from './reducers/followerReducers'
import {
  fetchQuestionReducer,
  filterQuestionsReducer,
  questionCreateReducer,
  questionDeleteReducer,
  questionDetailsReducer,
  questionFlagReducer,
  questionUnflagReducer,
  questionUpdateReducer,
} from './reducers/questionReducers'
import {
  initializeCustomParametersReducer,
  initializeParametersReducer,
} from './reducers/startReducers'
import {
  notebookCreateReducer,
  notebookDeleteBulkReducer,
  notebookDeleteReducer,
  notebookDetailsReducer,
  notebookListMyReducer,
  notebooksFetchOneReducer,
  notebooksLevelsListReducer,
  notebookUpdateReducer,
} from './reducers/notebookReducers'
import {
  flagCreateReducer,
  flagFilterReducer,
  flagListReducer,
} from './reducers/flagReducers'

import {
  myQuestionAddClassroomReducer,
  myQuestionCreateReducer,
  myQuestionByClassroomReducer,
  myQuestionDeleteReducer,
  myQuestionDetailsReducer,
  myQuestionFilterReducer,
  myQuestionUpdateReducer,
  myQuestionRemoveClassroomReducer,
  myQuestionLowestLevelReducer,
  myQuestionFetchOneReducer,
  myQuestionLevelsListReducer,
  myQuestionDeleteBulkReducer,
} from './reducers/myQuestionsReducers'
import {
  myDashboardAnswerCountReducer,
  myDashboardDonutDataReducer,
  myDashboardPostDataReducer,
  myDashboardQuestionCountReducer,
} from './reducers/myDashboardReducers'
import {
  languageProfileBarChartReducer,
  languageProfileDonutCountReducer,
  languageProfileQuestionCountReducer,
  languageProfileTotalCountReducer,
  languageProfileVocabCountReducer,
} from './reducers/languageProfileReducers'

const reducer = combineReducers({
  questionFetch: fetchQuestionReducer,
  questionsFilter: filterQuestionsReducer,
  questionDetails: questionDetailsReducer,
  questionDelete: questionDeleteReducer,
  questionUpdate: questionUpdateReducer,
  questionCreate: questionCreateReducer,
  questionFlag: questionFlagReducer,
  questionUnflag: questionUnflagReducer,
  myQuestionFilter: myQuestionFilterReducer,
  myQuestionAddClassroom: myQuestionAddClassroomReducer,
  myQuestionRemoveClassroom: myQuestionRemoveClassroomReducer,
  myQuestionDelete: myQuestionDeleteReducer,
  myQuestionDeleteBulk: myQuestionDeleteBulkReducer,
  myQuestionDetails: myQuestionDetailsReducer,
  myQuestionCreate: myQuestionCreateReducer,
  myQuestionUpdate: myQuestionUpdateReducer,
  myQuestionByClassroom: myQuestionByClassroomReducer,
  myQuestionLowestLevel: myQuestionLowestLevelReducer,
  myQuestionLevelsList: myQuestionLevelsListReducer,
  myQuestionFetchOne: myQuestionFetchOneReducer,
  flagCreate: flagCreateReducer,
  flagList: flagListReducer,
  flagFilter: flagFilterReducer,
  teamList: teamListReducer,
  teamDetails: teamDetailsReducer,
  teamDelete: teamDeleteReducer,
  teamCreate: teamCreateReducer,
  teamUpdate: teamUpdateReducer,
  initialize: initializeParametersReducer,
  initializeCustom: initializeCustomParametersReducer,
  classroomList: classroomListReducer,
  classroomsListMy: classroomListMyReducer,
  classroomCreate: classroomCreateReducer,
  classroomDetails: classroomDetailsReducer,
  classroomUpdate: classroomUpdateReducer,
  classroomDelete: classroomDeleteReducer,
  classroomLogin: classroomLoginReducer,
  classroomRegister: classroomRegisterReducer,
  followerCreate: followerCreateReducer,
  followerGetClassrooms: followerGetClassroomsReducer,
  followerGetStudents: followerGetStudentsReducer,
  followerDelete: followerDeleteReducer,
  notebookCreate: notebookCreateReducer,
  notebookListMy: notebookListMyReducer,
  notebookDelete: notebookDeleteReducer,
  notebookDeleteBulk: notebookDeleteBulkReducer,
  notebookLevelsList: notebooksLevelsListReducer,
  notebookFetchOne: notebooksFetchOneReducer,
  notebookUpdate: notebookUpdateReducer,
  notebookDetails: notebookDetailsReducer,
  dashboardPost: postDashboardDataReducer,
  dashboardGlobalStudentHeatmap: dashboardGlobalStudentHeatmapReducer,
  dashboardStudentTotalQuestion: dashboardStudentTotalQuestionReducer,
  dashboardStudentTotalAnswer: dashboardStudentTotalAnswerReducer,
  dashboardGlobalAccuracyDonut: dashboardGlobalAccuracyDonutReducer,
  dashboardGlobalTimeSeries: dashboardGlobalTimeSeriesReducer,
  dashboardGlobalBarChart: dashboardGlobalBarChartReducer,
  dashboardLanguageTotalQuestion: dashboardLanguageTotalQuestionReducer,
  dashboardLanguageTotalAnswer: dashboardLanguageTotalAnswerReducer,
  dashboardLanguageAccuracyDonut: dashboardLanguageAccuracyDonutReducer,
  dashboardLanguageBarChart: dashboardLanguageBarChartReducer,
  dashboardLanguageLebeScore: dashboardLanguageLebeScoreReducer,
  dashboardLanguageMeanScoreBarChart: dashboardLanguageMeanScoreBarChartReducer,
  dashboardLanguageStudentHeatmap: dashboardLanguageStudentHeatmapReducer,
  dashboardLanguageTrickyWords: dashboardLanguageTrickyWordsReducer,
  dashboardDeleteAllData: dashboardDeleteAllDataReducer,
  myDashboardPostData: myDashboardPostDataReducer,
  myDashboardQuestionCount: myDashboardQuestionCountReducer,
  myDashboardAnswerCount: myDashboardAnswerCountReducer,
  myDashboardDonutData: myDashboardDonutDataReducer,
  languageProfileVocabCount: languageProfileVocabCountReducer,
  languageProfileBarChart: languageProfileBarChartReducer,
  languageProfileQuestionCount: languageProfileQuestionCountReducer,
  languageProfileTotalCount: languageProfileTotalCountReducer,
  languageProfileDonutCount: languageProfileDonutCountReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userUpdateLevel: userUpdateLevelReducer,
  userUpdateCustomLevel: userUpdateCustomLevelReducer,
  userClearLevel: userClearLevelReducer,
})

// const cartItemsFromStorage = localStorage.getItem('cartItems')
//   ? JSON.parse(localStorage.getItem('cartItems'))
//   : []

// const answerDataFromStorage = localStorage.getItem('answerData')
//   ? JSON.parse(localStorage.getItem('answerData'))
//   : []

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

// const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
//   ? JSON.parse(localStorage.getItem('shippingAddress'))
//   : {}

const initializeParametersFromStorage = localStorage.getItem(
  'initializeParameters'
)
  ? JSON.parse(localStorage.getItem('initializeParameters'))
  : {}

const initializeCustomParametersFromStorage = localStorage.getItem(
  'initializeCustomParameters'
)
  ? JSON.parse(localStorage.getItem('initializeCustomParameters'))
  : {}

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  // answers: {
  //   answerData: answerDataFromStorage,
  // },

  initialize: { initializeParameters: initializeParametersFromStorage },
  initializeCustom: {
    initializeCustomParameters: initializeCustomParametersFromStorage,
  },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
