import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Table, Button, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'

import { deleteFollower, listMyStudents } from '../actions/followerActions'
import classes from './StudentListBox.module.css'

const StudentListBox = ({ id }) => {
  const classroom = { classroom: id }

  let history = useNavigate()

  const dispatch = useDispatch()

  const followerGetStudents = useSelector((state) => state.followerGetStudents)
  const { loading, error, students } = followerGetStudents

  const followerDelete = useSelector((state) => state.followerDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = followerDelete

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history('/login')
    } else {
      dispatch(listMyStudents(classroom))
    }
  }, [dispatch, history, userInfo, successDelete])

  const deleteHandler = (studentId) => {
    if (
      window.confirm(
        'Are you sure you want to remove this student from your classroom?'
      )
    ) {
      dispatch(deleteFollower(studentId))
    }
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Students</h1>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Card className={classes['student-list-card']}>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>ID</th>
                <th>USERNAME</th>
                <th>NAME</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student._id}>
                  <td>{student._id}</td>
                  <td>{student.student.name}</td>
                  <td>{student.studentName}</td>
                  <td>
                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => deleteHandler(student._id)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      )}
    </>
  )
}

export default StudentListBox
