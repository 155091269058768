import React, { useState } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router'

const SearchBox = () => {
  const [keyword, setKeyword] = useState('')
  let history = useNavigate()

  const submitHandler = (e) => {
    e.preventDefault()

    if (keyword.trim()) {
      history(`/admin/questionfilter/search/${keyword}`)
    } else {
      history('/admin/questionfilter')
    }
  }

  return (
    <div>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col>
            <Form.Control
              type='text'
              name='q'
              onChange={(e) => setKeyword(e.target.value)}
              placeholder='Search questions...'
              className='mr-sm-1 ml-sm-1'
            ></Form.Control>
          </Col>
          <Col>
            <Button type='submit' variant='outline-success' className='p-2'>
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default SearchBox
