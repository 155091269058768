export const MY_DASHBOARD_POST_DATA_REQUEST = 'MY_DASHBOARD_POST_DATA_REQUEST'
export const MY_DASHBOARD_POST_DATA_SUCCESS = 'MY_DASHBOARD_POST_DATA_SUCCESS'
export const MY_DASHBOARD_POST_DATA_FAIL = 'MY_DASHBOARD_POST_DATA_FAIL'

export const MY_DASHBOARD_QUESTION_COUNT_REQUEST =
  'MY_DASHBOARD_QUESTION_COUNT_REQUEST'
export const MY_DASHBOARD_QUESTION_COUNT_SUCCESS =
  'MY_DASHBOARD_QUESTION_COUNT_SUCCESS'
export const MY_DASHBOARD_QUESTION_COUNT_FAIL =
  'MY_DASHBOARD_QUESTION_COUNT_FAIL'

export const MY_DASHBOARD_ANSWER_COUNT_REQUEST =
  'MY_DASHBOARD_ANSWER_COUNT_REQUEST'
export const MY_DASHBOARD_ANSWER_COUNT_SUCCESS =
  'MY_DASHBOARD_ANSWER_COUNT_SUCCESS'
export const MY_DASHBOARD_ANSWER_COUNT_FAIL = 'MY_DASHBOARD_ANSWER_COUNT_FAIL'

export const MY_DASHBOARD_DONUT_DATA_REQUEST = 'MY_DASHBOARD_DONUT_DATA_REQUEST'
export const MY_DASHBOARD_DONUT_DATA_SUCCESS = 'MY_DASHBOARD_DONUT_DATA_SUCCESS'
export const MY_DASHBOARD_DONUT_DATA_FAIL = 'MY_DASHBOARD_DONUT_DATA_FAIL'
