import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import AboutNavbar from '../components/AboutNavbar'
import ParagraphBox from '../components/ParagraphBox'
import classes from './AboutScreen.module.css'

const FutureScreen = () => {
  return (
    <div>
      <AboutNavbar />
      <div className={classes.scrollContainer}>
        <ParagraphBox
          title={'The Future'}
          paragraph={
            "As the 'beta' indicates, this product is not yet intended for public use and several developments need to take place before a genuine product launch. We aspire to more formally launch this product in 2023."
          }
        />
        <ParagraphBox
          title={'Next Steps: Total UI/UX Redesign'}
          paragraph={
            'Lëbe Language is currently undergoing a complete UI/UX redesign that will improve the layout and functionality of the platform. Everything including the logos, fonts, colors, illustrations, etc. is  considered to be a placeholder until the redesign process is complete. We hope to have our total redesign completed by the end of the year. '
          }
        />
        <ParagraphBox
          title={'Next Steps: Improving The Dashboards'}
          paragraph={
            'The dashboards are currently underdeveloped, and as we gather more data from the beta testers, we will be deploying a much broader variety of learning analytics tools that will provide more granular feedback to users.'
          }
        />
        <ParagraphBox
          title={'Next Steps: Mobile Apps'}
          paragraph={
            "This platform is currently only serviceable on a desktop computer. Given that most language learners throughout the world don't have regular access to desktop browsers, we hope to launch mobile versions for both iPhone and Android by 2023."
          }
        />
        <ParagraphBox
          title={'Next Steps: Audio Content'}
          paragraph={
            'Although a text-only experience is serviceable for some, we very much intend to include some form of audio interaction for each question. Though the data pipelines for obtaining audio files are in place (for most languages), this aspect of the project has yet to be implemented due to current limitations of data storage capacity.'
          }
        />
        <ParagraphBox
          title={'Next Steps: More Languages'}
          paragraph={
            "The app currently produces questions in Dutch, Spanish, French, English, German, and Portuguese. The next wave of languages to be introduced will be Italian, Swedish, Norwegian, Danish, Finnish, and Turkish. Other high-priority languages include Japanese, Mandarin, and Korean, although more research is needed in order to optimize our data pipelines for non-romanized text. Although we have managed to create some content in Dzongkha, this is considered a 'low-resource language'. As such, we hope to create custom language models and word vectors for Dzongkha in order to improve the quality and comprehensiveness of the learning content."
          }
        />
      </div>
    </div>
  )
}
export default FutureScreen
