import axios from 'axios'
import {
  MY_DASHBOARD_ANSWER_COUNT_FAIL,
  MY_DASHBOARD_ANSWER_COUNT_REQUEST,
  MY_DASHBOARD_ANSWER_COUNT_SUCCESS,
  MY_DASHBOARD_DONUT_DATA_FAIL,
  MY_DASHBOARD_DONUT_DATA_REQUEST,
  MY_DASHBOARD_DONUT_DATA_SUCCESS,
  MY_DASHBOARD_POST_DATA_FAIL,
  MY_DASHBOARD_POST_DATA_REQUEST,
  MY_DASHBOARD_POST_DATA_SUCCESS,
  MY_DASHBOARD_QUESTION_COUNT_FAIL,
  MY_DASHBOARD_QUESTION_COUNT_REQUEST,
  MY_DASHBOARD_QUESTION_COUNT_SUCCESS,
} from '../constants/myDashboardConstants'
import { logout } from './userActions'
import { API } from '../apiConstants'

export const postMyDashboardData =
  (answerData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MY_DASHBOARD_POST_DATA_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${API}/api/mydashboards`,
        answerData,
        config
      )

      dispatch({
        type: MY_DASHBOARD_POST_DATA_SUCCESS,
        payload: data,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: MY_DASHBOARD_POST_DATA_FAIL,
        payload: message,
      })
    }
  }

export const fetchMyDashboardQuestionCount =
  (filter) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      dispatch({
        type: MY_DASHBOARD_QUESTION_COUNT_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/mydashboards/questioncount`,
        {
          params: filter,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )

      dispatch({
        type: MY_DASHBOARD_QUESTION_COUNT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: MY_DASHBOARD_QUESTION_COUNT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const fetchMyDashboardAnswerCount =
  (filter) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      dispatch({
        type: MY_DASHBOARD_ANSWER_COUNT_REQUEST,
      })

      const { data } = await axios.get(`${API}/api/mydashboards/answercount`, {
        params: filter,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      })

      dispatch({
        type: MY_DASHBOARD_ANSWER_COUNT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: MY_DASHBOARD_ANSWER_COUNT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const fetchMyDashboardDonutData =
  (filter) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      dispatch({
        type: MY_DASHBOARD_DONUT_DATA_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/mydashboards/overallaccuracy`,
        {
          params: filter,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )

      dispatch({
        type: MY_DASHBOARD_DONUT_DATA_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: MY_DASHBOARD_DONUT_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
