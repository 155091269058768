import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Row, Col, Image, ListGroup } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import { listTeamMemberDetails } from '../actions/teamActions'
import classes from './TeamMemberScreen.module.css'

const TeamMemberScreen = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const teamDetails = useSelector((state) => state.teamDetails)
  const { loading, error, teamMember } = teamDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!teamMember._id || teamMember._id !== id) {
      dispatch(listTeamMemberDetails(id))
    }
  }, [dispatch, id])

  return (
    <div className={classes.container}>
      <Link className='btn btn-light my-3' to='/team'>
        Go Back
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Meta title={teamMember.name} />
          <Row>
            <Col md={4}>
              <Image src={teamMember.image} alt={teamMember.name} fluid />
            </Col>
            <Col md={6}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h3>{teamMember.name}</h3>
                </ListGroup.Item>
                <ListGroup.Item>{teamMember.role}</ListGroup.Item>
                <ListGroup.Item>{teamMember.description}</ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}

export default TeamMemberScreen
