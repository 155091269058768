import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Form, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import {
  createQuestion,
  deleteQuestion,
  filterQuestions,
} from '../actions/questionActions'
import { QUESTION_CREATE_RESET } from '../constants/questionConstants'
import SearchBox from '../components/SearchBox'
import {
  dropdown_level_options,
  dropdown_question_type_options,
  dropdown_target_options,
  dropdown_text_type_options,
  dropdown_user_options,
} from '../constants/dropdownConstants'
import classes from './ListScreen.module.css'

const QuestionListScreen = () => {
  const params = useParams()
  const pageNumber = params.pageNumber || 1

  const { keyword } = useParams()

  const [level, setLevel] = useState('')
  const [userLanguage, setUserLanguage] = useState('')
  const [targetLanguage, setTargetLanguage] = useState('')
  const [textType, setTextType] = useState('')
  const [questionType, setQuestionType] = useState('')
  const [showFilters, setShowFilters] = useState(false)

  let history = useNavigate()

  const dispatch = useDispatch()

  const questionsFilter = useSelector((state) => state.questionsFilter)
  const { loading, error, questions, page, pages } = questionsFilter

  const filter = {
    level: level,
    userLanguage: userLanguage,
    targetLanguage: targetLanguage,
    textType: textType,
    questionType: questionType,
  }

  const questionDelete = useSelector((state) => state.questionDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = questionDelete

  const questionCreate = useSelector((state) => state.questionCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    question: createdQuestion,
  } = questionCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: QUESTION_CREATE_RESET })

    if (!userInfo || !userInfo.isAdmin) {
      history('/login')
    }

    if (successCreate) {
      history(`/admin/questionfilter/${createdQuestion._id}/edit`)
    } else {
      dispatch(filterQuestions(filter, keyword, pageNumber))
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdQuestion,
    pageNumber,
    params,
  ])

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history('/login')
    } else {
      dispatch(filterQuestions(filter, '', pageNumber))
    }
  }, [level, targetLanguage, userLanguage, questionType, textType])

  useEffect(() => {
    if (!userInfo) {
      history('/login')
    } else {
      history(
        keyword
          ? `/admin/questionfilter/${keyword}/page/${1}`
          : `/admin/questionfilter/page/${1}`
      )
    }
  }, [level, targetLanguage, userLanguage, questionType, textType])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteQuestion(id))
    }
  }
  const createQuestionHandler = () => {
    dispatch(createQuestion())
  }

  const filterHandler = () => {
    setShowFilters(!showFilters)
  }

  return (
    <div className={classes.container}>
      <Row className='align-items-center'>
        <Col>
          <h1>MY ADMIN QUESTIONS</h1>
        </Col>
        <Col className='text-right'>
          <Button
            className={classes.createQuestionButton}
            onClick={createQuestionHandler}
          >
            <i className='fas fa-plus'></i> Create New Question
          </Button>
        </Col>
      </Row>
      <div className={classes.filterContainer}>
        <Button className={classes.showFiltersButton} onClick={filterHandler}>
          Show Filters
        </Button>

        {showFilters && (
          <div>
            <Row>
              <Col>
                <Card className={classes.filterCard}>
                  <h4>Filter Questions</h4>
                  <div>
                    <Form.Label>User Language:</Form.Label>
                    <Form.Select
                      onChange={(e) => setUserLanguage(e.target.value)}
                    >
                      {dropdown_user_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>

                  <div>
                    <Form.Label>Target Language:</Form.Label>
                    <Form.Select
                      onChange={(e) => setTargetLanguage(e.target.value)}
                    >
                      {dropdown_target_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>

                  <div>
                    <Form.Label>Level:</Form.Label>
                    <Form.Select onChange={(e) => setLevel(e.target.value)}>
                      {dropdown_level_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div>
                    <Form.Label>Question Type:</Form.Label>
                    <Form.Select
                      onChange={(e) => setQuestionType(e.target.value)}
                    >
                      {dropdown_question_type_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div>
                    <Form.Label>Text Type:</Form.Label>
                    <Form.Select onChange={(e) => setTextType(e.target.value)}>
                      {dropdown_text_type_options.map((option) => (
                        <option
                          href='#'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                    <Col>
                      <SearchBox />
                    </Col>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        )}
        {/* {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>} */}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Card className={classes.tableCard}>
            <Table striped bordered hover responsive className='table-sm'>
              <thead>
                <tr>
                  <th>USER_LANG</th>
                  <th>TARGET_LANG</th>
                  <th>LEVEL</th>
                  <th>QUESTION TEXT</th>
                  <th>TEXT TYPE</th>
                  <th>QUESTION TYPE</th>
                </tr>
              </thead>
              <tbody>
                {questions.map((question) => (
                  <tr key={question._id}>
                    <td>{question.userLanguage}</td>
                    <td>{question.targetLanguage}</td>
                    <td>{question.level}</td>
                    <td>{question.question}</td>
                    <td>{question.textType}</td>
                    <td>{question.questionType}</td>
                    <td>
                      <LinkContainer
                        to={`/admin/questionfilter/${question._id}/edit`}
                      >
                        <Button variant='light' className='btn-sm'>
                          <i className='fas fa-edit'></i>
                        </Button>
                      </LinkContainer>
                      <Button
                        variant='danger'
                        className='btn-sm'
                        onClick={() => deleteHandler(question._id)}
                      >
                        <i className='fas fa-trash'></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className={classes.paginationContainer}>
              <Paginate pages={pages} page={page} isAdmin={true} />
            </div>
          </Card>
        )}
      </div>
    </div>
  )
}

export default QuestionListScreen
