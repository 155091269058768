import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getQuestionDetails, updateQuestion } from '../actions/questionActions'
import { UPDATE_QUESTION_DETAILS_RESET } from '../constants/questionConstants'
import {
  dropdown_flag_options,
  dropdown_level_options,
  dropdown_question_type_options,
  dropdown_target_options,
  dropdown_user_options,
  dropdown_weight_options,
} from '../constants/dropdownConstants'
import classes from './EditScreen.module.css'

const FlagEditScreen = () => {
  //const { productId } = useParams()
  const params = useParams()
  const questionId = params.id
  let history = useNavigate()

  // answers: user: category: level: weight: question: full_question: type: user_language: target_language: isFlagged citation text_type,

  const [question, setQuestion] = useState('')
  const [answers, setAnswers] = useState([])
  const [category, setCategory] = useState('')
  const [level, setLevel] = useState('')
  const [weight, setWeight] = useState(1)
  const [fullQuestion, setFullQuestion] = useState('')
  const [questionType, setQuestionType] = useState('')
  const [userLanguage, setUserLanguage] = useState('')
  const [targetLanguage, setTargetLanguage] = useState('')
  const [isFlagged, setIsFlagged] = useState('')
  const [citation, setCitation] = useState('')
  const [textType, setTextType] = useState('')

  const updateAnswers = (index, e) => {
    let newArr = [...answers] // copying the old datas array
    if (e.target.id.includes('answer')) {
      newArr[index]['text'] = e.target.value // replace e.target.value with whatever you want to change it to
      setAnswers(newArr)
    } else if (e.target.id.includes('isCorrect')) {
      newArr[index]['isCorrect'] = e.target.value
      setAnswers(newArr)
    } else {
    }
  }

  const addOptionHandler = (e) => {
    e.preventDefault()
    let newArr = [...answers]
    newArr.push({ isCorrect: 'false', text: 'Answer' })
    setAnswers(newArr)
  }

  const removeOptionHandler = (e) => {
    e.preventDefault()
    let newArr = [...answers]
    newArr.pop()
    setAnswers(newArr)
  }

  const dispatch = useDispatch()

  const questionDetails = useSelector((state) => state.questionDetails)
  const { loading, error } = questionDetails
  const questionFetched = questionDetails.question

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const questionUpdate = useSelector((state) => state.questionUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = questionUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: UPDATE_QUESTION_DETAILS_RESET })
      history('/admin/flags')
    } else {
      if (!questionFetched._id || questionFetched._id !== questionId) {
        dispatch(getQuestionDetails(questionId))
      } else {
        setQuestion(questionFetched.question)
        setAnswers(questionFetched.answers)
        setCategory(questionFetched.category)
        setLevel(questionFetched.level)
        setWeight(questionFetched.weight)
        setFullQuestion(questionFetched.fullQuestion)
        setQuestionType(questionFetched.questionType)
        setUserLanguage(questionFetched.userLanguage)
        setTargetLanguage(questionFetched.targetLanguage)
        setIsFlagged(questionFetched.isFlagged)
        setCitation(questionFetched.citation)
        setTextType(questionFetched.textType)
      }
    }
  }, [dispatch, history, questionId, questionFetched, successUpdate])

  // Dynamic Dropdowns

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateQuestion({
        user: userInfo._id,
        _id: questionId,
        question,
        answers,
        category,
        level,
        weight,
        fullQuestion,
        questionType,
        userLanguage,
        targetLanguage,
        isFlagged,
        citation,
        textType,
      })
    )
  }

  return (
    <div className={classes.container}>
      <Link to='/admin/flags' className='btn btn-light my-3'>
        Go Back
      </Link>
      <div className={classes.formContainer}>
        <>
          <h1>Edit Question</h1>
          {loadingUpdate && <Loader />}
          {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='question'>
                <Form.Label>Question</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter question text'
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <div className={classes.answersContainer}>
                {answers.map((answer, index) => (
                  <Form.Group>
                    <Form.Label>ANSWER {index + 1}</Form.Label>
                    <Row>
                      <Col>
                        <Form.Control
                          id={`answer${index}`}
                          type='text'
                          placeholder='Enter answer'
                          value={answer.text}
                          onChange={(e) => updateAnswers(index, e)}
                        ></Form.Control>
                      </Col>
                      <Col>
                        <Form.Select
                          id={`isCorrectForm${index}`}
                          onChange={(e) => updateAnswers(index, e)}
                        >
                          <option
                            id={`isCorrect_1${index}`}
                            value={answer.isCorrect}
                          >
                            {answer.isCorrect === true
                              ? 'CORRECT ANSWER'
                              : 'INCORRECT'}
                          </option>
                          <option
                            id={`isCorrect_2${index}`}
                            value={answer.isCorrect === true ? false : true}
                          >
                            {answer.isCorrect === true
                              ? 'INCORRECT'
                              : 'CORRECT ANSWER'}
                          </option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>
                ))}

                <Row>
                  <Col>
                    <Button
                      className={classes.addAnswerButton}
                      type='btn'
                      variant='success'
                      onClick={addOptionHandler}
                    >
                      Add Answer
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className={classes.removeAnswerButton}
                      type='btn'
                      variant='danger'
                      onClick={removeOptionHandler}
                    >
                      Remove Answer
                    </Button>
                  </Col>
                </Row>
              </div>

              <Form.Group controlId='category'>
                <Form.Label>Category</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter question category'
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='level'>
                <Form.Label>Level</Form.Label>
                <Form.Select
                  className={classes.dropdownContainer}
                  value={
                    dropdown_level_options.filter(
                      (option) => option['value'] === level
                    )[0].value
                  }
                  onChange={(e) => setLevel(e.target.value)}
                >
                  {dropdown_level_options.slice(1).map((option) => (
                    <option href='#' value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId='weight'>
                <Form.Label>Weight</Form.Label>
                <Form.Select
                  className={classes.dropdownContainer}
                  value={
                    dropdown_weight_options.filter(
                      (option) => option['value'] === weight
                    )[0].value
                  }
                  onChange={(e) => setWeight(e.target.value)}
                >
                  {dropdown_weight_options.slice(1).map((option) => (
                    <option href='#' value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId='full_question'>
                <Form.Label>Full Question Text</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter full question'
                  value={fullQuestion}
                  onChange={(e) => setFullQuestion(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='type'>
                <Form.Label>Question Type</Form.Label>
                <Form.Select
                  className={classes.dropdownContainer}
                  value={
                    dropdown_question_type_options.filter(
                      (option) => option['value'] === questionType
                    )[0].value
                  }
                  onChange={(e) => setQuestionType(e.target.value)}
                >
                  {dropdown_question_type_options.slice(1).map((option) => (
                    <option href='#' value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId='user_language'>
                <Form.Label>User Language</Form.Label>
                <Form.Select
                  className={classes.dropdownContainer}
                  value={
                    dropdown_user_options.filter(
                      (option) => option['value'] === userLanguage
                    )[0].value
                  }
                  onChange={(e) => setUserLanguage(e.target.value)}
                >
                  {dropdown_user_options.slice(1).map((option) => (
                    <option href='#' value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId='target_language'>
                <Form.Label>Target Language</Form.Label>
                <Form.Select
                  className={classes.dropdownContainer}
                  value={
                    dropdown_target_options.filter(
                      (option) => option['value'] === targetLanguage
                    )[0].value
                  }
                  onChange={(e) => setTargetLanguage(e.target.value)}
                >
                  {dropdown_target_options.slice(1).map((option) => (
                    <option href='#' value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId='flag_edit'>
                <Form.Label>RED FLAGS</Form.Label>
                <Form.Select
                  className={classes.dropdownContainer}
                  value={
                    dropdown_flag_options.filter(
                      (option) => option['value'] === isFlagged
                    )[0]?.value
                  }
                  onChange={(e) => setIsFlagged(e.target.value)}
                >
                  {dropdown_flag_options.slice(1).map((option) => (
                    <option href='#' value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId='text_type'>
                <Form.Label>Text Type</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter text type'
                  value={textType}
                  onChange={(e) => setTextType(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='citation'>
                <Form.Label>Citation</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter citation if necessary'
                  value={citation}
                  onChange={(e) => setCitation(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Button
                className={classes.updateQuestionButton}
                type='submit'
                variant='primary'
              >
                Update
              </Button>
            </Form>
          )}
        </>
      </div>
    </div>
  )
}

export default FlagEditScreen
