import React, { useEffect, useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Form, Row, Col, Card } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  deleteNotebook,
  deleteNotebookBulk,
  listMyNotebooks,
} from '../actions/notebookActions'
import {
  dropdown_level_options,
  dropdown_question_type_options,
  dropdown_target_options,
  dropdown_text_type_options,
  dropdown_user_options,
} from '../constants/dropdownConstants'

import classes from './ListScreen.module.css'
import PaginateNotebooks from '../components/PaginateNotebooks'
import SuggestionBird from '../components/SuggestionBird'
import NotebookImage from '../components/NotebookImage'

const NotebookListScreen = () => {
  const params = useParams()
  const pageNumber = params.pageNumber || 1
  const { keyword } = useParams()

  const [level, setLevel] = useState('')
  const [userLanguage, setUserLanguage] = useState('')
  const [targetLanguage, setTargetLanguage] = useState('')
  const [textType, setTextType] = useState('')
  const [questionType, setQuestionType] = useState('')
  const [selectAll, setSelectAll] = useState([])
  const [notebookList, setNotebookList] = useState([])
  const [showFilters, setShowFilters] = useState(false)

  let history = useNavigate()
  const dispatch = useDispatch()

  const filter = {
    level: level,
    userLanguage: userLanguage,
    targetLanguage: targetLanguage,
    textType: textType,
    questionType: questionType,
  }

  const notebookListMy = useSelector((state) => state.notebookListMy)
  const { loading, error, notebooks, page, pages } = notebookListMy

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const notebookDelete = useSelector((state) => state.notebookDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = notebookDelete

  const notebookDeleteBulk = useSelector((state) => state.notebookDeleteBulk)

  const {
    loading: loadingDeleteBulk,
    error: errorDeleteBulk,
    success: successDeleteBulk,
  } = notebookDeleteBulk

  useEffect(() => {
    if (!userInfo) {
      history('/login')
    } else {
      dispatch(listMyNotebooks(filter, '', pageNumber))
    }
  }, [
    history,
    dispatch,
    userInfo,
    pageNumber,
    level,
    targetLanguage,
    userLanguage,
    questionType,
    textType,
    successDelete,
    successDeleteBulk,
  ])

  useEffect(() => {
    if (!userInfo) {
      history('/login')
    } else {
      history(
        keyword
          ? `/notebook/search/${keyword}/page/${1}`
          : `/notebook/page/${1}`
      )
    }
  }, [level, targetLanguage, userLanguage, questionType, textType])

  useEffect(() => {
    if (notebooks) {
      setSelectAll(
        Array.apply(false, Array(notebooks.length)).map(function (x, i) {
          return false
        })
      )
    }
  }, [notebooks])

  useEffect(() => {
    if (notebooks) {
      let ids = notebooks.map((notebook, index) => notebook._id)
      let selected = selectAll
      let notebookIdList = ids.filter((item, index) => selected[index] && item)
      setNotebookList(notebookIdList)
    }
  }, [selectAll])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure you want to delete this notebook')) {
      dispatch(deleteNotebook(id))
    }
  }

  const deleteBulkHandler = () => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteNotebookBulk({ notebooks: notebookList }))
    }
  }

  const filterHandler = () => {
    setShowFilters(!showFilters)
  }

  const checkboxHandler = (e) => {
    if (e) {
      let newSelectAll = [...selectAll]
      if (e.target.checked === true) {
        newSelectAll[Number(e.target.value)] = true
        setSelectAll(newSelectAll)
      } else {
        newSelectAll[Number(e.target.value)] = false
        setSelectAll(newSelectAll)
      }
    }
  }

  const selectAllHandler = (e) => {
    if (e.target.checked === true) {
      setSelectAll(
        selectAll.map(function (x, i) {
          return true
        })
      )
    } else if (e.target.checked === false) {
      setSelectAll(
        selectAll.map(function (x, i) {
          return false
        })
      )
    }
  }

  return (
    <div className={classes.container}>
      <div>
        <Row className='my-3'>
          <Col>
            <h1 className={classes.headerText}>MY NOTEBOOK</h1>
          </Col>
          <Col>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : notebooks.length ? (
              <LinkContainer to={`/notebook/module/`}>
                <Button
                  className={classes.practiceButton}
                  type='btn'
                  variant='primary'
                >
                  Practice
                </Button>
              </LinkContainer>
            ) : (
              <div></div>
            )}
          </Col>
        </Row>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : notebooks.length ? (
          <>
            <div>
              <div className={classes.filterContainer}>
                <Button
                  className={classes.showFiltersButton}
                  onClick={filterHandler}
                >
                  Show Filters
                </Button>

                {showFilters && (
                  <div>
                    <Row>
                      <Col>
                        <Card className={classes.filterCard}>
                          <div>
                            <Form.Label>User Language:</Form.Label>
                            <Form.Select
                              onChange={(e) => setUserLanguage(e.target.value)}
                            >
                              {dropdown_user_options.map((option) => (
                                <option
                                  href='#'
                                  value={option.value}
                                  key={option.value}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </Form.Select>
                          </div>

                          <div>
                            <Form.Label>Target Language:</Form.Label>
                            <Form.Select
                              onChange={(e) =>
                                setTargetLanguage(e.target.value)
                              }
                            >
                              {dropdown_target_options.map((option) => (
                                <option
                                  href='#'
                                  value={option.value}
                                  key={option.value}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </Form.Select>
                          </div>

                          <div>
                            <Form.Label>Level:</Form.Label>
                            <Form.Select
                              onChange={(e) => setLevel(e.target.value)}
                            >
                              {dropdown_level_options.map((option) => (
                                <option
                                  href='#'
                                  value={option.value}
                                  key={option.value}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                          <div>
                            <Form.Label>Question Type:</Form.Label>
                            <Form.Select
                              onChange={(e) => setQuestionType(e.target.value)}
                            >
                              {dropdown_question_type_options.map((option) => (
                                <option
                                  href='#'
                                  value={option.value}
                                  key={option.value}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                          <div>
                            <Form.Label>Text Type:</Form.Label>
                            <Form.Select
                              onChange={(e) => setTextType(e.target.value)}
                            >
                              {dropdown_text_type_options.map((option) => (
                                <option
                                  href='#'
                                  value={option.value}
                                  key={option.value}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                )}

                {loading ? (
                  <Loader />
                ) : error ? (
                  <Message variant='danger'>{error}</Message>
                ) : (
                  <>
                    <Card className={classes.tableCard}>
                      <Table
                        striped
                        bordered
                        hover
                        responsive
                        className='table-sm'
                      >
                        <thead>
                          <tr>
                            <th>QUESTION</th>
                            <th>LANGUAGE</th>
                            <th>LEVEL</th>
                            <th>NOTE</th>
                            <th>PRIORITY</th>
                            <th>HINT</th>
                            <th>SOURCE</th>
                            <th>COLLECTION</th>
                            <th>
                              {' '}
                              <Form>
                                <Form.Group
                                  className='mb-3'
                                  controlId={`selectallcheckbox`}
                                >
                                  <Form.Check
                                    type='checkbox'
                                    label='Select All'
                                    onChange={selectAllHandler}
                                  />
                                </Form.Group>
                              </Form>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {notebooks.map((notebook, index) => (
                            <tr key={notebook._id}>
                              <td>{notebook.question}</td>
                              <td>{notebook.targetLanguage}</td>
                              <td>{notebook.level}</td>
                              <td>{notebook.note}</td>
                              <td>{notebook.priority}</td>
                              <td>{notebook.hint}</td>
                              <td>{notebook.source}</td>
                              <td>{notebook.collectionName}</td>
                              <td>
                                <Form controlid={`checkform-${index}`}>
                                  <Form.Group
                                    className='mb-3'
                                    controlId={`checkbox-${index}`}
                                  >
                                    <Form.Check
                                      type='checkbox'
                                      label=''
                                      checked={selectAll[index]}
                                      value={index}
                                      onChange={checkboxHandler}
                                    />
                                  </Form.Group>
                                </Form>
                              </td>
                              <td>
                                <LinkContainer
                                  to={`/notebook/${notebook._id}/edit`}
                                >
                                  <Button variant='light' className='btn-sm'>
                                    <i className='fas fa-edit'></i>
                                  </Button>
                                </LinkContainer>
                                <Button
                                  variant='danger'
                                  className='btn-sm'
                                  onClick={() => deleteHandler(notebook._id)}
                                >
                                  <i className='fas fa-trash'></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className={classes.paginationContainer}>
                        <PaginateNotebooks
                          pages={pages}
                          page={page}
                          isAdmin={true}
                        />
                      </div>
                    </Card>
                    <Col>
                      <Button
                        className={classes.deleteQuestionsButton}
                        onClick={deleteBulkHandler}
                        disabled={
                          selectAll.filter(Boolean).length > 0 ? false : true
                        }
                      >
                        Delete Selected Questions
                      </Button>
                    </Col>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <NotebookImage />
        )}
      </div>
    </div>
  )
}

export default NotebookListScreen
