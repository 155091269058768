import React, { useEffect, useState } from 'react'
import classes from './LevelBar.module.css'
import { Button, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { getQuestions } from '../actions/questionActions'

const languageLevelDictionary = {
  portuguese: 10,
  french: 10,
  dutch: 8,
  dzongkha: 10,
  german: 10,
  spanish: 10,
  english: 10,
  sango: 10,
}

const LevelBar = (props) => {
  let dispatch = useDispatch()

  const [levelList, setLevelList] = useState([])

  const changeLevelHandler = (e) => {
    dispatch(
      getQuestions({
        level: e.target.value,
        targetLanguage: props.targetLanguage,
      })
    )
    props.setScoreList([])
    props.setCurrentLevel(e.target.value)
    props.setAttemptCount(0)
  }

  useEffect(() => {
    if (document.getElementById(`levelButton${props.currentLevel}`) != null) {
      document.getElementById(
        `levelButton${props.currentLevel}`
      ).backgroundColor = '#2fb986'
    }
  }, [props.currentLevel])

  useEffect(() => {
    if ((props.targetLanguage == 'english') & (props.maxLevel < 6)) {
      setLevelList(Array.from('1'.repeat(6)).concat(Array.from('0'.repeat(4))))
    } else {
      if (props.maxLevel < props.numLanguageLevels) {
        setLevelList(
          Array.from('1'.repeat(props.maxLevel)).concat(
            Array.from(
              '0'.repeat(
                languageLevelDictionary[props.targetLanguage] - props.maxLevel
              )
            )
          )
        )
      } else {
        setLevelList(
          Array.from('1'.repeat(props.maxLevel)).concat(
            Array.from(
              '0'.repeat(
                languageLevelDictionary[props.targetLanguage] - props.maxLevel
              )
            )
          )
        )
      }
    }
  }, [props.maxLevel])

  return (
    <>
      {/* <div className={classes.tooltip}>
        <h4
          className={classes.hintText}
        >{`ANSWER QUESTIONS TO UNLOCK LEVELS`}</h4>
      </div> */}
      <div className={classes.levelBarContainer}>
        <div>
          <Row className={classes['tooltip-on-hover']}>
            <Col>
              {levelList.map((level, index) =>
                Number(index + 1) == Number(props.currentLevel) ? (
                  <Button
                    className={classes.currentLevelButton}
                    key={`levelButton${index + 1}`}
                    value={index + 1}
                    onClick={changeLevelHandler}
                  ></Button>
                ) : Number(level) > 0 ? (
                  <Button
                    className={classes.levelButton}
                    key={`levelButton${index + 1}`}
                    value={index + 1}
                    onClick={changeLevelHandler}
                  ></Button>
                ) : (
                  <Button
                    className={classes.levelButton}
                    key={`levelButton${index + 1}`}
                    value={index + 1}
                    disabled={true}
                    onClick={changeLevelHandler}
                  />
                )
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default LevelBar
