import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'
import { clearClassroomLogin } from '../actions/classroomActions'
import { createFollower } from '../actions/followerActions'
import { CLASSROOM_LOGIN_RESET } from '../constants/classroomConstants'
import { FOLLOWER_CREATE_RESET } from '../constants/followerConstants'
import Loader from './Loader'

import Message from './Message'

const ModalClassroomLogin = (props) => {
  let history = useNavigate()

  const [studentName, setStudentName] = useState('')

  const dispatch = useDispatch()

  const followerCreate = useSelector((state) => state.followerCreate)
  const {
    loading: loadingFollower,
    error: followerError,
    follower,
  } = followerCreate

  const joinClassHandler = () => {
    if (props.classroomInfo) {
      const classroom = props.classroomInfo._id

      dispatch(createFollower(classroom, studentName))
    }
    if (follower) {
      dispatch(clearClassroomLogin())
    }
  }

  useEffect(() => {
    if (follower) {
      history('/studentclassrooms')
    }
  }, [history, follower])

  const backHandler = () => {
    dispatch({ type: CLASSROOM_LOGIN_RESET })
    dispatch({ type: FOLLOWER_CREATE_RESET })
  }

  return (
    <>
      <Modal show={props.showModal} onHide={props.handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>JOIN CLASSROOM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.classroomInfo && (
            <>
              <Row>
                <Col>
                  <Card className='my-3 p-3 rounded'>
                    <Card.Body>
                      <Card.Title as='div'>
                        <strong>{props.classroomInfo.title}</strong>
                      </Card.Title>

                      <Card.Text as='h3'>
                        {props.classroomInfo.teacher}
                      </Card.Text>
                      <Card.Text as='h4'>
                        {props.classroomInfo.description}
                      </Card.Text>
                      <Form.Group className='mb-3' controlId='priority'>
                        <Form.Label>
                          What name would you like to share with your teacher?
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder=''
                          value={studentName}
                          onChange={(e) => setStudentName(e.target.value)}
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    type='submit'
                    variant='primary'
                    onClick={joinClassHandler}
                  >
                    Join Class
                  </Button>
                  {followerError && (
                    <LinkContainer
                      to='/studentclassrooms'
                      className='btn btn-dark my-3'
                    >
                      <Button onClick={backHandler}> Go Back</Button>
                    </LinkContainer>
                  )}
                </Col>
              </Row>

              <Row></Row>
            </>
          )}
        </Modal.Body>
        {follower && <Message variant='success'>Success!</Message>}
        {followerError && <Message variant='danger'>{followerError}</Message>}
        {loadingFollower && <Loader />}
      </Modal>
    </>
  )
}

export default ModalClassroomLogin
