import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import classes from './NoDataImage.module.css'

const NoDataImage = (props) => {
  return (
    <div className={classes.container}>
      <div>
        <img className={classes.img} src={'/images/nodataimage.png'} />
      </div>
      <Row>
        <Col className={classes.letsGoButtonContainer}>
          <LinkContainer to={'/'}>
            <Button className={classes.letsGoButton}>Let's go!</Button>
          </LinkContainer>
        </Col>
      </Row>
    </div>
  )
}

export default NoDataImage
