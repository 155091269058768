import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import classes from './Dashboard.module.css'
import { Card } from 'react-bootstrap'

const GlobalAccuracyDonut = (props) => {
  const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])

  const [correctAnswers, setCorrectAnswers] = useState(0)
  const [totalAnswers, setTotalAnswers] = useState(0)
  const [totalAccuracy, setTotalAccuracy] = useState(0)

  const ref = useRef(null)
  const cache = useRef(props.data)
  const createPie = d3
    .pie()
    .value((d) => d.value)
    .sort(null)
  const createArc = d3
    .arc()
    .innerRadius(props.innerRadius)
    .outerRadius(props.outerRadius)
  const colors = d3.scaleOrdinal(d3.schemeCategory10)
  const format = d3.format('.2f')

  useEffect(() => {
    const data = createPie(props.data)
    const prevData = createPie(cache.current)
    const group = d3.select(ref.current)
    const groupWithData = group.selectAll('g.arc').data(data)

    groupWithData.exit().remove()

    const groupWithUpdate = groupWithData
      .enter()
      .append('g')
      .attr('class', 'arc')

    const path = groupWithUpdate
      .append('path')
      .merge(groupWithData.select('path.arc'))

    const arcTween = (d, i) => {
      const interpolator = d3.interpolate(prevData[i], d)

      return (t) => createArc(interpolator(t))
    }

    path
      .attr('class', 'arc')
      .attr('fill', (d, i) => myColor(i))
      .transition()
      .attrTween('d', arcTween)

    const text = groupWithUpdate
      .append('text')
      .merge(groupWithData.select('text'))

    // text
    //   .attr('text-anchor', 'middle')
    //   .attr('alignment-baseline', 'middle')
    //   .style('fill', 'white')
    //   .style('font-size', 10)
    //   .transition()
    //   .attr('transform', (d) => `translate(${createArc.centroid(d)})`)
    //   .tween('text', (d, i, nodes) => {
    //     const interpolator = d3.interpolate(prevData[i], d)

    //     return (t) => d3.select(nodes[i]).text(format(interpolator(t).value))
    //   })
    // const legendG = groupWithUpdate
    //   .selectAll('.legend')
    //   .data(data)
    //   .enter()
    //   .append('g')
    //   .attr('transform', function (d, i) {
    //     return 'translate(' + (props.width + 110) + ',' + (i * 15 + 20) + ')'
    //   })
    //   .attr('class', 'legend')

    // legendG
    //   .append('rect')
    //   .attr('width', 10)
    //   .attr('height', 10)
    //   .attr('fill', function (d, i) {
    //     return colors(i)
    //   })

    // legendG
    //   .append('text')
    //   .text(function (d) {
    //     return d.value + '  ' + d.data.emote
    //   })
    //   .style('font-size', 12)
    //   .attr('y', 10)
    //   .attr('x', 11)

    cache.current = props.data
  }, [props.data])

  useEffect(() => {
    if (props.data) {
      let donutData = props.data
      let total = donutData
        .map((item) => item.value)
        .reduce((prev, curr) => prev + curr, 0)
      setTotalAnswers(total)
      let correct = donutData.filter((item) => item.label === 'correct')
      if (correct.length) {
        setCorrectAnswers(correct[0].value)
      }
    }
  }, [props.data])

  useEffect(() => {
    setTotalAccuracy(((correctAnswers / totalAnswers) * 100).toFixed(2))
  }, [correctAnswers])

  return (
    <div>
      <Card className={classes.donutContainer}>
        <Card.Body>
          <div>
            <svg width={props.width} height={props.height}>
              <g
                ref={ref}
                transform={`translate(${props.outerRadius} ${props.outerRadius})`}
              />
            </svg>
          </div>
          <h2>{`Total Accuracy: ${totalAccuracy}%`}</h2>
        </Card.Body>
      </Card>
    </div>
  )
}

export default GlobalAccuracyDonut
