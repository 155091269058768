import React from 'react'
import classes from './LevelImage.module.css'

const LevelImage = (props) => {
  return (
    <div className={classes.container}>
      <div>
        <img className={classes.img} src={props.sourceImg} />
      </div>
    </div>
  )
}

export default LevelImage
