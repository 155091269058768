import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router'
import { QuestionMultipleChoice } from '../components/QuestionMultipleChoice'
import { QuestionFillBlank } from '../components/QuestionFillBlank'
import QuestionModuleDonut from '../components/QuizModuleDonut'
import { sum } from 'd3-array'
import { NOTEBOOK_CREATE_RESET } from '../constants/notebookConstants'
import { getMyQuestionRandom } from '../actions/myQuestionActions'
import {
  getNotebookLevelsList,
  getNotebookRandom,
} from '../actions/notebookActions'
import classes from './ModuleScreens.module.css'
import Loader from '../components/Loader'
import LevelBarCustom from '../components/LevelBarCustom'
import ScoreBar from '../components/ScoreBar'
import Message from '../components/Message'
import LevelBarNotebook from '../components/LevelBarNotebook'
import { QuestionConjugation } from '../components/QuestionConjugation'
import { QuestionMultipleChoiceDzongkha } from '../components/QuestionMultipleChoiceDzongkha'
import { QuestionMultipleChoiceMobile } from '../components/QuestionMultipleChoiceMobile'
import ScoreBarMobile from '../components/ScoreBarMobile'

const NotebookModuleScreen = () => {
  const dispatch = useDispatch()
  let history = useNavigate()
  const correctColor = '#2fb986'
  const incorrectColor = '#ff8d7a'
  const { id } = useParams()

  const notebookLevelsList = useSelector((state) => state.notebookLevelsList)
  const {
    loading: loadingLevelsList,
    error: errorLevelsList,
    levels: levelsList,
  } = notebookLevelsList

  const notebookFetchOne = useSelector((state) => state.notebookFetchOne)
  const {
    loading: loadingQuestion,
    error,
    question: questionData,
  } = notebookFetchOne

  const [isStarted, setIsStarted] = useState(true)
  const [levelChangePermit, setLevelChangePermit] = useState(false)
  const [scoreList, setScoreList] = useState([])
  const [attemptCount, setAttemptCount] = useState(0)
  const [levelLock, setLevelLock] = useState(false)
  const [shuffledAnswerList, setShuffledAnswerList] = useState([])
  const [totalScore, setTotalScore] = useState(null)
  const [correctAnswer, setCorrectAnswer] = useState('')
  const [levels, setLevels] = useState([1])
  const [currentLevelIndex, setCurrentLevelIndex] = useState(0)
  const [maxLevelIndex, setMaxLevelIndex] = useState(0)
  const [showFlagModal, setShowFlagModal] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [showNotebookModal, setShowNotebookModal] = useState(false)
  const handleFlagModalClose = () => setShowFlagModal(false)
  const handleFlagModalShow = () => setShowFlagModal(true)
  const handleNotebookModalClose = () => {
    setShowNotebookModal(false)
    dispatch({ type: NOTEBOOK_CREATE_RESET })
  }
  const handleNotebookModalShow = () => setShowNotebookModal(true)
  const [showDonut, setShowDonut] = useState(true)

  const filter = {
    level: levels[currentLevelIndex],
  }

  const data = [
    { date: 'incorrect', value: 100 - totalScore },
    { date: 'correct', value: totalScore },
  ]

  const shuffle = (arr) => arr.sort(() => Math.random() - 0.5)
  const myFunc = (total, num) => {
    return total + num
  }

  ////////////////////////////////////////////////////
  /////////WONDERFUL WORLD OF USE EFFECTS ////////////
  ////////////////////////////////////////////////////

  // Initialize Module Levels
  useEffect(() => {
    if (isStarted === true) {
      // dispatch(getLowestLevel(id))
      dispatch(getNotebookLevelsList())
      dispatch(getNotebookRandom(filter))
      setIsStarted(false)
    }
  }, [isStarted])
  useEffect(() => {
    window.screen.width <= 760 ? setIsMobile(true) : setIsMobile(false)
  }, [window.screen.width])

  function detectWindowSize() {
    window.innerWidth <= 760 ? setIsMobile(true) : setIsMobile(false)
  }

  window.onresize = detectWindowSize
  useEffect(() => {
    if (levelsList) {
      setLevels(levelsList)
      setMaxLevelIndex(levelsList.length - 1)
    }
  }, [notebookLevelsList])

  // Set Total Score
  useEffect(() => {
    if (scoreList.length > 0) {
      setTotalScore(100 * (sum(scoreList.slice(-5)) / 5))
    }
  }, [scoreList])

  // Shuffled Answers
  useEffect(() => {
    if (questionData.answers) {
      if (questionData.answers.length > 0) {
        setShuffledAnswerList(shuffle([...questionData.answers]))
      }
    }
  }, [questionData])

  // Correct Answers
  useEffect(() => {
    if (shuffledAnswerList.length > 0) {
      let answerFilter = shuffledAnswerList.filter(
        ({ isCorrect, text }) => isCorrect === true
      )
      if (answerFilter.length > 0) {
        setCorrectAnswer(answerFilter[0]['text'])
      }
    }
  }, [shuffledAnswerList, questionData])

  // Manage Levels
  useEffect(() => {
    if (
      scoreList.length === 5 &&
      currentLevelIndex !== maxLevelIndex &&
      scoreList.slice(-1)[0] === 1 &&
      scoreList.slice(-5).reduce((accumulator, curr) => accumulator + curr) ===
        5 &&
      levelLock !== true
    ) {
      setScoreList([])
      setCurrentLevelIndex(currentLevelIndex + 1)
      // setShowLevelModal(true)
      setTotalScore(0)
    }
    if (
      scoreList.length > 0 &&
      currentLevelIndex !== 0 &&
      scoreList.slice(-2).reduce((accumulator, curr) => accumulator + curr) ===
        0 &&
      scoreList.slice(-5).reduce((accumulator, curr) => accumulator + curr) <=
        2 &&
      levelLock !== true
    ) {
      setCurrentLevelIndex(currentLevelIndex)
    }
  }, [scoreList])

  // Parlay Question Data
  useEffect(() => {
    if (questionData.answers) {
      for (let i = 1; i <= questionData.answers.length; i++) {
        if (document.getElementById(i) !== null) {
          document.getElementById(i).disabled = false
          document.getElementById(i).style.backgroundColor = '#4C4A47'
        }
      }
    }
  }, [questionData])

  ////////////////////////////////////////////////////
  /////////WONDERFUL WORLD OF BUTTON HANDLERS ////////
  ////////////////////////////////////////////////////

  // Handle Hot Keys
  const handleKeyPress = (event) => {
    if (
      event.key == 'Enter' &&
      document.getElementById('nextButton').disabled === false
    ) {
      document.getElementById('nextButton').click()
    } else {
      if (document.getElementById(event.key) !== null) {
        if (document.getElementById(event.key).value) {
          document.getElementById(event.key).click()
          // console.log('Click info', document.getElementById(event.key).value)
        } else {
          document.getElementById(event.key).click()
          // console.log('Click info', document.getElementById(event.key).value)
        }
      }
    }
  }

  const answerButtonHandler = (event) => {
    setAttemptCount(attemptCount + 1)
    if (attemptCount === 10) {
      setAttemptCount(0)
      setLevelChangePermit(true)
    } else {
      setLevelChangePermit(false)
    }
    if (event.target.value === correctAnswer) {
      setScoreList((scoreList) => [...scoreList, 1].slice(-100))
      document.getElementById(event.target.id).style.backgroundColor = 'green'
      document.getElementById('overDiv').focus()
      for (let i = 1; i <= questionData.answers.length; i++) {
        if (document.getElementById(i) !== null) {
          document.getElementById(i).disabled = true
        }
      }
      document.getElementById('nextButton').disabled = false
      document.getElementById('skipButton').disabled = true
      if (document.getElementById('giveUpButton') != null) {
        document.getElementById('giveUpButton').disabled = true
      }
    } else {
      document.getElementById(event.target.id).style.backgroundColor = 'red'
      document.getElementById(event.target.id).disabled = true
      setScoreList((scoreList) => [...scoreList, 0].slice(-100))
    }
  }

  // Skip Button Handler
  const skipButtonHandler = (event) => {
    setScoreList((scoreList) => [...scoreList, 0].slice(-10))

    if (document.getElementById('mainForm')) {
      document.getElementById('mainForm').focus()
    }
    dispatch(getNotebookRandom(filter))
  }

  // Next Button Handler
  const nextButtonHandler = (event) => {
    if (document.getElementById('mainForm')) {
      document.getElementById('mainForm').focus()
    }
    dispatch(getNotebookRandom(filter))
    document.getElementById('nextButton').disabled = true
    document.getElementById('skipButton').disabled = false
    document.getElementById('giveUpButton').disabled = true
  }

  const exitButtonHandler = (event) => {
    history('/')
  }

  ////////////////////////////////////////////////////
  /////////WONDERFUL WORLD OF REACT COMPONENTS ////////////
  ////////////////////////////////////////////////////

  const otherButtons = (
    <div>
      <button className={classes.exitButton} onClick={exitButtonHandler}>
        Exit
      </button>
      <button
        className={classes.skipButton}
        id={'skipButton'}
        onClick={skipButtonHandler}
      >
        Skip
      </button>
      <button
        className={classes.nextButton}
        id={'nextButton'}
        onClick={nextButtonHandler}
      >
        Next
      </button>
    </div>
  )

  const questionBox = (
    <div>
      <Row>
        <Col>
          {questionData.questionType === 'multiple_choice' ? (
            isMobile ? (
              <QuestionMultipleChoiceMobile
                questionData={questionData}
                correctAnswer={correctAnswer}
                loadingQuestion={loadingQuestion}
                // buttonTextSize={buttonTextSize}
                answerButtonHandler={answerButtonHandler}
                shuffledAnswerList={shuffledAnswerList}
                setScoreList={setScoreList}
                correctColor={correctColor}
              />
            ) : questionData.targetLanguage == 'dzongkha' ? (
              <QuestionMultipleChoiceDzongkha
                questionData={questionData}
                correctAnswer={correctAnswer}
                loadingQuestion={loadingQuestion}
                // buttonTextSize={buttonTextSize}
                answerButtonHandler={answerButtonHandler}
                shuffledAnswerList={shuffledAnswerList}
                setScoreList={setScoreList}
                correctColor={correctColor}
              />
            ) : (
              <QuestionMultipleChoice
                questionData={questionData}
                correctAnswer={correctAnswer}
                loadingQuestion={loadingQuestion}
                // buttonTextSize={buttonTextSize}
                answerButtonHandler={answerButtonHandler}
                shuffledAnswerList={shuffledAnswerList}
                setScoreList={setScoreList}
                correctColor={correctColor}
              />
            )
          ) : questionData.questionType === 'fill_in_the_blank' ? (
            <QuestionFillBlank
              questionData={questionData}
              loadingQuestion={loadingQuestion}
              targetLanguage={questionData.targetLanguage}
              setScoreList={setScoreList}
              isMobile={isMobile}
            />
          ) : questionData.questionType === 'conjugation' ? (
            <QuestionConjugation
              questionData={questionData}
              loadingQuestion={loadingQuestion}
              targetLanguage={questionData.targetLanguage}
              setScoreList={setScoreList}
              isMobile={isMobile}
            />
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
    </div>
  )

  const moduleLayout = (
    <>
      <div
        id={'overDiv'}
        className={classes.div}
        onKeyDown={handleKeyPress}
        tabIndex={'0'}
      >
        <LevelBarNotebook
          maxLevelIndex={maxLevelIndex}
          levels={levels}
          currentLevelIndex={currentLevelIndex}
          setCurrentLevelIndex={setCurrentLevelIndex}
          setScoreList={setScoreList}
          setTotalScore={setTotalScore}
          id={id}
          filter={filter}
          setLevelLock={setLevelLock}
        />
        <div>
          <Row>
            <Col>
              {isMobile ? (
                <ScoreBarMobile scoreList={scoreList} />
              ) : (
                <ScoreBar scoreList={scoreList} />
              )}
            </Col>
          </Row>
        </div>

        <Row>
          <Col>
            <div>
              <h2 className={classes.languageLabel}>{'Your Notebook'}</h2>
            </div>
            <div>
              <h2 className={classes.levelLabel2}>
                Level {questionData.level}
              </h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{questionBox}</Col>
        </Row>
        <div className={classes.otherButtonsContainer}>{otherButtons}</div>
        <div className={classes.donutdiv}>
          <QuestionModuleDonut
            data={data}
            width={40}
            height={40}
            innerRadius={10}
            outerRadius={20}
          />
        </div>
      </div>
    </>
  )

  return (
    <div className={classes.container}>
      {loadingLevelsList ? (
        <Loader />
      ) : currentLevelIndex != null ? (
        moduleLayout
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default NotebookModuleScreen
