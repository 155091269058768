import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import classes from './SampleDashboardImage.module.css'

const SampleDashboardImage = (props) => {
  return (
    <div className={classes.container}>
      <div>
        <img className={classes.img} src={'/images/dashboardsample.png'} />
      </div>
    </div>
  )
}

export default SampleDashboardImage
