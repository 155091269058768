import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Card } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listClassroomDetails } from '../actions/classroomActions'
import { FETCH_CLASSROOM_DETAILS_RESET } from '../constants/classroomConstants'
import classes from './ClassroomScreens.module.css'
import ForthcomingBox from '../components/ForthcomingBox'
import {
  fetchMyDashboardAnswerCount,
  fetchMyDashboardDonutData,
  fetchMyDashboardQuestionCount,
} from '../actions/myDashboardActions'
import GlobalAccuracyDonut from '../dashboardComponents/GlobalAccuracyDonut'
import { GlobalTotalBox } from '../dashboardComponents/GlobalTotalBox'
import { initializeCustomModule } from '../actions/startActions'
import { getUserDetails } from '../actions/userActions'

const ClassroomStudentScreen = () => {
  let history = useNavigate()

  const { id } = useParams()

  const [classroomCode, setClassroomCode] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [teacher, setTeacher] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const classroomDetails = useSelector((state) => state.classroomDetails)
  const { loading, error, classroom } = classroomDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const classroomUpdate = useSelector((state) => state.classroomUpdate)
  const { success } = classroomUpdate

  const initializeCustom = useSelector(
    (state) => state.initializeCustom.initializeParameters
  )

  //const { userLanguage, targetLanguage, level } = initializeCustom

  const userDetails = useSelector((state) => state.userDetails)
  const {
    loading: loadingUserDetails,
    error: errorUserDetails,
    user,
  } = userDetails

  const filter = { id: id }

  // Answer Count - Global
  const myDashboardAnswerCount = useSelector(
    (state) => state.myDashboardAnswerCount
  )
  const {
    loading: loadingAnswerCount,
    error: errorAnswerCount,
    answerCount,
  } = myDashboardAnswerCount

  // Question Count - Global
  const myDashboardQuestionCount = useSelector(
    (state) => state.myDashboardQuestionCount
  )
  const {
    loading: loadingQuestionCount,
    error: errorQuestionCount,
    questionCount,
  } = myDashboardQuestionCount

  // Accuracy Donut - Global
  const myDashboardDonutData = useSelector(
    (state) => state.myDashboardDonutData
  )
  const {
    loading: loadingAccuracyDonut,
    error: errorAccuracyDonut,
    donutData,
  } = myDashboardDonutData

  useEffect(() => {
    if (userLogin) {
      dispatch(fetchMyDashboardQuestionCount(filter))
      dispatch(fetchMyDashboardAnswerCount(filter))
      dispatch(fetchMyDashboardDonutData(filter))
    }
  }, [userLogin])

  useEffect(() => {
    if (!userInfo) {
      history('/login')
    } else {
      if (!classroom || success) {
        dispatch(listClassroomDetails(id))
      } else {
        setClassroomCode(classroom.classroomCode)
        setName(classroom.name)
        setDescription(classroom.description)
        setTeacher(classroom.teacher)
      }
    }
  }, [dispatch, history, userInfo, classroom, success])

  const clickHandler = () => {
    dispatch({ type: FETCH_CLASSROOM_DETAILS_RESET })
  }

  const startButtonHandler = (e) => {
    if (!userInfo || !user.customLevels[id]) {
      dispatch(initializeCustomModule(id, 0))
    } else {
      dispatch(initializeCustomModule(id, user.customLevels[id]))
    }
  }

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserDetails('profile'))
    }
  }, [history, dispatch, userInfo, initializeCustom])

  const dashboard = (
    <div className={classes.chartContainer}>
      <Row>
        <Col>
          {loadingQuestionCount ? (
            <Loader />
          ) : questionCount ? (
            <GlobalTotalBox
              data={questionCount}
              message={'Total Unique Questions Answered'}
            />
          ) : (
            <h1>Data Not Found</h1>
          )}
        </Col>
        <Col>
          {loadingAnswerCount ? (
            <Loader />
          ) : answerCount ? (
            <GlobalTotalBox data={answerCount} message={'Total Answers'} />
          ) : (
            <h1>Data Not Found</h1>
          )}
        </Col>
        <Col>
          {loadingAccuracyDonut ? (
            <Loader />
          ) : donutData ? (
            <>
              <GlobalAccuracyDonut
                data={donutData}
                width={100}
                height={100}
                innerRadius={30}
                outerRadius={50}
              />
            </>
          ) : (
            <h1>Data Not Found</h1>
          )}
        </Col>
      </Row>
    </div>
  )

  const otherMessage = (
    <>
      <Row className='py-3'>
        <Col>
          <Card>
            <Card.Body>
              <ForthcomingBox
                message={'Answer at least 5 questions to enable your dashboard'}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )

  return (
    <div className={classes.container}>
      <Row className='py-3'>
        <Col>
          <LinkContainer to='/studentclassrooms/'>
            <Button type='btn' variant='primary' onClick={clickHandler}>
              Back to Classrooms
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      <Row className='py-3'>
        <Col>
          {message && <Message variant='danger'>{message}</Message>}
          {}
          {success && <Message variant='success'>Classroom Updated</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Card>
              <Card.Body>
                <Card.Title>{classroomCode}</Card.Title>
                <Card.Title>{name}</Card.Title>
                <Card.Text>{teacher}</Card.Text>
                <Card.Text>{description}</Card.Text>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      {loadingQuestionCount ? (
        <Loader />
      ) : questionCount ? (
        dashboard
      ) : (
        otherMessage
      )}
      <Row className='py-3'>
        <Col>
          <LinkContainer to={`/studentclassrooms/module/${id}`}>
            <Card
              onClick={startButtonHandler}
              className={classes.letsStudyButton}
            >
              <Card.Body>
                <h1>Let's study!</h1>
              </Card.Body>
            </Card>
          </LinkContainer>
        </Col>
      </Row>
    </div>
  )
}

export default ClassroomStudentScreen
