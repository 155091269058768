import React from 'react'
import { Card } from 'react-bootstrap'
import classes from './Dashboard.module.css'
export const GlobalTotalBox = (props) => {
  return (
    <div>
      <Card className={classes.countContainer}>
        <Card.Body>
          <Card.Title>{props.message}</Card.Title>
          <h1>{props.data}</h1>
        </Card.Body>
      </Card>
    </div>
  )
}
