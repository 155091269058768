import {
  NOTEBOOK_CREATE_FAIL,
  NOTEBOOK_CREATE_REQUEST,
  NOTEBOOK_CREATE_RESET,
  NOTEBOOK_CREATE_SUCCESS,
  NOTEBOOK_DELETE_BULK_FAIL,
  NOTEBOOK_DELETE_BULK_REQUEST,
  NOTEBOOK_DELETE_BULK_SUCCESS,
  NOTEBOOK_DELETE_FAIL,
  NOTEBOOK_DELETE_REQUEST,
  NOTEBOOK_DELETE_SUCCESS,
  NOTEBOOK_DETAILS_FAIL,
  NOTEBOOK_DETAILS_REQUEST,
  NOTEBOOK_DETAILS_SUCCESS,
  NOTEBOOK_FETCH_ONE_FAIL,
  NOTEBOOK_FETCH_ONE_REQUEST,
  NOTEBOOK_FETCH_ONE_RESET,
  NOTEBOOK_FETCH_ONE_SUCCESS,
  NOTEBOOK_LEVEL_OPTIONS_FAIL,
  NOTEBOOK_LEVEL_OPTIONS_REQUEST,
  NOTEBOOK_LEVEL_OPTIONS_SUCCESS,
  NOTEBOOK_LIST_MY_FAIL,
  NOTEBOOK_LIST_MY_REQUEST,
  NOTEBOOK_LIST_MY_RESET,
  NOTEBOOK_LIST_MY_SUCCESS,
  NOTEBOOK_UPDATE_FAIL,
  NOTEBOOK_UPDATE_REQUEST,
  NOTEBOOK_UPDATE_RESET,
  NOTEBOOK_UPDATE_SUCCESS,
} from '../constants/notebookConstants'

export const notebookCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTEBOOK_CREATE_REQUEST:
      return {
        loading: true,
      }
    case NOTEBOOK_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        notebook: action.payload,
      }
    case NOTEBOOK_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case NOTEBOOK_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const notebookListMyReducer = (state = { notebooks: [] }, action) => {
  switch (action.type) {
    case NOTEBOOK_LIST_MY_REQUEST:
      return {
        loading: true,
      }
    case NOTEBOOK_LIST_MY_SUCCESS:
      return {
        loading: false,
        notebooks: action.payload.questions,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case NOTEBOOK_LIST_MY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case NOTEBOOK_LIST_MY_RESET:
      return { notebooks: [] }
    default:
      return state
  }
}

export const notebookDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTEBOOK_DELETE_REQUEST:
      return { loading: true }
    case NOTEBOOK_DELETE_SUCCESS:
      return { loading: false, success: true }
    case NOTEBOOK_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const notebookDeleteBulkReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTEBOOK_DELETE_BULK_REQUEST:
      return { loading: true }
    case NOTEBOOK_DELETE_BULK_SUCCESS:
      return { loading: false, success: true }
    case NOTEBOOK_DELETE_BULK_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const notebooksLevelsListReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTEBOOK_LEVEL_OPTIONS_REQUEST:
      return { loading: true }
    case NOTEBOOK_LEVEL_OPTIONS_SUCCESS:
      return {
        loading: false,
        levels: action.payload,
      }
    case NOTEBOOK_LEVEL_OPTIONS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const notebooksFetchOneReducer = (state = { question: {} }, action) => {
  switch (action.type) {
    case NOTEBOOK_FETCH_ONE_REQUEST:
      return { ...state, loading: true }
    case NOTEBOOK_FETCH_ONE_SUCCESS:
      return {
        loading: false,
        question: action.payload,
      }
    case NOTEBOOK_FETCH_ONE_FAIL:
      return { loading: false, error: action.payload }
    case NOTEBOOK_FETCH_ONE_RESET:
      return {}
    default:
      return state
  }
}

export const notebookUpdateReducer = (state = { notebook: {} }, action) => {
  switch (action.type) {
    case NOTEBOOK_UPDATE_REQUEST:
      return { loading: true }
    case NOTEBOOK_UPDATE_SUCCESS:
      return { loading: false, success: true, notebook: action.payload }
    case NOTEBOOK_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case NOTEBOOK_UPDATE_RESET:
      return { notebook: {} }
    default:
      return state
  }
}

export const notebookDetailsReducer = (state = { notebook: {} }, action) => {
  switch (action.type) {
    case NOTEBOOK_DETAILS_REQUEST:
      return { ...state, loading: true }
    case NOTEBOOK_DETAILS_SUCCESS:
      return { loading: false, notebook: action.payload }
    case NOTEBOOK_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
