import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  deleteFollower,
  listMyFollowedClassrooms,
} from '../actions/followerActions'
import ClassroomBox from '../components/ClassroomBox'
import classes from './ListScreen.module.css'
import SuggestionBird from '../components/SuggestionBird'
import JoinClassroomImage from '../components/JoinClassroomImage'

const ClassroomListStudentScreen = () => {
  let history = useNavigate()
  let location = useLocation()

  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const followerGetClassrooms = useSelector(
    (state) => state.followerGetClassrooms
  )
  const {
    loading: loadingFollowerGetClassrooms,
    error: errorFollowerGetClassrooms,
    classrooms,
  } = followerGetClassrooms

  const followerDelete = useSelector((state) => state.followerDelete)
  const {
    loading: loadingFollowerDelete,
    error: errorFollowerDelete,
    success: followerDeleteSuccess,
  } = followerDelete

  useEffect(() => {
    if (!userInfo) {
      history('/login')
    } else if (!classrooms) {
      dispatch(listMyFollowedClassrooms())
    }
  }, [dispatch, history, userInfo, classrooms, followerDeleteSuccess])

  useEffect(() => {
    dispatch(listMyFollowedClassrooms())
  }, [followerDeleteSuccess])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure you want to leave this classroom?')) {
      dispatch(deleteFollower(id))
    }
  }

  const clickHandler = () => {}

  return (
    <div className={classes.container}>
      <Row>
        <Col>
          <Row className='align-items-center'>
            <Col>
              <h1 className={classes.headerText}>MY CLASSROOMS</h1>
            </Col>
            <Col className='text-right'>
              <LinkContainer to={`/joinclassroom`}>
                <Button
                  variant='dark'
                  className={classes.createClassroomButton}
                >
                  <i className='fas fa-plus'></i> Join a classroom
                </Button>
              </LinkContainer>
            </Col>
          </Row>

          {loadingFollowerGetClassrooms || loadingFollowerDelete ? (
            <Loader />
          ) : errorFollowerGetClassrooms || errorFollowerDelete ? (
            <Message variant='danger'>{errorFollowerGetClassrooms}</Message>
          ) : classrooms.length ? (
            <>
              <Row>
                {classrooms.map(
                  (classroom) =>
                    classroom.classroom != null && (
                      <Col key={classroom._id} sm={12} md={6} lg={4} xl={3}>
                        <ClassroomBox classroom={classroom} />
                        <Button
                          className={classes.deleteButton}
                          variant='danger'
                          onClick={() => deleteHandler(classroom._id)}
                        >
                          LEAVE CLASSROOM
                        </Button>
                      </Col>
                    )
                )}
              </Row>
            </>
          ) : (
            <JoinClassroomImage />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default ClassroomListStudentScreen
