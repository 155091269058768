import React, { useEffect, useState } from 'react'
import classes from './LevelBar.module.css'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { getMyQuestionRandom } from '../actions/myQuestionActions'
import { getNotebookRandom } from '../actions/notebookActions'

const LevelBarNotebook = (props) => {
  let dispatch = useDispatch()

  const [levelList, setLevelList] = useState([])
  const [orderedLevels, setOrderedLevels] = useState([])

  const changeLevelHandler = (e) => {
    dispatch(getNotebookRandom({ level: orderedLevels[e.target.value] }))
    props.setScoreList([])
    props.setCurrentLevelIndex(Number(e.target.value))
    props.setTotalScore(0)
  }

  useEffect(() => {
    setOrderedLevels(
      props.levels.sort(function (a, b) {
        return a - b
      })
    )
  }, [props.levels])

  useEffect(() => {
    setLevelList(
      Array.from('1'.repeat(props.maxLevelIndex + 1)).concat(
        Array.from('0'.repeat(props.levels.length - (props.maxLevelIndex + 1)))
      )
    )
  }, [props.maxLevelIndex, props.levels])

  return (
    <div className={classes.levelBarContainer}>
      {levelList.map((level, index) =>
        Number(index) == Number(props.currentLevelIndex) ? (
          <Button
            className={classes.currentLevelButton}
            key={`levelButton${index + 1}`}
            value={index + 1}
            onClick={changeLevelHandler}
          ></Button>
        ) : Number(level) > 0 ? (
          <Button
            className={classes.levelButton}
            key={`levelButton${index + 1}`}
            value={index}
            onClick={changeLevelHandler}
          >
            {/* {props.levels[index]} */}
          </Button>
        ) : (
          <Button
            className={classes.levelButton}
            key={`levelButton${index + 1}`}
            value={index}
            disabled={true}
            onClick={changeLevelHandler}
          ></Button>
        )
      )}
    </div>
  )
}

export default LevelBarNotebook
