import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Form, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  createMyQuestion,
  deleteMyQuestion,
  deleteMyQuestionBulk,
  filterMyQuestions,
  updateMyQuestionAddClassrooms,
} from '../actions/myQuestionActions'
import { listMyClassrooms } from '../actions/classroomActions'
import { MY_QUESTION_CREATE_RESET } from '../constants/myQuestionConstants'
import SearchBoxMyQuestions from '../components/SearchBoxMyQuestions'
import PaginateMyQuestions from '../components/PaginateMyQuestions'
import {
  dropdown_level_options,
  dropdown_question_type_options,
  dropdown_target_options,
  dropdown_text_type_options,
  dropdown_user_options,
} from '../constants/dropdownConstants'
import classes from './ListScreen.module.css'
import SuggestionBird from '../components/SuggestionBird'
import ClassroomImage from '../components/ClassroomImage'
import MyQuestionImage from '../components/MyQuestionImage'

const MyQuestionsListScreen = () => {
  const params = useParams()
  const pageNumber = params.pageNumber || 1
  const { keyword } = useParams()

  const [level, setLevel] = useState('')
  const [userLanguage, setUserLanguage] = useState('')
  const [targetLanguage, setTargetLanguage] = useState('')
  const [textType, setTextType] = useState('')
  const [questionType, setQuestionType] = useState('')
  const [classroomOptions, setClassroomOptions] = useState([
    { title: 'no classrooms', value: 'none' },
  ])
  const [selectAll, setSelectAll] = useState([])
  const [classroom, setClassroom] = useState('')
  const [questionList, setQuestionList] = useState([])
  const [showFilters, setShowFilters] = useState(false)

  let history = useNavigate()
  const dispatch = useDispatch()

  const filter = {
    level: level,
    userLanguage: userLanguage,
    targetLanguage: targetLanguage,
    textType: textType,
    questionType: questionType,
  }

  const classroomsListMy = useSelector((state) => state.classroomsListMy)
  const {
    loading: classroomsLoading,
    error: classroomsError,
    classrooms,
  } = classroomsListMy

  const myQuestionFilter = useSelector((state) => state.myQuestionFilter)
  const { loading, error, questions, page, pages } = myQuestionFilter

  const myQuestionAddClassroom = useSelector(
    (state) => state.myQuestionAddClassroom
  )
  const { success: successAddQuestions } = myQuestionAddClassroom

  const myQuestionDelete = useSelector((state) => state.myQuestionDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = myQuestionDelete

  const myQuestionDeleteBulk = useSelector(
    (state) => state.myQuestionDeleteBulk
  )

  const {
    loading: loadingDeleteBulk,
    error: errorDeleteBulk,
    success: successDeleteBulk,
  } = myQuestionDeleteBulk

  const myQuestionCreate = useSelector((state) => state.myQuestionCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    question: createdQuestion,
  } = myQuestionCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: MY_QUESTION_CREATE_RESET })

    if (!userInfo) {
      history('/login')
    }

    if (successCreate) {
      history(`/myquestions/${createdQuestion._id}/edit`)
    } else {
      dispatch(filterMyQuestions(filter, keyword, pageNumber))
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successDeleteBulk,
    successCreate,
    params,
  ])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [questions])

  useEffect(() => {
    if (!userInfo) {
      history('/login')
    } else {
      dispatch(filterMyQuestions(filter, '', pageNumber))
      dispatch(listMyClassrooms('', ''))
    }
  }, [history, dispatch, userInfo, pageNumber])

  useEffect(() => {
    if (!userInfo) {
      history('/login')
    } else {
      history(
        keyword
          ? `/myquestions/search/${keyword}/page/${1}`
          : `/myquestions/page/${1}`
      )
    }
  }, [level, targetLanguage, userLanguage, questionType, textType])

  useEffect(() => {
    if (classrooms) {
      setClassroomOptions(classrooms)
    }
  }, [history, dispatch, classrooms])

  useEffect(() => {
    if (questions) {
      setSelectAll(
        Array.apply(false, Array(questions.length)).map(function (x, i) {
          return false
        })
      )
    }
  }, [questions, successAddQuestions])

  useEffect(() => {
    if (questions) {
      let ids = questions.map((question, index) => question._id)
      let selected = selectAll
      let questionIdList = ids.filter((item, index) => selected[index] && item)
      setQuestionList(questionIdList)
    }
  }, [selectAll])

  useEffect(() => {
    if (classroomOptions.length > 0) {
      setClassroom(classroomOptions[0]._id)
    }
  }, [classrooms])

  /// OTHER BUTTON HANDLERS

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteMyQuestion(id))
    }
  }

  const deleteBulkHandler = () => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteMyQuestionBulk({ questions: questionList }))
    }
  }

  const checkboxHandler = (e) => {
    if (e) {
      let newSelectAll = [...selectAll]
      if (e.target.checked === true) {
        newSelectAll[Number(e.target.value)] = true
        setSelectAll(newSelectAll)
      } else {
        newSelectAll[Number(e.target.value)] = false
        setSelectAll(newSelectAll)
      }
    }
  }

  const submitUpdateHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateMyQuestionAddClassrooms({
        classroom: classroom,
        questions: questionList,
      })
    )
  }

  const selectAllHandler = (e) => {
    if (e.target.checked === true) {
      setSelectAll(
        selectAll.map(function (x, i) {
          return true
        })
      )
    } else if (e.target.checked === false) {
      setSelectAll(
        selectAll.map(function (x, i) {
          return false
        })
      )
    }
  }

  const createQuestionHandler = () => {
    dispatch(createMyQuestion())
  }

  const filterHandler = () => {
    setShowFilters(!showFilters)
  }

  return (
    <div className={classes.container}>
      <div>
        <Row className='align-items-center'>
          <Col className='text-right'>
            <Button
              className={classes.createQuestionButton}
              onClick={createQuestionHandler}
            >
              <i className='fas fa-plus'></i> Create Question
            </Button>
          </Col>
        </Row>
      </div>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : questions.length ? (
        <>
          <div className={classes.questionActionsContainer}>
            <Col>
              <h1 className={classes.headerText}>MY QUESTIONS</h1>
            </Col>
            <Row>
              {/* <Col>
                <div>
                  <Button
                    className={classes.showFiltersButton}
                    onClick={filterHandler}
                  >
                    Show Filters
                  </Button>
                </div>
              </Col> */}

              <Col>
                <div>
                  <h3 className={classes.subTitle}>Choose a classroom:</h3>
                  <Form.Select
                    className={classes.dropdownContainer}
                    onChange={(e) => setClassroom(e.target.value)}
                    defaultValue={
                      classroomOptions.length > 0
                        ? classroomOptions[0]._id
                        : null
                    }
                  >
                    {classroomOptions.map((classroom, index) => (
                      <option
                        key={`classroomoption${index}`}
                        href='#'
                        value={classroom._id}
                      >
                        {classroom.classroomCode}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
              <Col>
                <Button
                  type='submit'
                  className={classes.addQuestionsButton}
                  onClick={submitUpdateHandler}
                  disabled={selectAll.filter(Boolean).length > 0 ? false : true}
                >
                  <i className='fas fa-plus'></i> Add selected questions to
                  classroom
                </Button>
                {successAddQuestions && (
                  <Message variant='success'>
                    Questions Successfully Added to Classroom!
                  </Message>
                )}
              </Col>
              <Col>
                <Button
                  className={classes.deleteQuestionsButton}
                  onClick={deleteBulkHandler}
                  disabled={selectAll.filter(Boolean).length > 0 ? false : true}
                >
                  Delete Selected Questions
                </Button>
              </Col>
            </Row>
          </div>
          <div>
            <div className={classes.filterContainer}>
              {/* {showFilters && (
                <div>
                  <Row>
                    <Col>
                      <Card className={classes.filterCard}>
                        <h4>Filter Questions</h4>
                        <div>
                          <Form.Label>User Language:</Form.Label>
                          <Form.Select
                            controlId='formUser'
                            onChange={(e) => setUserLanguage(e.target.value)}
                          >
                            {dropdown_user_options.map((option) => (
                              <option
                                href='#'
                                value={option.value}
                                key={option.value}
                              >
                                {option.label}
                              </option>
                            ))}
                          </Form.Select>
                        </div>

                        <div>
                          <Form.Label>Target Language:</Form.Label>
                          <Form.Select
                            controlId='formTarget'
                            onChange={(e) => setTargetLanguage(e.target.value)}
                          >
                            {dropdown_target_options.map((option) => (
                              <option
                                href='#'
                                value={option.value}
                                key={option.value}
                              >
                                {option.label}
                              </option>
                            ))}
                          </Form.Select>
                        </div>

                        <div>
                          <Form.Label>Level:</Form.Label>
                          <Form.Select
                            controlId='formLevel'
                            onChange={(e) => setLevel(e.target.value)}
                          >
                            {dropdown_level_options.map((option) => (
                              <option
                                href='#'
                                value={option.value}
                                key={option.value}
                              >
                                {option.label}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                        <div>
                          <Form.Label>Question Type:</Form.Label>
                          <Form.Select
                            controlId='formType'
                            onChange={(e) => setQuestionType(e.target.value)}
                          >
                            {dropdown_question_type_options.map((option) => (
                              <option
                                href='#'
                                value={option.value}
                                key={option.value}
                              >
                                {option.label}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                        <div>
                          <Form.Label>Text Type:</Form.Label>
                          <Form.Select
                            controlId='formText'
                            onChange={(e) => setTextType(e.target.value)}
                          >
                            {dropdown_text_type_options.map((option) => (
                              <option
                                href='#'
                                value={option.value}
                                key={option.value}
                              >
                                {option.label}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                        <div>
                          <SearchBoxMyQuestions />
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )} */}

              {loadingDelete && <Loader />}
              {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
              {loading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'>{error}</Message>
              ) : (
                <Card className={classes.tableCard}>
                  <Table striped bordered hover responsive className='table-sm'>
                    <thead>
                      <tr>
                        <th>USER_LANG</th>
                        <th>TARGET_LANG</th>
                        <th>LEVEL</th>
                        <th>QUESTION TEXT</th>
                        <th>TEXT TYPE</th>
                        <th>QUESTION TYPE</th>
                        <th>
                          {' '}
                          <Form>
                            <Form.Group
                              className='mb-3'
                              controlId={`selectallcheckbox`}
                            >
                              <Form.Check
                                type='checkbox'
                                label='Select All'
                                onChange={selectAllHandler}
                              />
                            </Form.Group>
                          </Form>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {questions.map((question, index) => (
                        <tr key={question._id}>
                          <td>{question.userLanguage}</td>
                          <td>{question.targetLanguage}</td>
                          <td>{question.level}</td>
                          <td>{question.question}</td>
                          <td>{question.textType}</td>
                          <td>{question.questionType}</td>
                          <td>
                            <Form controlid={`checkform-${index}`}>
                              <Form.Group
                                className='mb-3'
                                controlId={`checkbox-${index}`}
                              >
                                <Form.Check
                                  type='checkbox'
                                  label=''
                                  checked={selectAll[index]}
                                  value={index}
                                  onChange={checkboxHandler}
                                />
                              </Form.Group>
                            </Form>
                          </td>
                          <td>
                            <LinkContainer
                              to={`/myquestions/${question._id}/edit`}
                            >
                              <Button variant='light' className='btn-sm'>
                                <i className='fas fa-edit'></i>
                              </Button>
                            </LinkContainer>
                            <Button
                              variant='danger'
                              className='btn-sm'
                              onClick={() => deleteHandler(question._id)}
                            >
                              <i className='fas fa-trash'></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className={classes.paginationContainer}>
                    <PaginateMyQuestions
                      pages={pages}
                      page={page}
                      isAdmin={true}
                    />
                  </div>
                </Card>
              )}
            </div>
          </div>
        </>
      ) : (
        <MyQuestionImage />
      )}
    </div>
  )
}

export default MyQuestionsListScreen
