import React from 'react'
import { Card } from 'react-bootstrap'
import classes from './ClassroomBox.module.css'
import { LinkContainer } from 'react-router-bootstrap'

const ClassroomBox = ({ classroom }) => {
  return (
    <LinkContainer to={`/studentclassrooms/${classroom.classroom._id}`}>
      <Card className={classes.classroomCard}>
        <Card.Body>
          <Card.Title as='div'>
            <strong>{classroom.classroom.name}</strong>
          </Card.Title>
          <Card.Title as='div'>
            <h2>{classroom.classroom.classroomCode}</h2>
          </Card.Title>
          <Card.Text as='div'>{classroom.classroom.teacher}</Card.Text>
          <Card.Text as='div'>{classroom.classroom.description}</Card.Text>
        </Card.Body>
      </Card>
    </LinkContainer>
  )
}

export default ClassroomBox
