import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import classes from './Dashboard.module.css'
import debounce from 'lodash/debounce'

const GlobalBarchart = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null)
  const containerRef = useRef(null)

  const margin = { top: 90, right: 50, bottom: 50, left: 50 },
    width = (props.width ? props.width : 600) - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom

  useEffect(() => {
    let data = props.data
    const svgEl = d3.select(svgRef.current)
    svgEl.selectAll('*').remove()
    const svg = svgEl
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    const keys = ['correct', 'incorrect']
    const months = Array.from(new Set(data.map((d) => d.x))).sort(d3.ascending)

    // get a map from the month_started to the member_casual to the count_of_rides
    const monthToTypeToCount = d3.rollup(
      data,
      // g is an array that contains a single element
      // get the count for this element
      (g) => g[0].y,
      // group by month first
      (d) => d.x,
      // then group by member of casual
      (d) => d.color
    )

    // put the data in the format mentioned above
    const countsByMonth = Array.from(monthToTypeToCount, ([x, counts]) => {
      // counts is a map from member_casual to count_of_rides
      counts.set('x', x)
      counts.set('total', d3.sum(counts.values()))
      // turn the map into an object
      return Object.fromEntries(counts)
    })

    const stackedData = d3
      .stack()
      .keys(keys)
      // return 0 if a month doesn't have a count for member/casual
      .value((d, key) => d[key] ?? 0)(countsByMonth)

    // scales

    const xScale = d3.scaleBand().domain(months).range([0, width]).padding(0.25)

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(countsByMonth, (d) => d.total)])
      .range([height, 0])

    const color = d3.scaleOrdinal().domain(keys).range(d3.schemeSet3)

    // axes

    const xAxis = d3.axisBottom(xScale)

    svg.append('g').attr('transform', `translate(0,${height})`).call(xAxis)

    const yAxis = d3.axisLeft(yScale)

    svg.append('g').call(yAxis)

    // draw bars

    const groups = svg
      .append('g')
      .selectAll('g')
      .data(stackedData)
      .join('g')
      .attr('fill', (d) => color(d.key))

    groups
      .selectAll('rect')
      .data((d) => d)
      .join('rect')
      .attr('x', (d) => xScale(d.data.x))
      .attr('width', xScale.bandwidth())
      // .attr('y', (d) => yScale(d[1]))
      // .attr('height', (d) => yScale(d[0]) - yScale(d[1]))
      .attr('height', (d) => height - yScale(0)) // always equal to 0
      .attr('y', (d) => yScale(0))
    svg
      .selectAll('rect')
      .transition()
      .duration(800)
      .attr('y', (d) => yScale(d[1]))
      .attr('height', (d) => yScale(d[0]) - yScale(d[1]))
      .delay((d, i) => i * 100)
    // title

    svg
      .append('g')
      .attr('transform', `translate(${width / 2},${-10})`)
      .attr('font-family', 'sans-serif')
      .append('text')
      .attr('text-anchor', 'middle')

    //   .call((text) => text.append('tspan').attr('fill', 'black').text(' vs. '))
    //   .call((text) =>
    //     text.append('tspan').attr('fill', color('casual')).text('casual')
    //   )

    const lineLegend = svg
      .selectAll('.lineLegend')
      .data(stackedData)
      .enter()
      .append('g')
      .attr('class', 'lineLegend')
      .attr('transform', function (d, i) {
        return 'translate(' + (width - 40) + ',' + i * 20 + ')'
      })

    lineLegend
      .append('text')
      .text(function (d) {
        return d.key
      })
      .attr('transform', 'translate(15,9)') //align texts with boxes

    lineLegend
      .append('rect')
      .attr('fill', function (d, i) {
        return color(d.key)
      })
      .attr('width', 10)
      .attr('height', 10)
  }, [props.data, props.height, props.width])

  return (
    <div
      id='chartContainer'
      className={classes.chartContainer}
      ref={containerRef}
    >
      <div id='chart'>
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g
            ref={svgRef}
            // transform={`translate(${margin.left}, ${margin.top})`}
          />
        </svg>
      </div>
    </div>
  )
}

export default GlobalBarchart
