import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  listClassroomDetails,
  updateClassroom,
} from '../actions/classroomActions'
import { CLASSROOM_UPDATE_RESET } from '../constants/classroomConstants'
import StudentListBox from '../components/StudentListBox'
import ClassroomQuestionListBox from '../components/ClassroomQuestionListBox'
import classes from './ClassroomScreens.module.css'

const ClassroomScreen = () => {
  let history = useNavigate()
  let location = useLocation()

  const { id } = useParams()

  const [classroomCode, setClassroomCode] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [teacher, setTeacher] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const classroomDetails = useSelector((state) => state.classroomDetails)
  const { loading, error, classroom } = classroomDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const classroomUpdate = useSelector((state) => state.classroomUpdate)
  const { success } = classroomUpdate

  useEffect(() => {
    if (!userInfo) {
      history('/login')
    } else {
      if (!classroom || success) {
        dispatch({ type: CLASSROOM_UPDATE_RESET })
        dispatch(listClassroomDetails(id))
      } else {
        setClassroomCode(classroom.classroomCode)
        setName(classroom.name)
        setDescription(classroom.description)
        setTeacher(classroom.teacher)
      }
    }
  }, [dispatch, history, userInfo, classroom, success])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      dispatch(
        updateClassroom({
          _id: id,
          classroomCode,
          name,
          description,
          teacher,
          password,
        })
      )
    }
  }

  const clickHandler = () => {
    // dispatch({ type: FETCH_CLASSROOM_DETAILS_RESET })
  }

  return (
    <div className={classes.container}>
      <div className={classes.classroomContainer}>
        <h2 className={classes.classroomHeader}>Classroom Profile</h2>
        <LinkContainer to='/myclassrooms/'>
          <Button
            className={classes.backToClassroomsButton}
            type='btn'
            variant='secondary'
            onClick={clickHandler()}
          >
            Back to Classrooms
          </Button>
        </LinkContainer>
        {message && <Message variant='danger'>{message}</Message>}
        {}
        {success && <Message variant='success'>Classroom Updated</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <div className={classes.classroomFormContainer}>
            <h3>Update Classroom Information</h3>
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='classroomCode'>
                <Form.Label>Classroom Code</Form.Label>
                <Form.Control
                  type='title'
                  disabled='true'
                  placeholder='Enter classroom code'
                  value={classroomCode}
                  onChange={(e) => setClassroomCode(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='name'>
                <Form.Label>Classroom Title</Form.Label>
                <Form.Control
                  type='name'
                  placeholder='Enter title'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='description'>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type='description'
                  placeholder='Enter description'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='teacher'>
                <Form.Label>Teacher</Form.Label>
                <Form.Control
                  type='teacher'
                  placeholder='Enter teacher name'
                  value={teacher}
                  onChange={(e) => setTeacher(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='keycode'>
                <Form.Label>Keycode</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Enter keycode'
                  autoComplete='new-title'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='confirmPassword'>
                <Form.Label>Confirm Keycode</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Confirm password'
                  autoComplete='new-password'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Button
                className={classes.updateButton}
                type='submit'
                variant='primary'
                onClick={clickHandler()}
              >
                Update
              </Button>
            </Form>
          </div>
        )}

        <Row>
          <div className={classes.studentsContainer}>
            <StudentListBox id={id} />
          </div>
          <div className={classes.questionsContainer}>
            <Row>
              <Col>
                <ClassroomQuestionListBox id={id} />
                <LinkContainer to={`/myquestions/`}>
                  <Button
                    className={classes.myQuestionsButton}
                    type='btn'
                    variant='primary'
                    onClick={clickHandler()}
                  >
                    Go to My Questions
                  </Button>
                </LinkContainer>
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    </div>
  )
}

export default ClassroomScreen
