import {
  FOLLOWER_CREATE_FAIL,
  FOLLOWER_CREATE_REQUEST,
  FOLLOWER_CREATE_SUCCESS,
  FOLLOWER_DELETE_FAIL,
  FOLLOWER_DELETE_REQUEST,
  FOLLOWER_DELETE_SUCCESS,
  FOLLOWER_GET_CLASSROOMS_FAIL,
  FOLLOWER_GET_CLASSROOMS_REQUEST,
  FOLLOWER_GET_CLASSROOMS_SUCCESS,
  FOLLOWER_GET_STUDENTS_FAIL,
  FOLLOWER_GET_STUDENTS_REQUEST,
  FOLLOWER_GET_STUDENTS_SUCCESS,
} from '../constants/followerConstants'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { logout } from './userActions'

import { API } from '../apiConstants'

export const createFollower =
  (classroom, studentName) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FOLLOWER_CREATE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${API}/api/followers`,
        { classroom, studentName },
        config
      )

      dispatch({
        type: FOLLOWER_CREATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: FOLLOWER_CREATE_FAIL,
        payload: message,
      })
    }
  }

export const listMyFollowedClassrooms = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FOLLOWER_GET_CLASSROOMS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      'Content-Type': 'application/json',
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${API}/api/followers/myfollowedclassrooms`,
      config
    )

    dispatch({
      type: FOLLOWER_GET_CLASSROOMS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: FOLLOWER_GET_CLASSROOMS_FAIL,
      payload: message,
    })
  }
}

export const listMyStudents = (classroom) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FOLLOWER_GET_STUDENTS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${API}/api/followers/mystudents?classroom=${classroom.classroom}`,
      config
    )

    dispatch({
      type: FOLLOWER_GET_STUDENTS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: FOLLOWER_GET_STUDENTS_FAIL,
      payload: message,
    })
  }
}

export const deleteFollower = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FOLLOWER_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`${API}/api/followers/${id}`, config)

    dispatch({
      type: FOLLOWER_DELETE_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: FOLLOWER_DELETE_FAIL,
      payload: message,
    })
  }
}
