import axios from 'axios'
import {
  FLAG_CREATE_FAIL,
  FLAG_CREATE_REQUEST,
  FLAG_CREATE_SUCCESS,
  FLAG_FILTER_FAIL,
  FLAG_FILTER_REQUEST,
  FLAG_FILTER_SUCCESS,
  FLAG_LIST_ALL_FAIL,
  FLAG_LIST_ALL_REQUEST,
  FLAG_LIST_ALL_SUCCESS,
} from '../constants/flagConstants'

import { logout } from './userActions'

import { API } from '../apiConstants'

export const createFlag = (flag) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FLAG_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`${API}/api/flags`, flag, config)

    dispatch({
      type: FLAG_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: FLAG_CREATE_FAIL,
      payload: message,
    })
  }
}

export const filterFlags =
  (filter, keyword = '', pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      //filter.user = userInfo._id
      dispatch({ type: FLAG_FILTER_REQUEST })

      const { data } = await axios.get(
        `${API}/api/flags/allflags/?keyword=${keyword}&pageNumber=${pageNumber}`,
        {
          params: filter,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )

      dispatch({
        type: FLAG_FILTER_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: FLAG_FILTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listFlags = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FLAG_LIST_ALL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${API}/api/flags`, config)

    dispatch({
      type: FLAG_LIST_ALL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: FLAG_LIST_ALL_FAIL,
      payload: message,
    })
  }
}
