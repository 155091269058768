import React from 'react'
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import AboutNavbar from '../components/AboutNavbar'
import ParagraphBox from '../components/ParagraphBox'
import classes from './AboutScreen.module.css'

const AboutScreen = () => {
  return (
    <div>
      <AboutNavbar />
      <div className={classes.scrollContainer}>
        <Row>
          <Col>
            <ParagraphBox
              title={'What is Lëbe Language?'}
              paragraph={
                "Lëbe Language is a data-driven language learning tool that allows users to optimize their progress. We use state-of-the-art AI technology and insights from language acquisition research to create a dynamic repository of content designed to maximize your learning. Whether you're an absolute beginner or a seasoned polyglot, Lëbe Language will help you bring your skills to the next level."
              }
            />
            <ParagraphBox
              title={"Why is it called 'Lëbe'?"}
              paragraph={
                "The Lëbe Language platform is designed to accompany you on your journey towards fluency in another language. That's why we chose to name the platform 'lëbe' ( སློབ་དཔོན ) which means 'teacher' in the Dzongkha language which is spoken in the Kingdom of Bhutan."
              }
            />
            <ParagraphBox
              title={'Learning Philosophy'}
              paragraph={
                "Although many language learning products rely on relatively limited fixed sets of questions which prompt users to focus on memorization, our platform uses a different approach. With endless questions to simulate real-world language use, Lëbe Language will guide you towards internalizing grammar and vocabulary at a scale that is not possible with other apps. After all, language learning is an endless process, and should be embraced as such. Keep on going, and you'll eventually climb mountains."
              }
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default AboutScreen
