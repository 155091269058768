import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'
import ForthcomingBox from '../components/ForthcomingBox'
import LandingImage from '../components/LandingImage'
import classes from './LandingPage.module.css'

const LandingPage = () => {
  const [current, setCurrent] = useState(0)

  const helloList = [
    'Hello!',
    'Olá e bem-vindo!',
    'Bonjour!',
    'Hallo!',
    'Kuzuzangpo la!',
    'Guten Tag!',
    'Hola!',
    'Nzoni gango!',
    'Hello!',
  ]

  let history = useNavigate()

  const startButtonHandler = (e) => {
    history('/start')
  }

  useEffect(() => {
    const next = current + 1

    const id = setTimeout(() => setCurrent(next), 2000)

    if (next >= helloList.length) {
      setCurrent(0)
    }

    return () => clearTimeout(id)
  }, [current])

  return (
    <div className={classes.landingContainer}>
      <Row>
        <Col>
          <div className={classes.languageJourneyDiv}>
            <h1 className={classes.languageJourney}>
              {'Start your language journey with Lëbe Language!'}
            </h1>
          </div>
          <div className={classes.numberOfQuestionsHeader}>
            <h1 className={classes.numberOfQuestions}>{helloList[current]}</h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <LandingImage />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={classes.footerDiv}>
            <h1 className={classes.footerText}>
              {'Click below to start using the AI-powered learning engine!'}
            </h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button className={classes.letsGoButton} onClick={startButtonHandler}>
            Start Learning
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default LandingPage
