import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Card, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'

import classes from './StudentListBox.module.css'
import {
  deleteMyQuestion,
  getMyQuestionsByClassroom,
  updateMyQuestionRemoveClassrooms,
} from '../actions/myQuestionActions'

const ClassroomQuestionListBox = ({ id }) => {
  const params = useParams()
  const pageNumber = params.pageNumber || 1

  const classroom = { classroom: id }
  const filter = {}
  const { keyword } = useParams()

  const [selectAll, setSelectAll] = useState([])
  const [questionList, setQuestionList] = useState([])
  let history = useNavigate()

  const dispatch = useDispatch()

  const myQuestionByClassroom = useSelector(
    (state) => state.myQuestionByClassroom
  )
  const { loading, error, questions } = myQuestionByClassroom

  const myQuestionDelete = useSelector((state) => state.myQuestionDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = myQuestionDelete

  const myQuestionCreate = useSelector((state) => state.myQuestionCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    question: createdQuestion,
  } = myQuestionCreate

  const myQuestionRemoveClassroom = useSelector(
    (state) => state.myQuestionRemoveClassroom
  )
  const {
    loading: classroomRemoveLoading,
    error: classroomRemoveError,
    success: classroomRemoveSuccess,
  } = myQuestionRemoveClassroom

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {}, [successDelete])

  useEffect(() => {
    if (!userInfo) {
      history('/login')
    } else {
      dispatch(getMyQuestionsByClassroom(id, filter, keyword, pageNumber))
    }
  }, [dispatch, history, userInfo, classroomRemoveSuccess, params])

  useEffect(() => {
    if (questions) {
      setSelectAll(
        Array.apply(false, Array(questions.length)).map(function (x, i) {
          return false
        })
      )
    }
  }, [dispatch, questions, classroomRemoveSuccess])

  useEffect(() => {
    if (questions) {
      let ids = questions.map((question, index) => question._id)
      let selected = selectAll
      let questionIdList = ids.filter((item, index) => selected[index] && item)
      setQuestionList(questionIdList)
    }
  }, [dispatch, selectAll, classroomRemoveSuccess])

  /// OTHER BUTTON HANDLERS

  const checkboxHandler = (e) => {
    if (e) {
      let newSelectAll = [...selectAll]
      if (e.target.checked === true) {
        newSelectAll[Number(e.target.value)] = true
        setSelectAll(newSelectAll)
      } else {
        newSelectAll[Number(e.target.value)] = false
        setSelectAll(newSelectAll)
      }
    }
  }

  const deleteHandler = (idd) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteMyQuestion(idd))
    }
  }

  const submitUpdateHandler = (e) => {
    e.preventDefault()

    dispatch(
      updateMyQuestionRemoveClassrooms({
        classroom: id,
        questions: questionList,
      })
    )
  }

  const selectAllHandler = (e) => {
    if (e.target.checked === true) {
      setSelectAll(
        selectAll.map(function (x, i) {
          return true
        })
      )
    } else if (e.target.checked === false) {
      setSelectAll(
        selectAll.map(function (x, i) {
          return false
        })
      )
    }
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Classroom Module Questions</h1>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Card className={classes['question-list-card']}>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>Level</th>
                <th>Question</th>

                <th>
                  {' '}
                  <Form>
                    <Form.Group
                      className='mb-3'
                      controlId={`selectallcheckbox`}
                    >
                      <Form.Check
                        type='checkbox'
                        label='Select All'
                        onChange={selectAllHandler}
                      />
                    </Form.Group>
                  </Form>
                </th>
              </tr>
            </thead>
            <tbody>
              {questions.map((question, index) => (
                <tr key={question._id}>
                  <td>{question.level}</td>
                  <td>{question.question}</td>

                  <td>
                    <Form>
                      <Form.Group
                        className='mb-3'
                        controlId={`checkbox-${index}`}
                      >
                        <Form.Check
                          type='checkbox'
                          label=''
                          checked={selectAll[index]}
                          value={index}
                          onChange={checkboxHandler}
                        />
                      </Form.Group>
                    </Form>
                  </td>
                  <td>
                    <LinkContainer to={`/myquestions/${question._id}/edit`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button type='submit' variant='danger' onClick={submitUpdateHandler}>
            <i className='fas fa-trash'></i> Delete selected questions from
            classroom
          </Button>
          {/* <Paginate pages={pages} page={page} isAdmin={true} /> */}
        </Card>
      )}
    </>
  )
}

export default ClassroomQuestionListBox
