import {
  MY_QUESTIONS_DETAILS_FAIL,
  MY_QUESTIONS_DETAILS_REQUEST,
  MY_QUESTIONS_DETAILS_SUCCESS,
  MY_QUESTIONS_FETCH_ONE_FAIL,
  MY_QUESTIONS_FETCH_ONE_REQUEST,
  MY_QUESTIONS_FETCH_ONE_RESET,
  MY_QUESTIONS_FETCH_ONE_SUCCESS,
  MY_QUESTIONS_FILTER_FAIL,
  MY_QUESTIONS_FILTER_REQUEST,
  MY_QUESTIONS_FILTER_SUCCESS,
  MY_QUESTION_ADD_CLASSROOM_FAIL,
  MY_QUESTION_ADD_CLASSROOM_REQUEST,
  MY_QUESTION_ADD_CLASSROOM_RESET,
  MY_QUESTION_ADD_CLASSROOM_SUCCESS,
  MY_QUESTION_CREATE_FAIL,
  MY_QUESTION_CREATE_REQUEST,
  MY_QUESTION_CREATE_RESET,
  MY_QUESTION_CREATE_SUCCESS,
  MY_QUESTION_DELETE_BULK_FAIL,
  MY_QUESTION_DELETE_BULK_REQUEST,
  MY_QUESTION_DELETE_BULK_SUCCESS,
  MY_QUESTION_DELETE_FAIL,
  MY_QUESTION_DELETE_REQUEST,
  MY_QUESTION_DELETE_SUCCESS,
  MY_QUESTION_GET_BY_CLASSROOM_FAIL,
  MY_QUESTION_GET_BY_CLASSROOM_REQUEST,
  MY_QUESTION_GET_BY_CLASSROOM_SUCCESS,
  MY_QUESTION_LEVELS_LIST_FAIL,
  MY_QUESTION_LEVELS_LIST_REQUEST,
  MY_QUESTION_LEVELS_LIST_SUCCESS,
  MY_QUESTION_LOWEST_LEVEL_FAIL,
  MY_QUESTION_LOWEST_LEVEL_REQUEST,
  MY_QUESTION_LOWEST_LEVEL_SUCCESS,
  MY_QUESTION_REMOVE_CLASSROOM_FAIL,
  MY_QUESTION_REMOVE_CLASSROOM_REQUEST,
  MY_QUESTION_REMOVE_CLASSROOM_RESET,
  MY_QUESTION_REMOVE_CLASSROOM_SUCCESS,
  MY_QUESTION_UPDATE_FAIL,
  MY_QUESTION_UPDATE_REQUEST,
  MY_QUESTION_UPDATE_RESET,
  MY_QUESTION_UPDATE_SUCCESS,
} from '../constants/myQuestionConstants'

export const myQuestionFetchOneReducer = (state = { question: {} }, action) => {
  switch (action.type) {
    case MY_QUESTIONS_FETCH_ONE_REQUEST:
      return { ...state, loading: true }
    case MY_QUESTIONS_FETCH_ONE_SUCCESS:
      return {
        loading: false,
        question: action.payload,
      }
    case MY_QUESTIONS_FETCH_ONE_FAIL:
      return { loading: false, error: action.payload }
    case MY_QUESTIONS_FETCH_ONE_RESET:
      return {}
    default:
      return state
  }
}

export const myQuestionFilterReducer = (state = { questions: [] }, action) => {
  switch (action.type) {
    case MY_QUESTIONS_FILTER_REQUEST:
      return { loading: true }
    case MY_QUESTIONS_FILTER_SUCCESS:
      return {
        loading: false,
        questions: action.payload.questions,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case MY_QUESTIONS_FILTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const myQuestionDetailsReducer = (state = { question: {} }, action) => {
  switch (action.type) {
    case MY_QUESTIONS_DETAILS_REQUEST:
      return { ...state, loading: true }
    case MY_QUESTIONS_DETAILS_SUCCESS:
      return { loading: false, question: action.payload }
    case MY_QUESTIONS_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const myQuestionAddClassroomReducer = (
  state = { questions: [] },
  action
) => {
  switch (action.type) {
    case MY_QUESTION_ADD_CLASSROOM_REQUEST:
      return { loading: true }
    case MY_QUESTION_ADD_CLASSROOM_SUCCESS:
      return { loading: false, success: true, questions: action.payload }
    case MY_QUESTION_ADD_CLASSROOM_FAIL:
      return { loading: false, error: action.payload }
    case MY_QUESTION_ADD_CLASSROOM_RESET:
      return { questions: [] }
    default:
      return state
  }
}

export const myQuestionRemoveClassroomReducer = (
  state = { questions: [] },
  action
) => {
  switch (action.type) {
    case MY_QUESTION_REMOVE_CLASSROOM_REQUEST:
      return { loading: true }
    case MY_QUESTION_REMOVE_CLASSROOM_SUCCESS:
      return { loading: false, success: true, questions: action.payload }
    case MY_QUESTION_REMOVE_CLASSROOM_FAIL:
      return { loading: false, error: action.payload }
    case MY_QUESTION_REMOVE_CLASSROOM_RESET:
      return { questions: [] }
    default:
      return state
  }
}
export const myQuestionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MY_QUESTION_DELETE_REQUEST:
      return { loading: true }
    case MY_QUESTION_DELETE_SUCCESS:
      return { loading: false, success: true }
    case MY_QUESTION_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const myQuestionDeleteBulkReducer = (
  state = { questions: [] },
  action
) => {
  switch (action.type) {
    case MY_QUESTION_DELETE_BULK_REQUEST:
      return { loading: true }
    case MY_QUESTION_DELETE_BULK_SUCCESS:
      return { loading: false, success: true }
    case MY_QUESTION_DELETE_BULK_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const myQuestionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MY_QUESTION_CREATE_REQUEST:
      return { loading: true }
    case MY_QUESTION_CREATE_SUCCESS:
      return { loading: false, success: true, question: action.payload }
    case MY_QUESTION_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case MY_QUESTION_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const myQuestionUpdateReducer = (state = { question: {} }, action) => {
  switch (action.type) {
    case MY_QUESTION_UPDATE_REQUEST:
      return { loading: true }
    case MY_QUESTION_UPDATE_SUCCESS:
      return { loading: false, success: true, question: action.payload }
    case MY_QUESTION_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case MY_QUESTION_UPDATE_RESET:
      return { question: {} }
    default:
      return state
  }
}

export const myQuestionByClassroomReducer = (
  state = { questions: [] },
  action
) => {
  switch (action.type) {
    case MY_QUESTION_GET_BY_CLASSROOM_REQUEST:
      return { loading: true }
    case MY_QUESTION_GET_BY_CLASSROOM_SUCCESS:
      return {
        loading: false,
        questions: action.payload.questions,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case MY_QUESTION_GET_BY_CLASSROOM_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const myQuestionLowestLevelReducer = (state = { level: 1 }, action) => {
  switch (action.type) {
    case MY_QUESTION_LOWEST_LEVEL_REQUEST:
      return { loading: true }
    case MY_QUESTION_LOWEST_LEVEL_SUCCESS:
      return {
        loading: false,
        level: action.payload,
      }
    case MY_QUESTION_LOWEST_LEVEL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const myQuestionLevelsListReducer = (state = {}, action) => {
  switch (action.type) {
    case MY_QUESTION_LEVELS_LIST_REQUEST:
      return { loading: true }
    case MY_QUESTION_LEVELS_LIST_SUCCESS:
      return {
        loading: false,
        levels: action.payload,
      }
    case MY_QUESTION_LEVELS_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
