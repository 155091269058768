import {
  DASHBOARD_DELETE_ALL_DATA_FAIL,
  DASHBOARD_DELETE_ALL_DATA_REQUEST,
  DASHBOARD_DELETE_ALL_DATA_SUCCESS,
  DASHBOARD_GLOBAL_ACCURACY_DONUT_FAIL,
  DASHBOARD_GLOBAL_ACCURACY_DONUT_REQUEST,
  DASHBOARD_GLOBAL_ACCURACY_DONUT_SUCCESS,
  DASHBOARD_GLOBAL_BAR_CHART_FAIL,
  DASHBOARD_GLOBAL_BAR_CHART_REQUEST,
  DASHBOARD_GLOBAL_BAR_CHART_SUCCESS,
  DASHBOARD_GLOBAL_TIME_SERIES_FAIL,
  DASHBOARD_GLOBAL_TIME_SERIES_REQUEST,
  DASHBOARD_GLOBAL_TIME_SERIES_SUCCESS,
  DASHBOARD_LANGUAGE_ACCURACY_DONUT_FAIL,
  DASHBOARD_LANGUAGE_ACCURACY_DONUT_REQUEST,
  DASHBOARD_LANGUAGE_ACCURACY_DONUT_SUCCESS,
  DASHBOARD_LANGUAGE_BAR_CHART_FAIL,
  DASHBOARD_LANGUAGE_BAR_CHART_REQUEST,
  DASHBOARD_LANGUAGE_BAR_CHART_SUCCESS,
  DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_FAIL,
  DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_REQUEST,
  DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_SUCCESS,
  DASHBOARD_LANGUAGE_LEBE_SCORE_FAIL,
  DASHBOARD_LANGUAGE_LEBE_SCORE_REQUEST,
  DASHBOARD_LANGUAGE_LEBE_SCORE_SUCCESS,
  DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_FAIL,
  DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_REQUEST,
  DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_SUCCESS,
  DASHBOARD_LANGUAGE_TOTAL_ANSWERS_FAIL,
  DASHBOARD_LANGUAGE_TOTAL_ANSWERS_REQUEST,
  DASHBOARD_LANGUAGE_TOTAL_ANSWERS_SUCCESS,
  DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_FAIL,
  DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_REQUEST,
  DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_SUCCESS,
  DASHBOARD_LANGUAGE_TRICKY_WORDS_FAIL,
  DASHBOARD_LANGUAGE_TRICKY_WORDS_REQUEST,
  DASHBOARD_LANGUAGE_TRICKY_WORDS_SUCCESS,
  DASHBOARD_POST_DATA_FAIL,
  DASHBOARD_POST_DATA_REQUEST,
  DASHBOARD_POST_DATA_SUCCESS,
  DASHBOARD_STUDENT_GLOBAL_HEATMAP_FAIL,
  DASHBOARD_STUDENT_GLOBAL_HEATMAP_REQUEST,
  DASHBOARD_STUDENT_GLOBAL_HEATMAP_SUCCESS,
  DASHBOARD_STUDENT_TOTAL_ANSWERS_FAIL,
  DASHBOARD_STUDENT_TOTAL_ANSWERS_REQUEST,
  DASHBOARD_STUDENT_TOTAL_ANSWERS_SUCCESS,
  DASHBOARD_STUDENT_TOTAL_QUESTIONS_FAIL,
  DASHBOARD_STUDENT_TOTAL_QUESTIONS_REQUEST,
  DASHBOARD_STUDENT_TOTAL_QUESTIONS_SUCCESS,
} from '../constants/dashboardConstants'

export const postDashboardDataReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_POST_DATA_REQUEST:
      return { loading: true }
    case DASHBOARD_POST_DATA_SUCCESS:
      return { loading: false, success: true, answer: action.payload }
    case DASHBOARD_POST_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardGlobalStudentHeatmapReducer = (
  state = { heatmapData: [] },
  action
) => {
  switch (action.type) {
    case DASHBOARD_STUDENT_GLOBAL_HEATMAP_REQUEST:
      return { loading: true }
    case DASHBOARD_STUDENT_GLOBAL_HEATMAP_SUCCESS:
      return {
        loading: false,
        heatmapData: action.payload,
      }
    case DASHBOARD_STUDENT_GLOBAL_HEATMAP_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardGlobalAccuracyDonutReducer = (
  state = { donutData: [] },
  action
) => {
  switch (action.type) {
    case DASHBOARD_GLOBAL_ACCURACY_DONUT_REQUEST:
      return { loading: true }
    case DASHBOARD_GLOBAL_ACCURACY_DONUT_SUCCESS:
      return {
        loading: false,
        donutData: action.payload,
      }
    case DASHBOARD_GLOBAL_ACCURACY_DONUT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardGlobalTimeSeriesReducer = (
  state = { timeSeriesData: [] },
  action
) => {
  switch (action.type) {
    case DASHBOARD_GLOBAL_TIME_SERIES_REQUEST:
      return { loading: true }
    case DASHBOARD_GLOBAL_TIME_SERIES_SUCCESS:
      return {
        loading: false,
        timeSeriesData: action.payload,
      }
    case DASHBOARD_GLOBAL_TIME_SERIES_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardGlobalBarChartReducer = (
  state = { barChartData: [] },
  action
) => {
  switch (action.type) {
    case DASHBOARD_GLOBAL_BAR_CHART_REQUEST:
      return { loading: true }
    case DASHBOARD_GLOBAL_BAR_CHART_SUCCESS:
      return {
        loading: false,
        barChartData: action.payload,
      }
    case DASHBOARD_GLOBAL_BAR_CHART_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardStudentTotalQuestionReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_STUDENT_TOTAL_QUESTIONS_REQUEST:
      return { loading: true }
    case DASHBOARD_STUDENT_TOTAL_QUESTIONS_SUCCESS:
      return {
        loading: false,
        questionCount: action.payload,
      }
    case DASHBOARD_STUDENT_TOTAL_QUESTIONS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardStudentTotalAnswerReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_STUDENT_TOTAL_ANSWERS_REQUEST:
      return { loading: true }
    case DASHBOARD_STUDENT_TOTAL_ANSWERS_SUCCESS:
      return {
        loading: false,
        answerCount: action.payload,
      }
    case DASHBOARD_STUDENT_TOTAL_ANSWERS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardLanguageTotalQuestionReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_REQUEST:
      return { loading: true }
    case DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_SUCCESS:
      return {
        loading: false,
        questionCount: action.payload,
      }
    case DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardLanguageTotalAnswerReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_LANGUAGE_TOTAL_ANSWERS_REQUEST:
      return { loading: true }
    case DASHBOARD_LANGUAGE_TOTAL_ANSWERS_SUCCESS:
      return {
        loading: false,
        answerCount: action.payload,
      }
    case DASHBOARD_LANGUAGE_TOTAL_ANSWERS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardLanguageAccuracyDonutReducer = (
  state = { donutData: [] },
  action
) => {
  switch (action.type) {
    case DASHBOARD_LANGUAGE_ACCURACY_DONUT_REQUEST:
      return { loading: true }
    case DASHBOARD_LANGUAGE_ACCURACY_DONUT_SUCCESS:
      return {
        loading: false,
        donutData: action.payload,
      }
    case DASHBOARD_LANGUAGE_ACCURACY_DONUT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardLanguageLebeScoreReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_LANGUAGE_LEBE_SCORE_REQUEST:
      return { loading: true }
    case DASHBOARD_LANGUAGE_LEBE_SCORE_SUCCESS:
      return {
        loading: false,
        lebeScore: action.payload,
      }
    case DASHBOARD_LANGUAGE_LEBE_SCORE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardLanguageBarChartReducer = (
  state = { barChartData: [] },
  action
) => {
  switch (action.type) {
    case DASHBOARD_LANGUAGE_BAR_CHART_REQUEST:
      return { loading: true }
    case DASHBOARD_LANGUAGE_BAR_CHART_SUCCESS:
      return {
        loading: false,
        barChartData: action.payload,
      }
    case DASHBOARD_LANGUAGE_BAR_CHART_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardLanguageMeanScoreBarChartReducer = (
  state = { barChartData: [] },
  action
) => {
  switch (action.type) {
    case DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_REQUEST:
      return { loading: true }
    case DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_SUCCESS:
      return {
        loading: false,
        barChartData: action.payload,
      }
    case DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardDeleteAllDataReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_DELETE_ALL_DATA_REQUEST:
      return { loading: true }
    case DASHBOARD_DELETE_ALL_DATA_SUCCESS:
      return { loading: false, success: true }
    case DASHBOARD_DELETE_ALL_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardLanguageStudentHeatmapReducer = (
  state = { heatmapData: [] },
  action
) => {
  switch (action.type) {
    case DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_REQUEST:
      return { loading: true }
    case DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_SUCCESS:
      return {
        loading: false,
        heatmapData: action.payload,
      }
    case DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardLanguageTrickyWordsReducer = (
  state = { trickyWords: [] },
  action
) => {
  switch (action.type) {
    case DASHBOARD_LANGUAGE_TRICKY_WORDS_REQUEST:
      return { loading: true }
    case DASHBOARD_LANGUAGE_TRICKY_WORDS_SUCCESS:
      return {
        loading: false,
        trickyWords: action.payload,
      }
    case DASHBOARD_LANGUAGE_TRICKY_WORDS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
