import React from 'react'
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Meta from '../components/Meta'
import ParagraphBox from '../components/ParagraphBox'
import ParagraphPictureBox from '../components/ParagraphPictureBox'
import classes from './AboutScreen.module.css'

const SecretAboutScreen = () => {
  return (
    <div>
      <div className={classes.scrollContainer}>
        <Meta />
        <Row>
          <Col>
            <>
              <ParagraphPictureBox
                title={'Hello and welcome to Lëbe Language!'}
                paragraph={
                  'My name is Sean and I am a software developer and data scientist based in Michigan. I created Lëbe Language as a tool to empower language learners and teachers with high-quality data analytics. This product is currently being beta-tested by a worldwide team of polyglots. As we gather more training data from the beta testers and finetune the pipelines, we hope to improve the quality of the tools and adapt the pedagogy according to the analytics. I hope to one day develop this product into a viable business. In the meantime, I hope to gain more industry experience as a data scientist or software developer. Feel free to check out the technical details below or try the app yourself.'
                }
              />
              <ParagraphBox
                title={'1. The Data Science and AI'}
                paragraph={
                  'The questions in this language app are predominantly derived from two sources: text mining of public domain literature and AI-generated text. This pipeline utilized numerous deep learning and machine learning techniques and was made possible by Python libraries such as NLTK, spaCy, fastText, gensim, scikit-learn, pyTorch, tensorflow, and pandas, among others. In terms of language models, I made significant use of BERT, GPT-2, T5, and fastText word vectors.'
                }
              />
              <ParagraphBox
                title={'2. The Backend'}
                paragraph={
                  'The server was built with Node.js, Express, and Mongoose and communicates with a MongoDB collection. MongoDB Atlas proved especially useful for generating aggregation pipelines that allowed for creating dynamic visualizations. The authentication is secured with JWTs.'
                }
              />
              <ParagraphBox
                title={'3. The DevOps'}
                paragraph={
                  'The application is hosted on AWS and makes use of a wide variety of AWS services. The CI/CD pipeline consists of CodePipeline architecture that includes a server hosted on ElasticBeanstalk and the frontend static website hosted in an S3 bucket. The domain was acquired through Route53 and secured through Cloudfront distributions.'
                }
              />
              <ParagraphBox
                title={'4. The Frontend'}
                paragraph={
                  'The UX of this product is designed to be hyper-granular in order to ensure that learners receive constant feedback. As such, the frontend was built with React.js and utilizes a large suite of libraries for state management and dynamic components. In addition, the D3 library was used to create the interactive visualizations available in the dashboards.'
                }
              />
              <ParagraphBox
                title={'Note to Potential Employers and Investors:'}
                paragraph={
                  'If you would like to know more about this project or see some of the source code, feel free to contact me at lebelanguages@gmail.com.'
                }
              />
            </>
            {/* {summaryinfo.map((x, index) => (
              <>
                <ParagraphBox title={x.header} paragraph={x.text} />
              </>
            ))} */}
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default SecretAboutScreen
