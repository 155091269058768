import {
  LANGUAGE_PROFILE_FETCH_BARCHART_FAIL,
  LANGUAGE_PROFILE_FETCH_BARCHART_REQUEST,
  LANGUAGE_PROFILE_FETCH_BARCHART_SUCCESS,
  LANGUAGE_PROFILE_FETCH_DONUT_COUNT_FAIL,
  LANGUAGE_PROFILE_FETCH_DONUT_COUNT_REQUEST,
  LANGUAGE_PROFILE_FETCH_DONUT_COUNT_SUCCESS,
  LANGUAGE_PROFILE_FETCH_QUESTION_COUNT_FAIL,
  LANGUAGE_PROFILE_FETCH_QUESTION_COUNT_REQUEST,
  LANGUAGE_PROFILE_FETCH_QUESTION_COUNT_SUCCESS,
  LANGUAGE_PROFILE_FETCH_TOTAL_COUNT_FAIL,
  LANGUAGE_PROFILE_FETCH_TOTAL_COUNT_REQUEST,
  LANGUAGE_PROFILE_FETCH_TOTAL_COUNT_SUCCESS,
  LANGUAGE_PROFILE_FETCH_VOCAB_COUNT_FAIL,
  LANGUAGE_PROFILE_FETCH_VOCAB_COUNT_REQUEST,
  LANGUAGE_PROFILE_FETCH_VOCAB_COUNT_SUCCESS,
} from '../constants/languageProfileConstants'

export const languageProfileBarChartReducer = (
  state = { barChartData: [] },
  action
) => {
  switch (action.type) {
    case LANGUAGE_PROFILE_FETCH_BARCHART_REQUEST:
      return { loading: true }
    case LANGUAGE_PROFILE_FETCH_BARCHART_SUCCESS:
      return {
        loading: false,
        barChartData: action.payload,
      }
    case LANGUAGE_PROFILE_FETCH_BARCHART_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const languageProfileDonutCountReducer = (
  state = { DonutData: [] },
  action
) => {
  switch (action.type) {
    case LANGUAGE_PROFILE_FETCH_DONUT_COUNT_REQUEST:
      return { loading: true }
    case LANGUAGE_PROFILE_FETCH_DONUT_COUNT_SUCCESS:
      return {
        loading: false,
        donutData: action.payload,
      }
    case LANGUAGE_PROFILE_FETCH_DONUT_COUNT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const languageProfileQuestionCountReducer = (state = {}, action) => {
  switch (action.type) {
    case LANGUAGE_PROFILE_FETCH_QUESTION_COUNT_REQUEST:
      return { loading: true }
    case LANGUAGE_PROFILE_FETCH_QUESTION_COUNT_SUCCESS:
      return {
        loading: false,
        questionCount: action.payload,
      }
    case LANGUAGE_PROFILE_FETCH_QUESTION_COUNT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const languageProfileVocabCountReducer = (state = {}, action) => {
  switch (action.type) {
    case LANGUAGE_PROFILE_FETCH_VOCAB_COUNT_REQUEST:
      return { loading: true }
    case LANGUAGE_PROFILE_FETCH_VOCAB_COUNT_SUCCESS:
      return {
        loading: false,
        vocabCount: action.payload,
      }
    case LANGUAGE_PROFILE_FETCH_VOCAB_COUNT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const languageProfileTotalCountReducer = (state = {}, action) => {
  switch (action.type) {
    case LANGUAGE_PROFILE_FETCH_TOTAL_COUNT_REQUEST:
      return { loading: true }
    case LANGUAGE_PROFILE_FETCH_TOTAL_COUNT_SUCCESS:
      return {
        loading: false,
        totalCount: action.payload,
      }
    case LANGUAGE_PROFILE_FETCH_TOTAL_COUNT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
