import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createNotebook } from '../actions/notebookActions'

import Message from './Message'

const NotebookModal = (props) => {
  const [note, setNote] = useState('')
  const [hint, setHint] = useState('')
  const [collectionName, setCollectionName] = useState('')
  const [priority, setPriority] = useState(1)
  const [message, setMessage] = useState('')

  const dispatch = useDispatch()

  const notebookCreate = useSelector((state) => state.notebookCreate)
  const { loading, error, success, notebook } = notebookCreate

  useEffect(() => {
    if (success) {
      setMessage('Successfully added to notebook!')
      setNote('')
      setCollectionName('')
      setHint('')
    }
  }, [success, notebook])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createNotebook({
        question: props.questionData.question,
        answers: props.questionData.answers,
        targetLanguage: props.questionData.targetLanguage,
        level: props.questionData.level,
        questionId: props.questionData._id,
        questionType: props.questionData.questionType,
        isFlagged: props.questionData.isFlagged,
        textType: props.questionData.textType,
        citation: props.questionData.citation,
        note,
        hint,
        collectionName,
        priority,
        source: 'Lebe Languages',
      })
    )
  }

  return (
    <>
      <Modal
        show={props.showNotebookModal}
        onHide={props.handleNotebookModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add to your notebook</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3' controlId='question'>
              <Form.Label>Question</Form.Label>
              <Form.Control
                type='question'
                value={props.questionData.question}
                disabled
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='level'>
              <Form.Label>Level</Form.Label>
              <Form.Control
                type='text'
                value={props.questionData.level}
                disabled
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='targetLanguage'>
              <Form.Label>Language</Form.Label>
              <Form.Control
                type='text'
                value={props.questionData.targetLanguage}
                disabled
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='note'>
              <Form.Label>Note</Form.Label>
              <Form.Control
                type='text'
                placeholder=''
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='note'>
              <Form.Label>Hint</Form.Label>
              <Form.Control
                type='text'
                placeholder=''
                value={hint}
                onChange={(e) => setHint(e.target.value)}
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='collectionName'>
              <Form.Label>Collection Name</Form.Label>
              <Form.Control
                type='text'
                placeholder=''
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='priority'>
              <Form.Label>Priority</Form.Label>
              <Form.Control
                type='text'
                placeholder=''
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={props.handleNotebookModalClose}>
            Close
          </Button>
          <Button
            variant='primary'
            disabled={success ? true : false}
            onClick={submitHandler}
          >
            Save Changes
          </Button>
        </Modal.Footer>
        {success && <Message variant='success'>{message}</Message>}
      </Modal>
    </>
  )
}

export default NotebookModal
