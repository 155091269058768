import axios from 'axios'
import {
  DASHBOARD_POST_DATA_FAIL,
  DASHBOARD_POST_DATA_REQUEST,
  DASHBOARD_POST_DATA_SUCCESS,
  DASHBOARD_STUDENT_GLOBAL_HEATMAP_FAIL,
  DASHBOARD_STUDENT_GLOBAL_HEATMAP_REQUEST,
  DASHBOARD_STUDENT_GLOBAL_HEATMAP_SUCCESS,
  DASHBOARD_STUDENT_TOTAL_QUESTIONS_SUCCESS,
  DASHBOARD_STUDENT_TOTAL_QUESTIONS_REQUEST,
  DASHBOARD_STUDENT_TOTAL_QUESTIONS_FAIL,
  DASHBOARD_STUDENT_TOTAL_ANSWERS_SUCCESS,
  DASHBOARD_STUDENT_TOTAL_ANSWERS_REQUEST,
  DASHBOARD_STUDENT_TOTAL_ANSWERS_FAIL,
  DASHBOARD_GLOBAL_ACCURACY_DONUT_REQUEST,
  DASHBOARD_GLOBAL_ACCURACY_DONUT_SUCCESS,
  DASHBOARD_GLOBAL_ACCURACY_DONUT_FAIL,
  DASHBOARD_GLOBAL_TIME_SERIES_REQUEST,
  DASHBOARD_GLOBAL_TIME_SERIES_SUCCESS,
  DASHBOARD_GLOBAL_TIME_SERIES_FAIL,
  DASHBOARD_GLOBAL_BAR_CHART_REQUEST,
  DASHBOARD_GLOBAL_BAR_CHART_SUCCESS,
  DASHBOARD_GLOBAL_BAR_CHART_FAIL,
  DASHBOARD_LANGUAGE_ACCURACY_DONUT_REQUEST,
  DASHBOARD_LANGUAGE_ACCURACY_DONUT_SUCCESS,
  DASHBOARD_LANGUAGE_ACCURACY_DONUT_FAIL,
  DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_REQUEST,
  DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_SUCCESS,
  DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_FAIL,
  DASHBOARD_LANGUAGE_TOTAL_ANSWERS_REQUEST,
  DASHBOARD_LANGUAGE_TOTAL_ANSWERS_SUCCESS,
  DASHBOARD_LANGUAGE_TOTAL_ANSWERS_FAIL,
  DASHBOARD_LANGUAGE_BAR_CHART_REQUEST,
  DASHBOARD_LANGUAGE_BAR_CHART_SUCCESS,
  DASHBOARD_LANGUAGE_BAR_CHART_FAIL,
  DASHBOARD_LANGUAGE_LEBE_SCORE_REQUEST,
  DASHBOARD_LANGUAGE_LEBE_SCORE_SUCCESS,
  DASHBOARD_LANGUAGE_LEBE_SCORE_FAIL,
  DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_REQUEST,
  DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_SUCCESS,
  DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_FAIL,
  DASHBOARD_DELETE_ALL_DATA_FAIL,
  DASHBOARD_DELETE_ALL_DATA_SUCCESS,
  DASHBOARD_DELETE_ALL_DATA_REQUEST,
  DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_SUCCESS,
  DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_FAIL,
  DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_REQUEST,
  DASHBOARD_LANGUAGE_TRICKY_WORDS_REQUEST,
  DASHBOARD_LANGUAGE_TRICKY_WORDS_SUCCESS,
  DASHBOARD_LANGUAGE_TRICKY_WORDS_FAIL,
} from '../constants/dashboardConstants'
import { logout } from './userActions'

import { API } from '../apiConstants'

export const postDashboardData = (answerData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DASHBOARD_POST_DATA_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `${API}/api/dashboards`,
      answerData,
      config
    )

    dispatch({
      type: DASHBOARD_POST_DATA_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: DASHBOARD_POST_DATA_FAIL,
      payload: message,
    })
  }
}

export const fetchGlobalStudentHeatmap = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    dispatch({
      type: DASHBOARD_STUDENT_GLOBAL_HEATMAP_REQUEST,
    })

    const { data } = await axios.get(
      `${API}/api/dashboards/globalstudentheatmap`,
      config
    )

    dispatch({
      type: DASHBOARD_STUDENT_GLOBAL_HEATMAP_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DASHBOARD_STUDENT_GLOBAL_HEATMAP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const fetchGlobalStudentQuestionTotal =
  () => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      dispatch({
        type: DASHBOARD_STUDENT_TOTAL_QUESTIONS_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/dashboards/globalstudentquestioncount`,
        config
      )

      dispatch({
        type: DASHBOARD_STUDENT_TOTAL_QUESTIONS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DASHBOARD_STUDENT_TOTAL_QUESTIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const fetchGlobalStudentAnswerTotal =
  () => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      dispatch({
        type: DASHBOARD_STUDENT_TOTAL_ANSWERS_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/dashboards/globalstudentanswercount`,
        config
      )

      dispatch({
        type: DASHBOARD_STUDENT_TOTAL_ANSWERS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DASHBOARD_STUDENT_TOTAL_ANSWERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const fetchGlobalStudentTimeSeries =
  () => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      dispatch({
        type: DASHBOARD_GLOBAL_TIME_SERIES_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/dashboards/globalstudenttimeseries`,
        config
      )

      dispatch({
        type: DASHBOARD_GLOBAL_TIME_SERIES_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DASHBOARD_GLOBAL_TIME_SERIES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const fetchGlobalStudentBarChart = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    dispatch({
      type: DASHBOARD_GLOBAL_BAR_CHART_REQUEST,
    })

    const { data } = await axios.get(
      `${API}/api/dashboards/globalstudentbarchart`,
      config
    )

    dispatch({
      type: DASHBOARD_GLOBAL_BAR_CHART_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DASHBOARD_GLOBAL_BAR_CHART_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const fetchGlobalStudentAccuracyDonut =
  () => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      dispatch({
        type: DASHBOARD_GLOBAL_ACCURACY_DONUT_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/dashboards/globalstudentoverallaccuracy`,
        config
      )

      dispatch({
        type: DASHBOARD_GLOBAL_ACCURACY_DONUT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DASHBOARD_GLOBAL_ACCURACY_DONUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const fetchLanguageStudentAccuracyDonut =
  (filter) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      dispatch({
        type: DASHBOARD_LANGUAGE_ACCURACY_DONUT_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/dashboards/languagestudentoverallaccuracy`,
        {
          params: filter,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )

      dispatch({
        type: DASHBOARD_LANGUAGE_ACCURACY_DONUT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DASHBOARD_LANGUAGE_ACCURACY_DONUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const fetchLanguageStudentQuestionTotal =
  (filter) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      dispatch({
        type: DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/dashboards/languagestudentquestioncount`,
        {
          params: filter,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )

      dispatch({
        type: DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DASHBOARD_LANGUAGE_TOTAL_QUESTIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const fetchLanguageStudentAnswerTotal =
  (filter) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      dispatch({
        type: DASHBOARD_LANGUAGE_TOTAL_ANSWERS_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/dashboards/languagestudentanswercount`,
        {
          params: filter,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )

      dispatch({
        type: DASHBOARD_LANGUAGE_TOTAL_ANSWERS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DASHBOARD_LANGUAGE_TOTAL_ANSWERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const fetchLanguageStudentBarChart =
  (filter) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      dispatch({
        type: DASHBOARD_LANGUAGE_BAR_CHART_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/dashboards/languagestudentaccuracybarchart`,
        {
          params: filter,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )

      dispatch({
        type: DASHBOARD_LANGUAGE_BAR_CHART_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DASHBOARD_LANGUAGE_BAR_CHART_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const fetchLanguageStudentMeanScoreChart =
  (filter) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      dispatch({
        type: DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/dashboards/languagestudentmeanscorebarchart`,
        {
          params: filter,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )

      dispatch({
        type: DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DASHBOARD_LANGUAGE_MEAN_SCORE_CHART_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const fetchLanguageStudentLebeScore =
  (filter) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      dispatch({
        type: DASHBOARD_LANGUAGE_LEBE_SCORE_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/dashboards/languagestudentlebescore`,
        {
          params: filter,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )

      dispatch({
        type: DASHBOARD_LANGUAGE_LEBE_SCORE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DASHBOARD_LANGUAGE_LEBE_SCORE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const fetchLanguageStudentHeatmap =
  (filter) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      dispatch({
        type: DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/dashboards/languagestudentheatmap`,
        {
          params: filter,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )

      dispatch({
        type: DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DASHBOARD_LANGUAGE_KNOWLEDGE_HEATMAP_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const fetchLanguageStudentTrickyWords =
  (filter) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      dispatch({
        type: DASHBOARD_LANGUAGE_TRICKY_WORDS_REQUEST,
      })

      const { data } = await axios.get(
        `${API}/api/dashboards/languagestudenttrickywords`,
        {
          params: filter,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )

      dispatch({
        type: DASHBOARD_LANGUAGE_TRICKY_WORDS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DASHBOARD_LANGUAGE_TRICKY_WORDS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deleteAllDashboardData = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DASHBOARD_DELETE_ALL_DATA_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`${API}/api/dashboards/delete`, config)

    dispatch({
      type: DASHBOARD_DELETE_ALL_DATA_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: DASHBOARD_DELETE_ALL_DATA_FAIL,
      payload: message,
    })
  }
}
