import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import AboutNavbar from '../components/AboutNavbar'
import classes from './AboutScreen.module.css'

const ResearchAboutScreen = () => {
  return (
    <div>
      <AboutNavbar />
      <div className={classes.scrollContainer}>
        <h2></h2>
      </div>
    </div>
  )
}
export default ResearchAboutScreen
