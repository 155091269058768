import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  deleteAllDashboardData,
  fetchGlobalStudentAccuracyDonut,
  fetchGlobalStudentAnswerTotal,
  fetchGlobalStudentBarChart,
  fetchGlobalStudentHeatmap,
  fetchGlobalStudentQuestionTotal,
  fetchGlobalStudentTimeSeries,
  fetchLanguageStudentAccuracyDonut,
  fetchLanguageStudentAnswerTotal,
  fetchLanguageStudentBarChart,
  fetchLanguageStudentHeatmap,
  fetchLanguageStudentLebeScore,
  fetchLanguageStudentMeanScoreChart,
  fetchLanguageStudentQuestionTotal,
  fetchLanguageStudentTrickyWords,
} from '../actions/dashboardActions'
import SuggestionBird from '../components/SuggestionBird'
import GlobalHeatmap from '../dashboardComponents/GlobalHeatmap'
import classes from './DashboardScreen.module.css'
import Loader from '../components/Loader'
import { GlobalTotalBox } from '../dashboardComponents/GlobalTotalBox'
import GlobalAccuracyDonut from '../dashboardComponents/GlobalAccuracyDonut'
import GlobalTimeSeries from '../dashboardComponents/GlobalTimeSeries'
import GlobalBarchart from '../dashboardComponents/GlobalBarchart'
import LanguageBarChart from '../dashboardComponents/LanguageBarChart'
import NoDataImage from '../components/NoDataImage'
import { clearUserLevel } from '../actions/userActions'
import LanguageKnowledgeHeatmap from '../dashboardComponents/LanguageKnowledgeHeatmap'
import TrickyWordsTable from '../dashboardComponents/TrickyWordsTable'
import { FluencyScoreBox } from '../dashboardComponents/FluencyScoreBox'

const StudentGlobalDashboardScreen = () => {
  const dispatch = useDispatch()
  let history = useNavigate()

  const [height, setHeight] = useState(400)
  const [width, setWidth] = useState(600)
  const [languageOptions, setLanguageOptions] = useState(['Global'])
  const [language, setLanguage] = useState('Global')

  const filter = {
    language: language,
  }
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // Delete Dashboard Data

  const dashboardDeleteAllData = useSelector(
    (state) => state.dashboardDeleteAllData
  )
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = dashboardDeleteAllData

  // Heatmap Data
  const dashboardGlobalStudentHeatmap = useSelector(
    (state) => state.dashboardGlobalStudentHeatmap
  )
  const { loading, error, heatmapData } = dashboardGlobalStudentHeatmap

  // Heatmap Data
  const dashboardLanguageStudentHeatmap = useSelector(
    (state) => state.dashboardLanguageStudentHeatmap
  )
  const {
    loading: loadingLanguageHeatmap,
    error: errorLanguageHeatmap,
    heatmapData: languageHeatmapData,
  } = dashboardLanguageStudentHeatmap

  // Answer Count - Global
  const dashboardStudentTotalAnswer = useSelector(
    (state) => state.dashboardStudentTotalAnswer
  )
  const {
    loading: loadingAnswerCount,
    error: errorAnswerCount,
    answerCount,
  } = dashboardStudentTotalAnswer
  // Answer Count - Language
  const dashboardLanguageTotalAnswer = useSelector(
    (state) => state.dashboardLanguageTotalAnswer
  )
  const {
    loading: loadingLanguageAnswerCount,
    error: errorLanguageAnswerCount,
    answerCount: languageAnswerCount,
  } = dashboardLanguageTotalAnswer

  // Question Count - Global
  const dashboardStudentTotalQuestion = useSelector(
    (state) => state.dashboardStudentTotalQuestion
  )
  const {
    loading: loadingQuestionCount,
    error: errorQuestionCount,
    questionCount,
  } = dashboardStudentTotalQuestion

  // Question Count - Language
  const dashboardLanguageTotalQuestion = useSelector(
    (state) => state.dashboardLanguageTotalQuestion
  )
  const {
    loading: loadingLanguageQuestionCount,
    error: errorLanguageQuestionCount,
    questionCount: languageQuestionCount,
  } = dashboardLanguageTotalQuestion

  // Accuracy Donut - Global
  const dashboardGlobalAccuracyDonut = useSelector(
    (state) => state.dashboardGlobalAccuracyDonut
  )
  const {
    loading: loadingAccuracyDonut,
    error: errorAccuracyDonut,
    donutData,
  } = dashboardGlobalAccuracyDonut
  // Accuracy Donut - Language
  const dashboardLanguageAccuracyDonut = useSelector(
    (state) => state.dashboardLanguageAccuracyDonut
  )
  const {
    loading: loadingLanguageAccuracyDonut,
    error: errorLanguageAccuracyDonut,
    donutData: languageDonutData,
  } = dashboardLanguageAccuracyDonut

  // Time Series
  const dashboardGlobalTimeSeries = useSelector(
    (state) => state.dashboardGlobalTimeSeries
  )
  const {
    loading: loadingTimeSeries,
    error: errorTimeSeries,
    timeSeriesData,
  } = dashboardGlobalTimeSeries

  // Bar Chart - Global
  const dashboardGlobalBarChart = useSelector(
    (state) => state.dashboardGlobalBarChart
  )
  const {
    loading: loadingBarChart,
    error: errorBarChart,
    barChartData,
  } = dashboardGlobalBarChart

  // Bar Chart - Language
  const dashboardLanguageBarChart = useSelector(
    (state) => state.dashboardLanguageBarChart
  )
  const {
    loading: loadingLanguageBarChart,
    error: errorLanguageBarChart,
    barChartData: languageBarChartData,
  } = dashboardLanguageBarChart

  // Bar Chart - Language
  const dashboardLanguageMeanScoreBarChart = useSelector(
    (state) => state.dashboardLanguageMeanScoreBarChart
  )
  const {
    loading: loadingLanguageMeanChart,
    error: errorLanguageMeanChart,
    barChartData: languageMeanChartData,
  } = dashboardLanguageMeanScoreBarChart

  // Lebe Score - Language
  const dashboardLanguageLebeScore = useSelector(
    (state) => state.dashboardLanguageLebeScore
  )
  const {
    loading: loadingLanguageLebeScore,
    error: errorLanguageLebeScore,
    lebeScore,
  } = dashboardLanguageLebeScore

  // Tricky Words - Language
  const dashboardLanguageTrickyWords = useSelector(
    (state) => state.dashboardLanguageTrickyWords
  )
  const {
    loading: loadingLanguageTrickyWords,
    error: errorLanguageTrickyWords,
    trickyWords,
  } = dashboardLanguageTrickyWords

  useEffect(() => {
    if (userLogin) {
      dispatch(fetchGlobalStudentHeatmap())
      dispatch(fetchGlobalStudentQuestionTotal())
      dispatch(fetchGlobalStudentAnswerTotal())
      dispatch(fetchGlobalStudentAccuracyDonut())
      dispatch(fetchGlobalStudentTimeSeries())
      dispatch(fetchGlobalStudentBarChart())
    }
  }, [userLogin, history, successDelete])

  useEffect(() => {
    if (userLogin && language != 'Global') {
      // dispatch(fetchGlobalStudentHeatmap())
      dispatch(fetchLanguageStudentQuestionTotal(filter))
      dispatch(fetchLanguageStudentAnswerTotal(filter))
      dispatch(fetchLanguageStudentAccuracyDonut(filter))
      // dispatch(fetchGlobalStudentTimeSeries())
      dispatch(fetchLanguageStudentBarChart(filter))
      dispatch(fetchLanguageStudentTrickyWords(filter))
      dispatch(fetchLanguageStudentHeatmap(filter))
      dispatch(fetchLanguageStudentLebeScore(filter))
      dispatch(fetchLanguageStudentMeanScoreChart(filter))
    }
  }, [dispatch, language])

  useEffect(() => {
    if (document.getElementById('chartContainer3').clientWidth != null) {
      setWidth(document.getElementById('chartContainer3').clientWidth)
      setHeight(document.getElementById('chartContainer3').clientHeight)
    }
  }, [userLogin])

  const eventListener = () => {
    if (document.getElementById('chartContainer3').clientWidth != null) {
      setWidth(document.getElementById('chartContainer3').clientWidth)
      setHeight(document.getElementById('chartContainer3').clientHeight)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', eventListener)
    return () => {
      window.removeEventListener('resize', eventListener)
    }
  }, [width])

  useEffect(() => {
    if (userInfo.userLevels && languageOptions.length === 1) {
      setLanguageOptions(
        languageOptions.concat(Object.keys(userInfo.userLevels))
      )
    }
  }, [history, dispatch, userInfo, successDelete])

  const deleteDashboardData = (e) => {
    e.preventDefault()
    if (
      window.confirm(
        'Are you sure you want to delete ALL your data permanently? This will also lock your levels.'
      )
    ) {
      window.scrollTo(0, 0)
      dispatch(clearUserLevel())
      dispatch(deleteAllDashboardData())
    }
  }

  const globalDashboard = (
    <>
      <div>
        <Row>
          <Col>
            {loadingQuestionCount ? (
              <Loader />
            ) : questionCount ? (
              <GlobalTotalBox
                data={questionCount}
                message={'Total Unique Questions Answered'}
              />
            ) : (
              <h1>Data Not Found</h1>
            )}
          </Col>
          <Col>
            {loadingAnswerCount ? (
              <Loader />
            ) : answerCount ? (
              <GlobalTotalBox data={answerCount} message={'Total Answers'} />
            ) : (
              <h1>Data Not Found</h1>
            )}
          </Col>
          <Col>
            {loadingAccuracyDonut ? (
              <Loader />
            ) : donutData ? (
              <>
                <GlobalAccuracyDonut
                  data={donutData}
                  width={100}
                  height={100}
                  innerRadius={30}
                  outerRadius={50}
                />
              </>
            ) : (
              <h1>Data Not Found</h1>
            )}
          </Col>
        </Row>
      </div>
      <div className={classes.chartContainer}>
        <Row>
          <Col>
            <h4 className={classes.chartLabel}>Language-Level Heatmap</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            {loading ? (
              <Loader />
            ) : heatmapData ? (
              <GlobalHeatmap height={height} width={width} data={heatmapData} />
            ) : (
              <SuggestionBird message={'No Data'} />
            )}
          </Col>
        </Row>
      </div>
      {/* <div id={'chartContainer2'} className={classes.chartContainer}>
        <Row>
          <Col>
            <h4 className={classes.chartLabel}>Questions Answered Over Time</h4>
          </Col>
        </Row>
        <Row className={classes.chartContainer}>
          <Col>
            {loadingTimeSeries ? (
              <Loader />
            ) : timeSeriesData ? (
              <GlobalTimeSeries
                data={timeSeriesData}
                height={height}
                width={width}
              />
            ) : (
              <SuggestionBird message={'No Data'} />
            )}
          </Col>
        </Row>
      </div> */}
      <div id={'chartContainer3'}>
        <Row>
          <Col className={classes.chartContainer}>
            <h4 className={classes.chartLabel}>Accuracy Count By Level</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            {loadingBarChart ? (
              <Loader />
            ) : barChartData ? (
              <GlobalBarchart
                data={barChartData}
                height={height}
                width={width}
              />
            ) : (
              <SuggestionBird message={'No Data'} />
            )}
          </Col>
        </Row>
      </div>
    </>
  )

  const languageDashboard = (
    <>
      <div>
        <Row>
          <Col>
            {loadingLanguageQuestionCount ? (
              <Loader />
            ) : languageQuestionCount ? (
              <GlobalTotalBox
                data={languageQuestionCount}
                message={`Total Unique Questions Answered: ${language.toUpperCase()}`}
              />
            ) : (
              <h1>Data Not Found</h1>
            )}
          </Col>
          <Col>
            {loadingLanguageAnswerCount ? (
              <Loader />
            ) : languageAnswerCount ? (
              <GlobalTotalBox
                data={languageAnswerCount}
                message={`Total Answers: ${language.toUpperCase()}`}
              />
            ) : (
              <h1>Data Not Found</h1>
            )}
          </Col>
          <Col id={'donutContainer'}>
            {loadingLanguageAccuracyDonut ? (
              <Loader />
            ) : languageDonutData ? (
              <>
                <GlobalAccuracyDonut
                  data={languageDonutData}
                  width={100}
                  height={100}
                  innerRadius={30}
                  outerRadius={50}
                />
              </>
            ) : (
              <h1>Data Not Found</h1>
            )}
          </Col>
          <Col>
            {loadingLanguageLebeScore ? (
              <Loader />
            ) : lebeScore ? (
              <>
                <FluencyScoreBox
                  data={lebeScore}
                  message={`Your ${language.toUpperCase()} Fluency Score`}
                />
              </>
            ) : (
              <h1>Data Not Found</h1>
            )}
          </Col>
        </Row>
      </div>
      <div id={'chartContainer2'} className={classes.chartContainer}>
        <Row>
          <Col>
            <h4 className={classes.chartLabel}>Accuracy Count By Level</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            {loadingLanguageBarChart ? (
              <Loader />
            ) : languageBarChartData ? (
              <GlobalBarchart
                data={languageBarChartData}
                height={height}
                width={width}
              />
            ) : (
              <SuggestionBird message={'No Data'} />
            )}
          </Col>
        </Row>
      </div>
      <div id={'chartContainer3'} className={classes.chartContainer}>
        <Row>
          <Col>
            <h3 className={classes.chartLabel}>Accuracy By Level</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            {loadingLanguageMeanChart ? (
              <Loader />
            ) : languageMeanChartData ? (
              <LanguageBarChart
                data={languageMeanChartData}
                height={height}
                width={width}
              />
            ) : (
              <SuggestionBird message={'No Data'} />
            )}
            {/* <LanguageBarChart height={height} width={width} /> */}
          </Col>
        </Row>
        <div id={'chartContainer4'} className={classes.chartContainer}>
          <Row>
            <Col>
              <h3 className={classes.chartLabel}>
                Accuracy By Knowledge Type / Level
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              {loadingLanguageHeatmap ? (
                <Loader />
              ) : languageHeatmapData ? (
                <LanguageKnowledgeHeatmap
                  height={height}
                  width={width}
                  data={languageHeatmapData}
                />
              ) : (
                <SuggestionBird message={'No Data'} />
              )}
            </Col>
          </Row>
        </div>

        <div id={'chartContainer5'} className={classes.tableContainer}>
          <Row>
            <Col>
              {loadingLanguageTrickyWords ? (
                <Loader />
              ) : trickyWords ? (
                <TrickyWordsTable
                  height={height}
                  width={width}
                  TrickyWords={trickyWords}
                />
              ) : (
                <SuggestionBird message={'No Data'} />
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )

  const dashboardScreen = (
    <>
      <Row>
        <Col>
          <div className={classes.chartContainer}>
            <h3 className={classes.chartLabel}>
              {`Your ${language.toUpperCase()} Dashboard`}
            </h3>
          </div>
        </Col>
        <Col>
          <Form.Select
            className={classes.dropdownContainer}
            onChange={(e) => setLanguage(e.target.value)}
            defaultValue={languageOptions.length > 0 && languageOptions[0]}
          >
            {languageOptions.map((language, index) => (
              <option key={`languageoption${index}`} href='#' value={language}>
                {`${language.toUpperCase()} DASHBOARD`}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      <Row>
        <Col></Col>
      </Row>
      {language === 'Global' ? globalDashboard : languageDashboard}

      <Row>
        <Col>
          <Button
            className={classes.deleteDashboardButton}
            variant='warning'
            onClick={deleteDashboardData}
          >
            DELETE ALL DASHBOARD DATA
          </Button>
        </Col>
      </Row>
    </>
  )
  return (
    <div className={classes.container}>
      {errorQuestionCount ? <NoDataImage /> : dashboardScreen}
    </div>
  )
}

export default StudentGlobalDashboardScreen
