import React, { useState, useEffect } from 'react'
import { Modal, Form, ButtonGroup, ToggleButton, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createFlag } from '../actions/flagActions'
import { flagQuestion } from '../actions/questionActions'
import Message from './Message'
import classes from './FlagModal.module.css'

const FlagModal = (props) => {
  const [inappropriate, setInappropriate] = useState(false)
  const [incorrect, setIncorrect] = useState(false)
  const [wrongAnswer, setWrongAnswer] = useState(false)
  const [offensive, setOffensive] = useState(false)
  const [weird, setWeird] = useState(false)
  const [message, setMessage] = useState('')
  const [comment, setComment] = useState('')

  const dispatch = useDispatch()

  const flagCreate = useSelector((state) => state.flagCreate)
  const { loading, error, success, flag } = flagCreate
  useEffect(() => {}, [inappropriate, incorrect, wrongAnswer, offensive, weird])

  const questionFlag = useSelector((state) => state.questionFlag)
  const {
    loading: loadingQuestionFlag,
    error: errorQuestionFlag,
    successQuestionFlag,
  } = questionFlag

  useEffect(() => {
    if (success) {
      setMessage(
        'Thank you for your input! Our staff will review the question.'
      )
      setInappropriate(false)
      setIncorrect(false)
      setWrongAnswer(false)
      setOffensive(false)
      setWeird(false)
      setComment('')
    }
    props.handleFlagModalClose()
  }, [success, flag])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(flagQuestion(props.questionData._id))

    dispatch(
      createFlag({
        question: props.questionData._id,
        inappropriate,
        incorrect,
        wrongAnswer,
        offensive,
        weird,
        comment,
      })
    )
  }

  return (
    <>
      <Modal
        dialogClassName={classes.modalDiv}
        show={props.showFlagModal}
        dialogAs='div'
        onHide={props.handleFlagModalClose}
      >
        <div>
          <Modal.Header>
            <Modal.Title className={classes.modalTitle}>
              Flag Question for Moderation
            </Modal.Title>
          </Modal.Header>
          <Modal.Title className={classes.modalSubTitle}>
            What's wrong with the question?
          </Modal.Title>
          <Modal.Body>
            <Form>
              <ButtonGroup className='mb-2' vertical>
                <ToggleButton
                  className={classes.optionButton}
                  key={1}
                  id={`radio-1`}
                  type='radio'
                  name='radio1'
                  checked={inappropriate === true}
                  onClick={() => setInappropriate(!inappropriate)}
                >
                  Level Is Inappropriate
                </ToggleButton>
                <ToggleButton
                  className={classes.optionButton}
                  key={2}
                  id={`radio-2`}
                  type='radio'
                  name='radio2'
                  checked={incorrect === true}
                  onClick={() => setIncorrect(!incorrect)}
                >
                  Question is Incorrect
                </ToggleButton>
                <ToggleButton
                  className={classes.optionButton}
                  key={3}
                  id={`radio-3`}
                  type='radio'
                  name='radio3'
                  checked={wrongAnswer === true}
                  onClick={() => setWrongAnswer(!wrongAnswer)}
                >
                  Correct Answer Unavailable
                </ToggleButton>
                <ToggleButton
                  className={classes.optionButton}
                  key={4}
                  id={`radio-4`}
                  type='radio'
                  name='radio4'
                  checked={offensive === true}
                  onClick={() => setOffensive(!offensive)}
                >
                  Inappropriate / Offensive / Problematic
                </ToggleButton>
                <ToggleButton
                  className={classes.optionButton}
                  key={5}
                  id={`radio-5`}
                  type='radio'
                  name='radio5'
                  checked={weird === true}
                  onClick={() => setWeird(!weird)}
                >
                  Just Too Darn Weird
                </ToggleButton>
              </ButtonGroup>

              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlTextarea1'
              >
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  className={classes.commentBox}
                  as='textarea'
                  rows={3}
                  placeholder='Any extra information?'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={classes.nevermindButton}
              variant='secondary'
              onClick={props.handleFlagModalClose}
            >
              {success ? 'Go Back' : 'Nevermind'}
            </Button>
            <Button
              className={classes.flagForReviewButton}
              variant='primary'
              disabled={success ? true : false}
              onClick={submitHandler}
            >
              Flag Question for Review
            </Button>
          </Modal.Footer>

          {success && <Message variant='success'>{message}</Message>}
        </div>
      </Modal>
    </>
  )
}

export default FlagModal
