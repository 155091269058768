import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Container, Button } from 'react-bootstrap'
import classes from './StartScreen.module.css'
import { initializeModule } from '../actions/startActions.js'
import { getUserDetails } from '../actions/userActions'

const base_options = [{ value: 'english', label: 'English' }]

const target_options = [
  { value: 'dutch', label: 'Dutch' },
  { value: 'dzongkha', label: 'Dzongkha' },
  { value: 'english', label: 'English' },
  { value: 'french', label: 'French' },
  { value: 'german', label: 'German' },
  { value: 'portuguese', label: 'Portuguese' },
  { value: 'sango', label: 'Sango' },
  { value: 'spanish', label: 'Spanish' },
]

export const StartScreen = () => {
  let history = useNavigate()
  const dispatch = useDispatch()

  const [userLanguage, setUserLanguage] = useState('english')

  const initialize = useSelector(
    (state) => state.initialize.initializeParameters
  )

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const startButtonHandler = (e) => {
    if (!userInfo || !user.userLevels[e.target.value]) {
      if (e.target.value == 'english') {
        dispatch(initializeModule(userLanguage, e.target.value, 6))
        history('/lebemodule')
      } else {
        dispatch(initializeModule(userLanguage, e.target.value, 1))
        history('/lebemodule')
      }
    } else {
      dispatch(
        initializeModule(
          userLanguage,
          e.target.value,
          user.userLevels[e.target.value]
        )
      )
      history('/lebemodule')
    }
  }

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserDetails('profile'))
    }
  }, [history, dispatch, userInfo, initialize])

  return (
    <div>
      <Row>
        <Col>
          <div className={classes.numberOfQuestionsHeader}>
            <h3 className={classes.numberOfQuestions}>
              More than a million questions in 8 languages!
            </h3>
          </div>
          {/* <div className={classes.headerDiv}>
            <h3 className={classes.whichLanguageHeader}>
              Choose a language to study.
            </h3>
          </div> */}
        </Col>
      </Row>
      <Row>
        <Container className={classes.languageButtonContainer}>
          <Row>
            {target_options.map((option, index) => (
              <Col key={`startButton${index}`} sm={12} md={6} lg={4} xl={3}>
                <div className={classes.col}>
                  <Button
                    value={option.value}
                    className={classes.startButton}
                    onClick={startButtonHandler}
                  >
                    {option.label}
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Row>
    </div>
  )
}
