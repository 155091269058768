import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import AboutNavbar from '../components/AboutNavbar'
import ParagraphBox from '../components/ParagraphBox'
import classes from './AboutScreen.module.css'

const CareersScreen = () => {
  return (
    <div>
      <AboutNavbar />
      <div className={classes.scrollContainer}>
        <ParagraphBox
          title={'Want to work with us ?'}
          paragraph={
            "We don't have any specific job openings at the moment, but if you feel like your mission aligns with ours, feel free to email us at lebelanguages@gmail.com"
          }
        />
      </div>
    </div>
  )
}
export default CareersScreen
