import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import classes from './App.module.css'

import TeamMemberScreen from './screens/TeamMemberScreen'

import ClassroomRegisterScreen from './screensClassroom/ClassroomRegisterScreen'
import ClassroomLoginScreen from './screensClassroom/ClassroomLoginScreen'
import ClassroomScreen from './screensClassroom/ClassroomScreen'
import ClassroomStudentScreen from './screensClassroom/ClassroomStudentScreen'

import AboutScreen from './screensAbout/AboutScreen'
import TeamScreen from './screensLists/TeamScreen'

import LoginScreen from './screensForms/LoginScreen'
import RegisterScreen from './screensForms/RegisterScreen'
import ProfileScreen from './screensForms/ProfileScreen'

import QuestionListScreen from './screensLists/QuestionListScreen'
import ClassroomListStudentScreen from './screensLists/ClassroomListStudentScreen'
import NotebookListScreen from './screensLists/NotebookListScreen'
import MyQuestionsListScreen from './screensLists/MyQuestionListScreen'
import TeamMemberListScreen from './screensLists/TeamMemberListScreen'
import UserListScreen from './screensLists/UserListScreen'
import ClassroomListScreen from './screensLists/ClassroomListScreen'

import ClassroomModuleScreen from './screensModules/ClassroomModuleScreen'
import NotebookModuleScreen from './screensModules/NotebookModuleScreen'
import MyQuestionModuleScreen from './screensModules/MyQuestionModuleScreen'
import QuestionModuleScreen from './screensModules/QuestionModuleScreen'

import QuestionEditScreen from './screensEdits/QuestionEditScreen'
import MyQuestionEditScreen from './screensEdits/MyQuestionEditScreen'
import TeamMemberEditScreen from './screensEdits/TeamMemberEditScreen'
import UserEditScreen from './screensEdits/UserEditScreen'

import TeamAboutScreen from './screensAbout/TeamAboutScreen'
import MissionAboutScreen from './screensAbout/MissionAboutScreen'
import ResearchAboutScreen from './screensAbout/ResearchAboutScreen'
import NotebookEditScreen from './screensEdits/NotebookEditScreen'
import FlagListScreen from './screensLists/FlagListScreen'
import FlagEditScreen from './screensEdits/FlagEditScreen'

import StudentGlobalDashboardScreen from './screensDashboards/StudentGlobalDashboardScreen'
import TeacherGlobalDashboardScreen from './screensDashboards/TeacherGlobalDashboardScreen'
import QuizGeneratorScreen from './screensTeacherTools/QuizGeneratorScreen'
import SecretAboutScreen from './screensAbout/SecretAboutScreen'
import { NewStartScreen } from './screens/NewStartScreen'
import FutureScreen from './screensAbout/FutureScreen'
import CareersScreen from './screensAbout/CareersScreen'
import LevelDescriptionScreen from './screens/LevelDescriptionScreen'
import { StartScreen } from './screens/StartScreen'
import LanguagesAboutScreen from './screensAbout/LanguagesAboutScreen'
import SampleDashboard from './screens/SampleDashboard'
import LandingPage from './screens/LandingPage'

const App = () => {
  return (
    <div className={classes.main}>
      <Router>
        <Header />
        <main>
          <div className={classes.container}>
            <Routes>
              {/* <Route path='/' element={<NewStartScreen />} exact={true} /> */}
              <Route path='/' element={<LandingPage />} exact={true} />
              <Route path='/start' element={<StartScreen />} exact={true} />
              <Route
                path='/languages'
                element={<LanguagesAboutScreen />}
                exact={true}
              />
              <Route path='/about' element={<AboutScreen />} exact={true} />
              <Route
                path='/personalpage'
                element={<SecretAboutScreen />}
                exact={true}
              />
              <Route
                path='/levels'
                element={<LevelDescriptionScreen />}
                exact={true}
              />
              <Route path='/team' element={<TeamAboutScreen />} exact={true} />

              <Route
                path='/research'
                element={<ResearchAboutScreen />}
                exact={true}
              />
              <Route
                path='/mission'
                element={<MissionAboutScreen />}
                exact={true}
              />
              <Route path='/future' element={<FutureScreen />} exact={true} />
              <Route path='/careers' element={<CareersScreen />} exact={true} />
              <Route path='/lebemodule' element={<QuestionModuleScreen />} />
              <Route path='/login' element={<LoginScreen />} />
              <Route path='/register' element={<RegisterScreen />} />
              <Route path='/profile' element={<ProfileScreen />} />

              <Route path='/team/:id' element={<TeamMemberScreen />} />
              <Route
                path='/studentclassrooms'
                element={<ClassroomListStudentScreen />}
              />
              <Route
                path='/studentclassrooms/:id'
                element={<ClassroomStudentScreen />}
                exact={true}
              />
              <Route
                path='/studentclassrooms/module/:id'
                element={<ClassroomModuleScreen />}
                exact={true}
              />
              <Route path='/admin/userlist' element={<UserListScreen />} />
              <Route path='/admin/user/:id/edit' element={<UserEditScreen />} />
              <Route
                path='/admin/teamlist'
                element={<TeamMemberListScreen />}
                exact={true}
              />
              <Route
                path='/admin/team/:id/edit'
                element={<TeamMemberEditScreen />}
              />
              <Route
                path='/team/search/:keyword'
                element={<TeamScreen />}
                exact={true}
              />

              <Route
                path='/myclassrooms'
                element={<ClassroomListScreen />}
                exact={true}
              />
              <Route
                path='/myclassrooms/:id'
                element={<ClassroomScreen />}
                exact={true}
              />
              <Route
                path='/myquestions'
                element={<MyQuestionsListScreen />}
                exact={true}
              />
              <Route
                path='/myquestions/:id/edit'
                element={<MyQuestionEditScreen />}
                exact={true}
              />
              <Route
                path='/myquestions/module/:id'
                element={<MyQuestionModuleScreen />}
                exact={true}
              />
              <Route
                path='/myquestions/page/:pageNumber'
                element={<MyQuestionsListScreen />}
                exact={true}
              />
              <Route
                path='/myquestions/search/:keyword/page/:pageNumber'
                element={<MyQuestionsListScreen />}
                exact={true}
              />
              <Route
                path='/myquestions/search/:keyword'
                element={<MyQuestionsListScreen />}
                exact={true}
              />
              <Route
                path='/notebook'
                element={<NotebookListScreen />}
                exact={true}
              />
              <Route
                path='/notebook/:id/edit'
                element={<NotebookEditScreen />}
                exact={true}
              />
              <Route
                path='/notebook/page/:pageNumber'
                element={<NotebookListScreen />}
                exact={true}
              />
              <Route
                path='/notebook/search/:keyword/page/:pageNumber'
                element={<NotebookListScreen />}
                exact={true}
              />
              <Route
                path='/notebook/search/:keyword'
                element={<NotebookListScreen />}
                exact={true}
              />
              <Route
                path='/notebook/module'
                element={<NotebookModuleScreen />}
                exact={true}
              />
              <Route
                path='/myclassrooms/register'
                element={<ClassroomRegisterScreen />}
              />
              <Route path='/joinclassroom' element={<ClassroomLoginScreen />} />
              <Route
                path='/admin/questionfilter'
                element={<QuestionListScreen />}
                exact={true}
              />
              <Route
                path='/admin/questionfilter/page/:pageNumber'
                element={<QuestionListScreen />}
                exact={true}
              />
              <Route
                path='/admin/questionfilter/search/:keyword/page/:pageNumber'
                element={<QuestionListScreen />}
                exact={true}
              />
              <Route
                path='/admin/questionfilter/search/:keyword'
                element={<QuestionListScreen />}
                exact={true}
              />
              <Route
                path='/admin/questionfilter/:id/edit'
                element={<QuestionEditScreen />}
              />

              <Route
                path='/admin/flags'
                element={<FlagListScreen />}
                exact={true}
              />
              <Route
                path='/admin/flags/page/:pageNumber'
                element={<FlagListScreen />}
                exact={true}
              />
              <Route
                path='/admin/flags/search/:keyword/page/:pageNumber'
                element={<FlagListScreen />}
                exact={true}
              />
              <Route
                path='/admin/flags/search/:keyword'
                element={<FlagListScreen />}
                exact={true}
              />
              <Route
                path='/admin/flags/:id/edit'
                element={<FlagEditScreen />}
                exact={true}
              />
              <Route
                path='/admin/flags/module/:id'
                element={<FlagListScreen />}
                exact={true}
              />
              <Route
                path='/dashboard'
                element={<StudentGlobalDashboardScreen />}
                exact={true}
              />
              <Route
                path='/dashboardsample'
                element={<SampleDashboard />}
                exact={true}
              />
              <Route
                path='/teacherdashboard'
                element={<TeacherGlobalDashboardScreen />}
                exact={true}
              />
              <Route
                path='/quizgenerator'
                element={<QuizGeneratorScreen />}
                exact={true}
              />
            </Routes>
          </div>
        </main>
      </Router>
    </div>
  )
}

export default App
