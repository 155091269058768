import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getQuestions } from '../actions/questionActions'
import Loader from './Loader'
import classes from './QuestionFillBlank.module.css'

const accentDictionary = {
  french: [
    'é',
    'à',
    'è',
    'ì',
    'ò',
    'ù',
    'ë',
    'ï',
    'ü',
    'â',
    'ê',
    'î',
    'ô',
    'û',
    'ç',
  ],
  spanish: ['á', 'é', 'í', 'ó', 'ú', 'ü', 'ñ'],
  portuguese: ['á', 'é', 'í', 'ó', 'ú', 'â', 'ê', 'ô', 'ã', 'õ', 'à', 'ò', 'ç'],
  german: ['ä', 'ö', 'ü', 'Ä', 'Ö', 'Ü', 'ẞ', 'ß'],
}

export const QuestionFillBlank = (props) => {
  const typeInput = useRef(null)
  const [giveUp, setGiveUp] = useState(false)
  const [userInput, setUserInput] = useState('')
  const [showAccentButtons, setShowAccentButtons] = useState(false)
  const [typingAccuracy, setTypingAccuracy] = useState(1)
  const answer = props.questionData.answers[0].text

  useEffect(() => {
    if (
      props.targetLanguage == 'portuguese' ||
      props.targetLanguage == 'french' ||
      props.targetLanguage == 'spanish' ||
      props.targetLanguage == 'german'
    ) {
      setShowAccentButtons(true)
    }
  }, [props.targetLanguage])

  useEffect(() => {
    if (document.getElementById('mainInput') != null) {
      document.getElementById('mainInput').value = ''
      document.getElementById('mainInput').style.backgroundColor = '#ccffcc'
      document.getElementById('mainInput').disabled = false
      document.getElementById('giveUpButton').disabled = false
      document.getElementById('mainInput').focus()
    }
    setTypingAccuracy(1)
  }, [props.questionData])

  useEffect(() => {
    if (userInput.length) {
      const matchingSoFar = new RegExp('\b' + answer)
      const perfectMatch = new RegExp('^' + answer + '$')
      const userRegex =
        userInput.includes('*') ||
        userInput.includes('+') ||
        userInput.includes('[') ||
        userInput.includes('\\')
          ? new RegExp('\br983h4f934nfu9')
          : new RegExp('\b' + userInput)
      if (answer.includes('+') || answer.includes('*')) {
        document.getElementById('mainInput').style.backgroundColor = '#ff9999'
        setTypingAccuracy(typingAccuracy - 0.1)
      }
      if (perfectMatch.test(userInput)) {
        props.setScoreList(
          (scoreList) => [...scoreList, 1].slice(-5)
          // [...scoreList, Math.round(typingAccuracy)].slice(-10)
        )
        document.getElementById('mainInput').style.backgroundColor = '#4ba888'
        document.getElementById('mainInput').disabled = true
        document.getElementById('giveUpButton').disabled = true
        document.getElementById('nextButton').disabled = false
        document.getElementById('overDiv').focus()
      } else if (userRegex.test(matchingSoFar)) {
        document.getElementById('mainInput').style.backgroundColor = '#ccffcc'
      } else {
        document.getElementById('mainInput').style.backgroundColor = '#ff9999'
        if (typingAccuracy > 0) {
          setTypingAccuracy(typingAccuracy - 0.1)
        } else {
          setTypingAccuracy(0)
        }
      }
    }
  }, [userInput])

  const handleInput = (event) => {
    if (
      event.key == 'Enter' &&
      document.getElementById('mainInput').disabled === true
    ) {
      document.getElementById('nextButton').click()
    } else {
      setUserInput(event.target.value)
      console.log(document.getElementById('mainInput').value)
    }
  }

  const giveUpHandler = (event) => {
    props.setScoreList((scoreList) => [...scoreList, 0].slice(-5))
    document.getElementById('mainInput').value = answer
    document.getElementById('giveUpButton').disabled = true
    document.getElementById('nextButton').disabled = false
    document.getElementById('skipButton').disabled = true
  }

  useEffect(() => {
    if (document.getElementById('mainInput').value == '') {
      document.getElementById('mainInput').style.backgroundColor = '#ccffcc'
    }
  }, [userInput])

  const accentButtonHandler = (event) => {
    typeInput.current.value = typeInput.current.value + event.target.value
    setUserInput(typeInput.current.value)
    document.getElementById('mainInput').focus()
  }

  const FITB = (
    <div
      className={
        props.isMobile
          ? classes.questionContainerMobile
          : classes.questionContainer
      }
    >
      <Card className={classes.textCard}>
        <Card.Body>
          <Card.Text>{props.questionData.question}</Card.Text>
        </Card.Body>
      </Card>

      <form
        id={'mainForm'}
        autoFocus={true}
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <label>
          <input
            ref={typeInput}
            id={'mainInput'}
            className={classes.input}
            type='text'
            onKeyUp={handleInput}
          />
        </label>
      </form>
      <div>
        <div className={classes['tooltip-on-hover']}>
          <h4 className={classes.hintText}>{`${answer[0]}${answer.length}`}</h4>
        </div>
        <div className={classes.tooltip}>
          <h4 className={classes.hintText}>
            {`First letter is [${answer[0]}]...`}
          </h4>
          <h4 className={classes.hintText}> </h4>
          <h4 className={classes.hintText}>{`${' '}length is [${
            answer.length
          }] characters.`}</h4>
        </div>
      </div>
      <div>
        <Row>
          <Col>
            {showAccentButtons &&
              accentDictionary[props.targetLanguage].map((item, index) => (
                <button
                  className={classes.accentButton}
                  value={item}
                  onClick={accentButtonHandler}
                >
                  {item}
                </button>
              ))}
          </Col>
        </Row>
      </div>
      <Button
        id={'giveUpButton'}
        className={classes.giveUpButton}
        onClick={giveUpHandler}
      >
        {'show answer'}
      </Button>
    </div>
  )
  return (
    <>
      {props.loadingQuestion ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        FITB
      )}
    </>
  )
}
