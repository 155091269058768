import React, { useState, useEffect } from 'react'
import { Modal, Form, ButtonGroup, ToggleButton, Button } from 'react-bootstrap'

import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'
import LevelImage from './LevelImage'
import classes from './LevelModal.module.css'

const LevelModal = (props) => {
  let history = useNavigate()

  const submitHandler = (e) => {
    e.preventDefault()
    document.getElementById('nextButton').click()
    props.handleLevelModalClose()
  }

  const goBackHandler = (e) => {
    e.preventDefault()
    history(props.link)
    props.handleLevelModalClose()
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      document.getElementById('keepGoingButton').click()
    }
  }

  const congratulationsLevelModal = (
    <>
      <Modal
        dialogClassName={classes.modalDiv}
        dialogAs='div'
        show={props.showLevelModal}
        onHide={props.handleLevelModalClose}
      >
        <h2 className={classes.modalTitle}> Congratulations!</h2>
        <h3 className={classes.modalSubTitle}>{`You have unlocked level ${
          props.questionData.level + 1
        }!`}</h3>
        <div className={classes.imageContainer}>
          <Modal.Body>
            <LevelImage
              sourceImg={`/images/level${String(
                props.questionData.level + 1
              )}.png`}
            />
          </Modal.Body>
        </div>
        <Modal.Footer>
          <Button
            className={classes.takeABreakButton}
            onClick={goBackHandler}
            variant='secondary'
          >
            Take a Break
          </Button>

          <Button
            className={classes.keepGoingButton}
            variant='success'
            onClick={submitHandler}
          >
            Keep Going
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
  const oneLevelModal = (
    <>
      <Modal
        dialogClassName={classes.modalDiv}
        dialogAs='div'
        show={props.showLevelModal}
        onHide={props.handleLevelModalClose}
      >
        <div onKeyDown={props.handleKeyPress} tabIndex={'0'}>
          <h2 className={classes.modalTitle}> Welcome to Level 1!</h2>
          <h3
            className={classes.modalSubTitle}
          >{`You have unlocked level ${props.questionData.level}!`}</h3>
          <Modal.Body>
            <LevelImage
              sourceImg={`/images/level${String(props.questionData.level)}.png`}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={goBackHandler} variant='secondary'>
              Take a Break
            </Button>
            <Button
              className={classes.className}
              variant='success'
              onClick={submitHandler}
              id={'keepGoingButton'}
            >
              Keep Going
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
  return <>{congratulationsLevelModal}</>
}

export default LevelModal
