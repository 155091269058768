import React from "react"
import { Link } from "react-router-dom"
import { Card } from "react-bootstrap"

const TeamMember = ({ teamMember }) => {
  return (
    <Card className='my-3 p-3 rounded'>
      <Link to={`/team/${teamMember._id}`}>
        <Card.Img src={teamMember.image} variant='top' />
      </Link>

      <Card.Body>
        <Link to={`/team/${teamMember._id}`}>
          <Card.Title as='h2'>
            <strong>{teamMember.name}</strong>
          </Card.Title>
        </Link>

        <Card.Text as='div'>{teamMember.role}</Card.Text>
      </Card.Body>
    </Card>
  )
}

export default TeamMember
