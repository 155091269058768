import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  dropdown_level_options,
  dropdown_question_type_options,
  dropdown_target_options,
} from '../constants/dropdownConstants'
import classes from './EditScreen.module.css'
import { NOTEBOOK_UPDATE_RESET } from '../constants/notebookConstants'
import { getNotebookDetails, updateNotebook } from '../actions/notebookActions'

const NotebookEditScreen = () => {
  const params = useParams()
  const notebookId = params.id
  let history = useNavigate()

  const [question, setQuestion] = useState('')
  const [answers, setAnswers] = useState([])
  const [category, setCategory] = useState('')
  const [level, setLevel] = useState('')
  const [weight, setWeight] = useState(1)
  const [fullQuestion, setFullQuestion] = useState('')
  const [questionType, setQuestionType] = useState('')
  const [userLanguage, setUserLanguage] = useState('')
  const [targetLanguage, setTargetLanguage] = useState('')
  const [isFlagged, setIsFlagged] = useState(true)
  const [citation, setCitation] = useState('')
  const [note, setNote] = useState('')
  const [hint, setHint] = useState('')
  const [collectionName, setCollectionName] = useState('')
  const [priority, setPriority] = useState('')
  const [source, setSource] = useState('')
  const [textType, setTextType] = useState('')
  const [questionId, setQuestionId] = useState('')

  const updateAnswers = (index, e) => {
    let newArr = [...answers] // copying the old datas array
    if (e.target.id.includes('answer')) {
      newArr[index]['text'] = e.target.value // replace e.target.value with whatever you want to change it to
      setAnswers(newArr)
    } else if (e.target.id.includes('isCorrect')) {
      newArr[index]['isCorrect'] = e.target.value
      setAnswers(newArr)
    } else {
    }
  }

  const dispatch = useDispatch()

  const notebookDetails = useSelector((state) => state.notebookDetails)
  const { loading, error, notebook } = notebookDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const notebookUpdate = useSelector((state) => state.notebookUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = notebookUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: NOTEBOOK_UPDATE_RESET })
      history('/notebook')
    } else {
      if (!notebook._id) {
        dispatch(getNotebookDetails(notebookId))
      } else {
        setQuestion(notebook.question)
        setAnswers(notebook.answers)
        setCategory(notebook.category)
        setLevel(notebook.level)
        setWeight(notebook.weight)
        setQuestionType(notebook.questionType)
        setTargetLanguage(notebook.targetLanguage)
        setIsFlagged(notebook.isFlagged)
        setCitation(notebook.citation)
        setTextType(notebook.textType)
        setNote(notebook.note)
        setHint(notebook.hint)
        setSource(notebook.source)
        setPriority(notebook.priority)
        setQuestionId(notebook.questionId)
        setCollectionName(notebook.collectionName)
      }
    }
  }, [dispatch, history, questionId, notebook, successUpdate])

  // Dynamic Dropdowns

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateNotebook({
        user: userInfo._id,
        _id: notebookId,
        question,
        answers,
        category,
        level,
        questionType,
        userLanguage,
        targetLanguage,
        isFlagged,
        citation,
        textType,
        note,
        hint,
        source,
        priority,
        questionId,
      })
    )
  }

  return (
    <div className={classes.container}>
      <Link to='/admin/questionfilter' className='btn btn-light my-3'>
        Go Back
      </Link>
      <div className={classes.formContainer}>
        <>
          <h1>Edit Question</h1>
          {loadingUpdate && <Loader />}
          {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='question'>
                <Form.Label>Question</Form.Label>
                <Form.Control
                  disabled={true}
                  type='text'
                  placeholder='Enter question text'
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <div className={classes.answersContainer}>
                {answers.map((answer, index) => (
                  <Form.Group>
                    <Form.Label>ANSWER {index + 1}</Form.Label>
                    <Row>
                      <Col>
                        <Form.Control
                          disabled={true}
                          id={`answer${index}`}
                          type='text'
                          placeholder='Enter answer'
                          value={answer.text}
                          onChange={(e) => updateAnswers(index, e)}
                        ></Form.Control>
                      </Col>
                      <Col>
                        <Form.Select
                          disabled={true}
                          id={`isCorrectForm${index}`}
                          onChange={(e) => updateAnswers(index, e)}
                        >
                          <option
                            id={`isCorrect_1${index}`}
                            value={answer.isCorrect}
                          >
                            {answer.isCorrect === true
                              ? 'CORRECT ANSWER'
                              : 'INCORRECT'}
                          </option>
                          <option
                            id={`isCorrect_2${index}`}
                            value={answer.isCorrect === true ? false : true}
                          >
                            {answer.isCorrect === true
                              ? 'INCORRECT'
                              : 'CORRECT ANSWER'}
                          </option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>
                ))}
              </div>

              <Form.Group controlId='level'>
                <Form.Label>Level</Form.Label>
                <Form.Select
                  disabled={true}
                  className={classes.dropdownContainer}
                  value={
                    dropdown_level_options.filter(
                      (option) => option['value'] === level
                    )[0].value
                  }
                  onChange={(e) => setLevel(e.target.value)}
                >
                  {dropdown_level_options.slice(1).map((option) => (
                    <option href='#' value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group controlId='type'>
                <Form.Label>Question Type</Form.Label>
                <Form.Select
                  disabled={true}
                  className={classes.dropdownContainer}
                  value={
                    dropdown_question_type_options.filter(
                      (option) => option['value'] === questionType
                    )[0].value
                  }
                  onChange={(e) => setQuestionType(e.target.value)}
                >
                  {dropdown_question_type_options.slice(1).map((option) => (
                    <option href='#' value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group controlId='target_language'>
                <Form.Label>Target Language</Form.Label>
                <Form.Select
                  disabled={true}
                  className={classes.dropdownContainer}
                  value={
                    dropdown_target_options.filter(
                      (option) => option['value'] === targetLanguage
                    )[0].value
                  }
                  onChange={(e) => setTargetLanguage(e.target.value)}
                >
                  {dropdown_target_options.slice(1).map((option) => (
                    <option href='#' value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group controlId='source'>
                <Form.Label>Source</Form.Label>
                <Form.Control
                  disabled={true}
                  type='text'
                  placeholder='Enter citation if necessary'
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='note'>
                <Form.Label>Note</Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='hint'>
                <Form.Label>Hint</Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  value={hint}
                  onChange={(e) => setHint(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Button
                className={classes.updateQuestionButton}
                type='submit'
                variant='primary'
              >
                Update
              </Button>
            </Form>
          )}
        </>
      </div>
    </div>
  )
}

export default NotebookEditScreen
