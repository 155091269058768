import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import classes from './Dashboard.module.css'

const LanguageKnowledgeHeatmap = (props) => {
  // const myColor = d3.scaleLinear().domain([0, 1]).range(['#5a7864', 'orange'])
  const svgRef = useRef(null)
  const containerRef = useRef(null)
  const margin = { top: 50, right: 100, bottom: 50, left: 80 },
    width = props.width - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom

  const svg = d3
    .select('heatmap')
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

  const cache = useRef(props.data)

  useEffect(() => {
    const languages = d3.map(props.data, (d) => {
      return d.y
    })
    const levels = d3.map(props.data, (d) => {
      return d.x
    })

    const myColor = d3
      .scaleSequential()
      .interpolator(d3.interpolateBuPu)
      .domain([0, 1])
    const xScale = d3.scaleBand().range([0, width]).domain(levels).padding(0.05)
    const yScale = d3
      .scaleBand()
      .range([height, 0])
      .domain(languages)
      .padding(0.05)

    const svgEl = d3.select(svgRef.current)
    svgEl.selectAll('*').remove()

    const svg = svgEl
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    const xAxis = svg
      .append('g')
      .style('font-size', 15)
      .style('margin-top', '1rem')
      // .attr('transform', 'translate(0,' + width + ')')
      .call(d3.axisTop(xScale).tickSize(0))
      .select('.domain')
      .remove()

    const yAxis = svg
      .append('g')
      .style('font-size', 15)
      .call(d3.axisLeft(yScale).tickSize(0))
      .select('.domain')
      .remove()

    const tooltip = d3
      .select('#heatmap')
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .style('background-color', 'white')
      .style('border', 'solid')
      .style('border-width', '2px')
      .style('border-radius', '5px')
      .style('padding', '5px')

    const mouseover = function (event) {
      tooltip.style('opacity', 1)
      d3.select(this).style('stroke', 'black').style('opacity', 1)
    }
    const mousemove = (event) => {
      tooltip
        .html(
          `You have correctly answered ${Math.round(
            event.target.__data__.color * 100
          )}% of  ${event.target.__data__.y} questions at level ${
            event.target.__data__.x
          }`
        )
        .style('left', d3.pointer(event)[0] + 70 + 'px')
        .style('top', d3.pointer(event)[1] + 'px')
        .style('background-color', 'bonewhite')
    }
    const mouseleave = function (d) {
      tooltip.style('opacity', 0)
      d3.select(this).style('stroke', 'none').style('opacity', 0.8)
    }
    svg
      .selectAll()
      .data(props.data, function (d) {
        return d.x + ':' + d.y
      })
      .enter()
      .append('rect')
      .attr('x', function (d) {
        return xScale(d.x)
      })
      .attr('y', function (d) {
        return yScale(d.y)
      })
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', xScale.bandwidth())
      .attr('height', yScale.bandwidth())
      .style('fill', function (d) {
        return myColor(d.color)
      })
      .style('stroke-width', 4)
      .style('stroke', 'none')
      .style('opacity', 0.8)
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave)
  }, [props.data, props.width])

  return (
    <div>
      <div className={classes.chartContainer} ref={containerRef} id='heatmap'>
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g
            ref={svgRef}
            transform={`translate(${margin.left}, ${margin.top})`}
          />
        </svg>
      </div>
    </div>
  )
}

export default LanguageKnowledgeHeatmap
