import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import AboutNavbar from '../components/AboutNavbar'
import ParagraphBox from '../components/ParagraphBox'
import classes from './AboutScreen.module.css'
import { languageData } from '../screens/languageData'
import { LanguageTitleBox } from '../componentsLanguage/LanguageTitleBox'
import { LevelBox } from '../componentsLanguage/LevelBox'
import { QuestionCountBox } from '../componentsLanguage/QuestionCountBox'
import { VocabCountBox } from '../componentsLanguage/VocabCountBox'
import LanguageProfileBarChart from '../componentsLanguage/LanguageProfileBarChart'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { initializeModule } from '../actions/startActions'

const languages = [
  'dutch',
  'dzongkha',
  'english',
  'french',
  'german',
  'portuguese',
  'sango',
  'spanish',
]

const LanguagesAboutScreen = () => {
  let history = useNavigate()
  const dispatch = useDispatch()

  const [width, setWidth] = useState(
    window.screen.width - window.screen.width * 0.7
  )
  const [height, setHeight] = useState(100)
  const [userLanguage, setUserLanguage] = useState('english')
  const [language, setLanguage] = useState('default')
  //For prefab data:
  const [languageInfo, setLanguageInfo] = useState({})
  const [barChartData, setBarChartData] = useState([])
  const [questionCount, setQuestionCount] = useState(0)
  const [vocabCount, setVocabCount] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    window.screen.width <= 760 ? setIsMobile(true) : setIsMobile(false)
  }, [window.screen.width])

  function detectWindowSize() {
    window.innerWidth <= 760 ? setIsMobile(true) : setIsMobile(false)
  }

  window.onresize = detectWindowSize

  const initialize = useSelector(
    (state) => state.initialize.initializeParameters
  )
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const eventListener = () => {
    if (document.getElementById('chartContainer2').clientWidth != null) {
      setWidth(
        document.getElementById('chartContainer2').clientWidth -
          document.getElementById('chartContainer2').clientWidth * 0.2
      )
      setHeight(document.getElementById('chartContainer2').clientHeight)
    }
  }
  useEffect(() => {
    window.addEventListener('resize', eventListener)
    return () => {
      window.removeEventListener('resize', eventListener)
    }
  }, [width])

  const startButtonHandler = (e) => {
    if (!userInfo || !user.userLevels[e.target.value]) {
      dispatch(initializeModule(userLanguage, e.target.value, 1))
      history('/lebemodule')
    } else {
      dispatch(
        initializeModule(
          userLanguage,
          e.target.value,
          user.userLevels[e.target.value]
        )
      )
      history('/lebemodule')
    }
  }

  return (
    <div>
      <AboutNavbar />
      <div id={'chartContainer2'} className={classes.scrollContainer}>
        {languages.map((item, index) => (
          <Card className={classes.paragraphCard}>
            <Card.Body>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      {/* <Button
                        value={languageData[item].language}
                        className={classes.letsGoButton}
                        onClick={startButtonHandler}
                      >
                        {`START`}
                        {<i className='fa-solid fa-circle-play' />}
                      </Button> */}
                    </Col>
                  </Row>
                  <Row></Row>
                  <Row>
                    <Col>
                      <LanguageTitleBox
                        title={languageData[item].language}
                        subtitle={languageData[item].language}
                      />
                    </Col>

                    <Col>
                      <LevelBox
                        message={'Levels'}
                        data={languageData[item].levels}
                      />
                    </Col>
                    <Col>
                      <QuestionCountBox
                        message={'Questions'}
                        data={languageData[item].totalQuestions}
                      />
                    </Col>
                    <Col>
                      <VocabCountBox
                        message={'Vocabulary'}
                        data={languageData[item].totalVocab}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col>
                      {isMobile ? (
                        Array(languageData[item].barChartData).length < 1 ? (
                          <Loader />
                        ) : barChartData ? (
                          <LanguageProfileBarChart
                            data={languageData[item].barChartData}
                            width={300}
                            height={height}
                            language={languageData[item].language}
                          />
                        ) : (
                          <Message />
                        )
                      ) : Array(barChartData).length < 1 ? (
                        <Loader />
                      ) : barChartData ? (
                        <LanguageProfileBarChart
                          data={languageData[item].barChartData}
                          width={width}
                          height={height}
                          language={languageData[item].language}
                        />
                      ) : (
                        <Message />
                      )}
                    </Col>
                    {/* <Col id={'donutContainer'}>
                  {loadingLanguageDonutCount ? (
                    <Loader />
                  ) : donutData ? (
                    <>
                      <QuestionCountDonut
                        data={donutData}
                        width={160}
                        height={160}
                        innerRadius={55}
                        outerRadius={80}
                      />
                    </>
                  ) : (
                    <h1>Data Not Found</h1>
                  )}
                </Col> */}
                  </Row>
                  <Row>
                    {/* <Col>
                  <h3 className={classes.xAxisLabel}>{'LEVELS'}</h3>
                </Col> */}
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  )
}
export default LanguagesAboutScreen
