import axios from 'axios'
import {
  MY_QUESTIONS_DETAILS_FAIL,
  MY_QUESTIONS_DETAILS_REQUEST,
  MY_QUESTIONS_DETAILS_SUCCESS,
  MY_QUESTIONS_FETCH_ONE_FAIL,
  MY_QUESTIONS_FETCH_ONE_REQUEST,
  MY_QUESTIONS_FETCH_ONE_SUCCESS,
  MY_QUESTIONS_FILTER_FAIL,
  MY_QUESTIONS_FILTER_REQUEST,
  MY_QUESTIONS_FILTER_SUCCESS,
  MY_QUESTION_ADD_CLASSROOM_FAIL,
  MY_QUESTION_ADD_CLASSROOM_REQUEST,
  MY_QUESTION_ADD_CLASSROOM_SUCCESS,
  MY_QUESTION_CREATE_FAIL,
  MY_QUESTION_CREATE_REQUEST,
  MY_QUESTION_CREATE_SUCCESS,
  MY_QUESTION_DELETE_BULK_FAIL,
  MY_QUESTION_DELETE_BULK_REQUEST,
  MY_QUESTION_DELETE_BULK_SUCCESS,
  MY_QUESTION_DELETE_FAIL,
  MY_QUESTION_DELETE_REQUEST,
  MY_QUESTION_DELETE_SUCCESS,
  MY_QUESTION_GET_BY_CLASSROOM_FAIL,
  MY_QUESTION_GET_BY_CLASSROOM_REQUEST,
  MY_QUESTION_GET_BY_CLASSROOM_SUCCESS,
  MY_QUESTION_LEVELS_LIST_FAIL,
  MY_QUESTION_LEVELS_LIST_REQUEST,
  MY_QUESTION_LEVELS_LIST_SUCCESS,
  MY_QUESTION_LOWEST_LEVEL_FAIL,
  MY_QUESTION_LOWEST_LEVEL_REQUEST,
  MY_QUESTION_LOWEST_LEVEL_SUCCESS,
  MY_QUESTION_REMOVE_CLASSROOM_FAIL,
  MY_QUESTION_REMOVE_CLASSROOM_REQUEST,
  MY_QUESTION_REMOVE_CLASSROOM_SUCCESS,
  MY_QUESTION_UPDATE_FAIL,
  MY_QUESTION_UPDATE_REQUEST,
  MY_QUESTION_UPDATE_SUCCESS,
} from '../constants/myQuestionConstants'
import { logout } from './userActions'
import { API } from '../apiConstants'

export const getMyQuestionRandom = (id, filter) => async (dispatch) => {
  try {
    dispatch({ type: MY_QUESTIONS_FETCH_ONE_REQUEST })

    const { data } = await axios.get(`${API}/api/myquestions/module/${id}`, {
      params: filter,
    })

    dispatch({ type: MY_QUESTIONS_FETCH_ONE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: MY_QUESTIONS_FETCH_ONE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getLowestLevel = (id) => async (dispatch) => {
  try {
    dispatch({ type: MY_QUESTION_LOWEST_LEVEL_REQUEST })

    const { data } = await axios.get(`${API}/api/myquestions/minimum/${id}`)

    dispatch({ type: MY_QUESTION_LOWEST_LEVEL_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: MY_QUESTION_LOWEST_LEVEL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getLevelsList = (id) => async (dispatch) => {
  try {
    dispatch({ type: MY_QUESTION_LEVELS_LIST_REQUEST })

    const { data } = await axios.get(`${API}/api/myquestions/levels/${id}`)

    dispatch({ type: MY_QUESTION_LEVELS_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: MY_QUESTION_LEVELS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const filterMyQuestions =
  (filter, keyword = '', pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      filter.user = userInfo._id
      dispatch({ type: MY_QUESTIONS_FILTER_REQUEST })

      const { data } = await axios.get(
        `${API}/api/myquestions/?keyword=${keyword}&pageNumber=${pageNumber}`,
        {
          params: filter,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )

      dispatch({
        type: MY_QUESTIONS_FILTER_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: MY_QUESTIONS_FILTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getMyQuestionsByClassroom =
  (id, filter, keyword = '', pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      dispatch({ type: MY_QUESTION_GET_BY_CLASSROOM_REQUEST })

      const { data } = await axios.get(
        `${API}/api/myquestions/classroomquestions/${id}?keyword=${keyword}&pageNumber=${pageNumber}`,
        {
          params: filter,
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )

      dispatch({
        type: MY_QUESTION_GET_BY_CLASSROOM_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: MY_QUESTION_GET_BY_CLASSROOM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getMyQuestionDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: MY_QUESTIONS_DETAILS_REQUEST })

    const { data } = await axios.get(`${API}/api/myquestions/details/${id}`)

    dispatch({
      type: MY_QUESTIONS_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MY_QUESTIONS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteMyQuestion = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MY_QUESTION_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`${API}/api/myquestions/details/${id}`, config)

    dispatch({
      type: MY_QUESTION_DELETE_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: MY_QUESTION_DELETE_FAIL,
      payload: message,
    })
  }
}

export const deleteMyQuestionBulk =
  (questions) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MY_QUESTION_DELETE_BULK_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.delete(`${API}/api/myquestions/deletemyquestions`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
        data: questions,
      })

      dispatch({
        type: MY_QUESTION_DELETE_BULK_SUCCESS,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: MY_QUESTION_DELETE_BULK_FAIL,
        payload: message,
      })
    }
  }

export const createMyQuestion = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: MY_QUESTION_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`${API}/api/myquestions`, {}, config)

    dispatch({
      type: MY_QUESTION_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: MY_QUESTION_CREATE_FAIL,
      payload: message,
    })
  }
}

export const updateMyQuestionAddClassrooms =
  (questions) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MY_QUESTION_ADD_CLASSROOM_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `${API}/api/myquestions/addclassroom`,
        questions,
        config
      )

      dispatch({
        type: MY_QUESTION_ADD_CLASSROOM_SUCCESS,
        payload: data,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: MY_QUESTION_ADD_CLASSROOM_FAIL,
        payload: message,
      })
    }
  }

export const updateMyQuestionRemoveClassrooms =
  (questions) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MY_QUESTION_REMOVE_CLASSROOM_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `${API}/api/myquestions/removeclassroom`,
        questions,
        config
      )

      dispatch({
        type: MY_QUESTION_REMOVE_CLASSROOM_SUCCESS,
        payload: data,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: MY_QUESTION_REMOVE_CLASSROOM_FAIL,
        payload: message,
      })
    }
  }

export const updateMyQuestion = (question) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MY_QUESTION_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${API}/api/myquestions/details/${question._id}`,
      question,
      config
    )

    dispatch({
      type: MY_QUESTION_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: MY_QUESTIONS_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: MY_QUESTION_UPDATE_FAIL,
      payload: message,
    })
  }
}
